import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import JobDetails from '../components/JobDetails'

const JobDetailsScreen = (props) => {
  return (
    <JobDetails {...props} />
  )
}

export default JobDetailsScreen

const styles = StyleSheet.create({})