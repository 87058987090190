import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import EditCourse from '../components/EditCourse';

const EditCourseScreen = (props) => {
    return <EditCourse {...props} />
}

export default EditCourseScreen

const styles = StyleSheet.create({})