import { StyleSheet, Text, View, ScrollView, TouchableOpacity } from 'react-native'
import React, { useState, useEffect } from 'react'
import {
    Card, List, Button,
    IconButton,
    Paragraph,
    Surface,
    Title,
    TextInput, HelperText, Menu, Divider
} from 'react-native-paper';
import Header from "../../../global/components/Header";
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm, useController, Controller } from 'react-hook-form';

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { createSubmission } from "../../../graphql/mutations";
import { listContacts } from '../../../graphql/queries';

import awsconfig from "../../../aws-exports";

import { useSelector } from "react-redux";
import { addNotification } from "../../../../notifications";

API.configure(awsconfig);

const CreateJobSubmission = (props) => {
    let [job, setJob] = useState({});
    let [consultants, setConsultants] = useState([])

    const loggedinUser = useSelector((state) => state.login.user);
    let [user, setUser] = useState(loggedinUser);

    useEffect(async () => {
        setJob(props.route.params.job)
    }, [props])

    useEffect(() => {
        fetchConsultants();
    }, [])

    const fetchConsultants = async () => {
        await API.graphql({
            query: listContacts,
            variables: {
                filter: {
                    contactType: {
                        eq: 'CONSULTANT'
                    }
                }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(async (res) => {
                console.log(res)
                setConsultants(res.data.listContacts.items);
            })
            .catch((err) => {
                console.log(err, "errr");
            });
    };

    const { control, setFocus, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            submissionConsultantId: '',
        },
        mode: 'onChange',
    });

    const onSave = async (data) => {

        let submissionForm = {
            submissionJobId: job.id,
            submissionConsultantId: data.submissionConsultantId,
            consultantRate:data.consultantRate,
            submissionRecruiterId: user.userName,
            // status: 'PENDING',
            // notes: data.note,
            // recruiterSummary: data.recruiterSummary
        }

        console.log(submissionForm);

        await API.graphql({
            query: createSubmission,
            variables: {
                input: submissionForm
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        })
            .then(async (res) => {
                console.log(res, "res");

                let consultant=consultants.find((consultant)=>{
                    return consultant.id==data.submissionConsultantId;
                })

                addNotification({
                    notificationType: 'New Submission',
                    entityType: 'SUBMISSION',
                    entityId: job.id,
                    entityName: job.title,
                    actorId: user.username,
                    actorName: user.firstName,
                    message: `${user.firstName} submitted new consultant ${consultant.firstName} for job ${job.title}`
                })
                
                alert("New submission has been added successfully.")
                props.navigation.goBack()
            })
            .catch((err) => {
                console.log(err, "errr");
            })
    }

    return (
        <Card style={{ flex: 1 }}>
            <Header
                {...props}
                route={props.route}
                navigation={props.navigation}
                children={"New Submission"}
                showBack={true}
                leftType={"name"}
                leftName={"Save"}
                onPress={
                    handleSubmit((data) => {
                        onSave(data)
                    })}
            />
            <Card.Content style={{ marginTop: 30, }}>
                <FormBuilder
                    control={control}
                    setFocus={setFocus}
                    formConfigArray={[
                        {
                            name: 'submissionConsultantId',
                            type: 'select',
                            textInputProps: {
                                placeholder: 'Select Consultant',
                                //   label: 'City',
                                //   left: <TextInput.Icon name={'office-building'} />,
                            },
                            rules: {
                                required: {
                                    value: true,
                                    message: 'Consultant is required',
                                },
                            },
                            options: consultants.map((consultant) => {
                                let consultantName = `${consultant.firstName}, ${consultant.lastName}`;
                                return {
                                    label: consultantName,
                                    value: consultant.id
                                }
                            })
                        },
                    ]}
                />
                <Text>{'Consultant Rate'}</Text>
                <FormBuilder
                    control={control}
                    setFocus={setFocus}
                    formConfigArray={[
                        {
                            type: 'text',
                            name: 'consultantRate',
                            rules: {
                                required: {
                                    value: true,
                                    message: 'Consultant Rate',
                                },
                                // pattern: {
                                //     value:
                                //         /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                                //     message: 'Email is invalid',
                                // },
                            },
                            textInputProps: {
                                // label: 'Please Enter FirstName',
                                placeholder: 'Enter Consultant Rate',
                            },
                        }
                    ]}
                />
                <Text>{"Recruiter Summary"}</Text>
                <Controller
                    control={control}
                    name='recruiterSummary'
                    render={({ field: { onChange, value, onBlur } }) => (
                        <TextInput mode="outlined"
                            multiline={true}
                            placeholder="Enter Note"
                            onBlur={onBlur}
                            onChangeText={(text) => {
                                // console.log(text, "text");
                                onChange(text);
                            }}
                            style={{ height: 100 }}
                            outlineColor={errors.note && '#fc4c4c'}
                            activeOutlineColor={errors.note && '#fc4c4c'}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Note is required'
                        }
                    }}
                />
                <Text>{"Note"}</Text>
                <Controller
                    control={control}
                    name='note'
                    render={({ field: { onChange, value, onBlur } }) => (
                        <TextInput mode="outlined"
                            multiline={true}
                            placeholder="Enter Note"
                            onBlur={onBlur}
                            onChangeText={(text) => {
                                // console.log(text, "text");
                                onChange(text);
                            }}
                            style={{ height: 100 }}
                            outlineColor={errors.note && '#fc4c4c'}
                            activeOutlineColor={errors.note && '#fc4c4c'}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Note is required'
                        }
                    }}
                />
                <Card.Content>
                    {errors.note?.message ?
                        <Text style={{ color: '#fc4c4c', fontSize: 12, paddingVertical: 2, }}>
                            {errors.note?.message}
                        </Text> : null}
                </Card.Content>
            </Card.Content>
        </Card>
    )
}

export default CreateJobSubmission

const styles = StyleSheet.create({})