import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { createStackNavigator } from "@react-navigation/stack";
import LoginScreen from './screens/LoginScreen';
import DashboardStack from '../dashboard/DashboardStack';

const Stack = createStackNavigator();


const AccountStack = (props) => {
  return (
    <Stack.Navigator initialRouteName="Login">
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="DashboardStack"
        component={DashboardStack}
        options={{
          header: () => null,
        }}
      />
    </Stack.Navigator>
  )
}

export default AccountStack

const styles = StyleSheet.create({})