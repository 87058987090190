import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  View,
  StyleSheet,

  ScrollView,
} from "react-native";
import {
  Button,
  IconButton,
  Paragraph,
  Card,
  Text,
  Surface,
  Title,
  TextInput, HelperText
} from "react-native-paper";

import IntlPhoneField from 'react-native-intl-phone-field';

import { CognitoIdentityProviderClient, AdminCreateUserCommand, AdminSetUserPasswordCommand, AdminAddUserToGroupCommand } from "@aws-sdk/client-cognito-identity-provider";

import { useToast } from "react-native-toast-notifications";

import Header from "../../../global/components/Header";
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm, useController, Controller } from 'react-hook-form';

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { createContact } from "../../../graphql/mutations";

import awsconfig from "../../../aws-exports";

import { useSelector } from 'react-redux'

import { addNotification } from "../../../../notifications";

import { v4 as uuidv4 } from 'uuid';

API.configure(awsconfig);
Auth.configure(awsconfig)

const ContactType = {
  CONSULTANT: "CONSULTANT",
  VENDOR: "VENDOR",
  RECRUITER: "RECRUITER",
  MANAGER: "MANAGER",
  STUDENT: "STUDENT",
}

const AddContact = (props) => {

  const [contact, setContact] = useState({});

  const toast = useToast();

  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);

  const { control, setFocus, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      firstName: "",
      lastName: '',
      phone: '',
      contactType: 'CONSULTANT',
      manager: ''
    },
    mode: 'onChange',
  });

  let generatePassword = () => {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
      "abcdefghijklmnopqrstuvwxyz0123456789@#$" +
      "0123456789";

    for (i = 1; i <= 12; i++) {
      var char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }

    return pass;
  };


  const createUser=async (data)=>{
          const currentUser = await Auth.currentAuthenticatedUser()
          let getCredentials = async () => {
            const credentials = await Auth.currentCredentials();
            return Auth.essentialCredentials(credentials);
          };

          let credentials = await getCredentials();
          const client = new CognitoIdentityProviderClient({ region: awsconfig.aws_appsync_region, credentials });

          try {

            console.log('Data ' + JSON.stringify(data))

            let password = `H@ngten#${Math.floor(Math.random() * 101)}$`;
            console.log('generating password' + password)

            console.log('aws pool id' + awsconfig.aws_user_pools_id)

            let createUserParams = {
              UserPoolId: awsconfig.aws_user_pools_id,
              Username: data.email,
              DesiredDeliveryMediums: ["EMAIL"],
              TemporaryPassword: password,
              UserAttributes: [
                {
                  Name: "given_name",
                  Value: data.firstName,
                },
                {
                  Name: "family_name",
                  Value: data.lastName,
                },
                {
                  Name: "email",
                  Value: data.email,
                },
                {
                  Name: "email_verified",
                  Value: "true",
                },
                {
                  Name: "custom:managerId",
                  Value: currentUser.username,
                },
              ],
            }

            console.log('createUserParams ' + JSON.stringify(createUserParams))

            const createUserCommand = new AdminCreateUserCommand(createUserParams);
            const createUserData = await client.send(createUserCommand);

            let setUserPasswordParams = {
              Password: password,
              Permanent: true,
              Username: data.email,
              UserPoolId: awsconfig.aws_user_pools_id,
            }

            console.log('setUserPasswordParams ' + JSON.stringify(setUserPasswordParams))
            const setUserPasswordCommand = new AdminSetUserPasswordCommand(setUserPasswordParams);
            const setUserPasswordData = await client.send(setUserPasswordCommand);

            let groupName = "recruiters";

            switch (data.contactType) {
              case ContactType.MANAGER:
                groupName = "managers";
                break;
              // case ContactType.STUDENT:
              //   groupName = "students";
              //   break;
              default:
                groupName = "recruiters";
            }

            let addUserToGroupParams = {
              GroupName: groupName,
              UserPoolId: awsconfig.aws_user_pools_id,
              Username: data.email,
            }

            console.log('addUserToGroupParams ' + JSON.stringify(addUserToGroupParams))
            const addUserToGroupCommnad = new AdminAddUserToGroupCommand(addUserToGroupParams);
            const addUserToGroupData = await client.send(addUserToGroupCommnad);
            
            return createUserData;

            // process data.
          } catch (error) {
            console.log(error.message + " " + error.stack)
            throw new Error(error.message + " " + error.stack)
          }
      
  }


  const onSave = async (data) => {
    const currentUser = await Auth.currentAuthenticatedUser()
    let contactForm = {}
    let contactId=uuidv4();

    console.log(data.contactType)

    if (data.contactType !== "CONSULTANT" &&
        data.contactType !== "VENDOR" &&
        data.contactType !== "CLIENT" &&
        data.contactType !== "STUDENT") {

        let user=await createUser(data);
        console.log(user);
        contactId=user.User.Username
    }

    if (currentUser.attributes.email == "info@ecorfy.com") {
      contactForm = {
        id:contactId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email.toLowerCase(),
        contactType: data.contactType,
        phone: data.phone,
        userId: currentUser.username
      };
    }
    else {

      let userGroups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
      let managerId = "";
      if (userGroups.indexOf("managers") == -1) {
        managerId = currentUser.attributes['custom:managerId']
      }
      else {
        managerId = currentUser.username;
      }

      contactForm = {
        id:contactId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email.toLowerCase(),
        contactType: data.contactType,
        phone: data.phone,
        userId: currentUser.username,
        managerId: managerId
      };
    }

    console.log(contactForm);

    await API.graphql({
      query: createContact,
      variables: { input: contactForm },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
      .then(async (res) => {
        console.log(res, "res");

        addNotification({
          notificationType:'New Contact',
          entityType:'CONTACT',
          entityId:res.data.createContact.id,
          entityName:contactForm.firstName,
          actorId:currentUser.username,
          actorName:user.firstName,
          message:`${user.firstName} posted new contact ${contactForm.firstName}`
        })

        toast.show("New user has been added successfully.", {
          type: "success",
          placement: "top",
          duration: 4000,
          offset: 30,
          animationType: "zoom-in",
        })
        props.navigation.goBack()
      })
      .catch((err) => {
        console.log(err, "errr");
        toast.show("Failed to save new contact.", {
          type: "danger",
          placement: "top",
          duration: 4000,
          offset: 30,
          animationType: "zoom-in",
        })
      })
  }

  const NumberType = (props) => {
    const { name, rules, shouldUnregister, defaultValue, control } = props;
    console.log(useController({ name }), "props");
    return <TextInput keyboardType="number-pad" mode="outlined"
      placeholder="Please Enter Phone Number"
    />
  }
  return (
    <Card style={styles.container}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"Add Contact"}
        showBack={true}
        leftType={"name"}
        leftName={"Save"}
        onPress={handleSubmit((data) => {
          console.log('form data', data);
          setContact(data)
          onSave(data)
        })} />
      <ScrollView contentContainerStyle={styles.scrollViewStyle}>
        <Text>{"First Name"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              type: 'text',
              name: 'firstName',
              rules: {
                required: {
                  value: true,
                  message: 'firstName is required',
                },
                minLength: {
                  value: 3,
                  message: 'FirstName should be atleast 3 characters',
                },
              },
              textInputProps: {
                // label: 'Please Enter FirstName',
                placeholder: 'Please Enter FirstName',

              },
            },
          ]}
        />
        <Text>{"Last Name"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              type: 'text',
              name: 'lastName',
              rules: {
                required: {
                  value: true,
                  message: 'lastName is required',
                },
              },
              textInputProps: {
                // label: 'Please Enter FirstName',
                placeholder: 'Please Enter LastName',
              },
            },
          ]}
        />
        <Text>{'Email'}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              type: 'email',
              name: 'email',
              rules: {
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value:
                    /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                  message: 'Email is invalid',
                },
              },
              textInputProps: {
                // label: 'Please Enter FirstName',
                placeholder: 'Please Enter Email',
              },
            }
          ]}
        />

        <Text>{'Phone Number'}</Text>
        <Controller
          control={control}
          name='phone'
          render={({ field: { onChange, value, onBlur } }) => (
            <IntlPhoneField
              onEndEditing={(result) => onChange(result.value)}
              onValidation={(isValid) => console.log(isValid)}
              defaultCountry="IN"
              defaultPrefix="+91"
            />
          )}
          rules={{
            required: {
              value: false,
              message: 'Phone Number is required'
            }
          }}
        />
        {errors['phone']?.message ?
          <Text style={{ color: '#fc4c4c', fontSize: 12, paddingVertical: 2, paddingHorizontal: 10 }}>
            {errors['phone']?.message}
          </Text> : null}
        <Text>{"Contact Type"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              name: 'contactType',
              type: 'select',
              textInputProps: {
                placeholder: 'Select Contact Type',
                //   label: 'City',
                //   left: <TextInput.Icon name={'office-building'} />,
              },
              rules: {
                required: {
                  value: true,
                  message: 'Contact Type is required',
                },
              },
              options: user.group=="managers" ? [
                {
                  label: 'Consultant',
                  value: "CONSULTANT",
                },
                {
                  label: 'Vendor',
                  value: "VENDOR",
                },
                {
                  label: 'Student',
                  value: "STUDENT",
                },
                {
                  label: 'Manager',
                  value: "MANAGER",
                },
                {
                  label: 'Recruiter',
                  value: "RECRUITER",
                }
              ] : [{
                label: 'Consultant',
                value: "CONSULTANT",
              }]
            },
          ]}
        />
      </ScrollView>
    </Card>
  )
}

export default AddContact

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  helpertext: {
    fontSize: 12,
    paddingHorizontal: 0
  },
  scrollViewStyle: {
    padding: 15,
    justifyContent: 'center',
  },
})