import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import AddContact from '../components/AddContact';

const AddContactScreen = (props) => {
  return (
    <AddContact {...props} />
  )
}

export default AddContactScreen

const styles = StyleSheet.create({})