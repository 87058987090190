import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
  FlatList,
} from "react-native";
import React, { useEffect, useState } from "react";
import {
  Card,
  Surface,
  List,
  FAB,
  IconButton,
  Avatar,
} from "react-native-paper";

import { useFocusEffect } from "@react-navigation/native";
import * as ScreenOrientation from "expo-screen-orientation";
import Amplify, { API } from "aws-amplify";
import awsconfig from "../../../aws-exports";

import { listCourseLessons } from "../../../graphql/queries";
import { useSelector } from "react-redux";

import * as WebBrowser from 'expo-web-browser';

API.configure(awsconfig);

const Lessons = (props) => {
  console.log(props, "....");

  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([]);
  const [expanded, setExpanded] = React.useState(true);
  const [videoLandScape, setVideoLandScape] = useState(true);

  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);

  useFocusEffect(
    React.useCallback(() => {
      if (videoLandScape === true) {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
      }
      let isActive = true;
      fetchCourseLessons();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const fetchCourseLessons = async () => {
    await API.graphql({
      query: listCourseLessons,
      variables: {
        courseID: props ? props.course.id : course.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        setLessons(res.data.listCourseLessons.items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  useEffect(() => {
    setCourse(props.course);
    setLessons(props.course.courseLessons);
  }, [props]);

  const handlePress = () => setExpanded(!expanded);

  const renderItem = ({ item }) => {
    console.log(item, "item");
    return (
      <Surface
        style={{
          backgroundColor: "white",
          marginVertical: 5,
          marginHorizontal: 5,
          marginBottom: 5,
        }}
      >
        <ScrollView style={{}}>
          <List.Accordion title={item.title}>
            <Card.Actions
              style={{ justifyContent: "flex-end", marginVertical: 0 }}
            >
              {user.group == "managers" && (
                <IconButton
                  {...props}
                  icon="plus"
                  label="New Topic"
                  onPress={() => {
                    props.navigation.navigate("CreateLessonTopic", {
                      lesson: item,
                      course: course,
                    });
                  }}
                />
              )}
            </Card.Actions>
            {item?.topics?.map((topic, index) => {
              console.log(topic);
              return (
                <Card.Title
                  key={index}
                  title={topic.title}
                  subtitle={topic.description}
                  left={(props) => (
                    <Avatar.Text
                      {...props}
                      color={"white"}
                      size={48}
                      label={"T"}
                    />
                  )}
                  right={(prop) => (
                    <IconButton
                      {...prop}
                      icon="arrow-right-drop-circle-outline"
                      onPress={async () =>
                        await WebBrowser.openBrowserAsync(topic.videoUrl)
                      }
                    />
                  )}
                />
              );
            })}
          </List.Accordion>
        </ScrollView>
      </Surface>
    );
  };

  return (
    <Card
      style={{
        flex: 1,
        // justifyContent: "flex-start",
        // alignItems: "center",
        // flexDirection: "row",
        // backgroundColor: "white",
      }}
    >
      {!loading ? (
        <Card.Content style={{ flex: 1, paddingHorizontal: 0 }}>
          <FlatList data={lessons} renderItem={renderItem} />
          {user.group == "managers" && (
            <FAB
              icon="plus"
              style={styles.fab}
              onPress={() =>
                props.navigation.navigate("CreateLesson", { course })
              }
            />
          )}
        </Card.Content>
      ) : (
        <ActivityIndicator
          size="large"
          color="#390485"
          style={styles.loading}
        />
      )}
    </Card>
  );
};

export default Lessons;

const styles = StyleSheet.create({
  fab: {
    position: "absolute",
    marginVertical: -26,
    marginHorizontal: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
});