import React, { useState, useEffect, useLayoutEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import {
  Button,
  IconButton,
  Paragraph,
  Card,
  Text,
  Surface,
  Title,
  TextInput,
  HelperText,
  List,
} from "react-native-paper";

import {
  CognitoIdentityProviderClient,
  AdminCreateUserCommand,
  AdminSetUserPasswordCommand,
  AdminAddUserToGroupCommand,
} from "@aws-sdk/client-cognito-identity-provider";

import { useToast } from "react-native-toast-notifications";

import Header from "../../../global/components/Header";
import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { updateContact } from "../../../graphql/mutations";

import awsconfig from "../../../aws-exports";

API.configure(awsconfig);
Auth.configure(awsconfig);

const ContactType = {
  CONSULTANT: "CONSULTANT",
  VENDOR: "VENDOR",
  RECRUITER: "RECRUITER",
  MANAGER: "MANAGER",
  STUDENT: "STUDENT",
};


const EditContact = (props) => {
  // console.log(props.route.params.contacts);

  const [contact, setContact] = useState(props.route.params.contact);
  const [contacts, setContacts] = useState([]);
  const toast = useToast();

  useEffect(() => {
    setContact(props.route.params.contact);
    let filContacts = props?.route?.params?.contacts?.filter((item) => {
      return item.contactType === "MANAGER" || item.contactType === "RECRUITER";
    });
    // console.log(filContacts,"filContacts");
    setContacts(filContacts);
  }, [props]);

  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: contact !== undefined ? contact.title : "",
      firstName: contact !== undefined ? contact.firstName : "",
      lastName: contact !== undefined ? contact.lastName : "",
      middleName: contact !== undefined ? contact.middleName : "",
      email: contact !== undefined ? contact.email : "",
      phone: contact !== undefined ? contact.phone : "",
      contactType: contact !== undefined ? contact.contactType : "CONSULTANT",

      jobTitle: contact !== undefined ? contact.jobTitle : "",
      dob: contact !== undefined ? contact.dob : "",
      gender: contact !== undefined ? contact.gender : "",
      ssn: contact !== undefined ? contact.ssn : "",
      whatsappId: contact !== undefined ? contact.whatsappId : "",
      address: contact !== undefined ? contact.address : "",
      city: contact !== undefined ? contact.city : "",
      state: contact !== undefined ? contact.state : "",
      zip: contact !== undefined ? contact.zip : "",

      //   contractType: contact !== undefined ? contact.contractType : "",
      experience: contact !== undefined ? contact.experience : "",
      visa: contact !== undefined ? contact.visa : "",
      primarySkill: contact !== undefined ? contact.primarySkill : "",
      secondarySkill: contact !== undefined ? contact.secondarySkill : "",
      //   tags: contact !== undefined ? contact.tags : "",
      relocation: contact !== undefined ? contact.relocation : "",

      employerName: contact !== undefined ? contact.employerName : "",
      employerEmail: contact !== undefined ? contact.employerEmail : "",
      employerPhone: contact !== undefined ? contact.employerPhone : "",

      university: contact !== undefined ? contact.university : "",
      degree: contact !== undefined ? contact.degree : "",
      yearJoined: contact !== undefined ? contact.yearJoined : "",
      interest: contact !== undefined ? contact.interest : "",

      owner: contact !== undefined ? contact.owner : "",
    },
    mode: "onChange",
  });

  let generatePassword = () => {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
      "abcdefghijklmnopqrstuvwxyz0123456789@#$" +
      "0123456789";

    for (i = 1; i <= 12; i++) {
      var char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }

    return pass;
  };

  const onSave = async (data) => {
    // console.log(data);
    const currentUser = await Auth.currentAuthenticatedUser();
    let contactForm = {
      id: contact.id,
      title: data.title,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      email: data.email.toLowerCase(),
      contactType: data.contactType,
      phone: data.phone,
      jobTitle: data.jobTitle,
      dob: data.dob,
      gender: data.gender,
      ssn: data.ssn,
      whatsappId: data.whatsappId,
      shippingAddress: {
        address1: data.address,
        zip: data.zip,
      },
      city: data.city,
      state: data.state,

      experience: data.experience,
      visaType: data.visa,
      primarySkill: data.primarySkill,
      secondarySkill: data.secondarySkill,
      //   tags: data.tags,
      relocation: data.relocation,
      //   contractType: data.contractType,
      employerName: data.employerName,
      employerEmail: data.employerEmail,
      employerPhone: data.employerPhone,
      university: data.university,
      degree: data.degree,
      yearJoined: data.yearJoined,
      interest: data.interest
    };
    console.log(contactForm, "contactForm");
    await API.graphql({
      query: updateContact,
      variables: {
        input: contactForm,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        console.log(res, "res");
        alert("New Details has been updated successfully. ");
        props.navigation.goBack();
      })
      .catch((err) => {
        console.log(err, "errr");
        toast.show("Failed to Edit contact Details.", {
          type: "danger",
          placement: "top",
          duration: 4000,
          offset: 30,
          animationType: "zoom-in",
        });
      });
  };

  const NumberType = (props) => {
    const { name, rules, shouldUnregister, defaultValue, control } = props;
    console.log(useController({ name }), "props");
    return (
      <TextInput
        keyboardType="number-pad"
        mode="outlined"
        placeholder="Please Enter Phone Number"
      />
    );
  };
  return (
    <Card style={styles.container}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"Edit Contact"}
        showBack={true}
        leftType={"name"}
        leftName={"Save"}
        onPress={handleSubmit((data) => {
          console.log("form data", data);
          setContact(data);
          onSave(data);
        })}
      />
      <ScrollView>
        <Card.Content
          style={{ paddingHorizontal: 0, flex: 1, marginBottom: 30 }}
        >
          <List.Accordion
            title="Basic Info"
            titleStyle={{
              textAlign: "center",
            }}
          >
            <ScrollView contentContainerStyle={styles.scrollViewStyle}>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    name: "title",
                    type: "select",
                    textInputProps: {
                      placeholder: "Select Title",
                      label: "Title",
                    },
                    rules: {
                      required: {
                        value: true,
                        message: "Title Type is required",
                      },
                    },
                    options: [
                      {
                        label: "Mr",
                        value: "Mr",
                      },
                      {
                        label: "Mrs",
                        value: "Mrs",
                      },
                      {
                        label: "Ms",
                        value: "Ms",
                      },
                    ],
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "firstName",
                    rules: {
                      required: {
                        value: true,
                        message: "firstName is required",
                      },
                      minLength: {
                        value: 3,
                        message: "FirstName should be atleast 3 characters",
                      },
                    },
                    textInputProps: {
                      label: "First Name",
                      placeholder: "Please Enter FirstName",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "lastName",
                    rules: {
                      required: {
                        value: true,
                        message: "Last Name is required",
                      },
                    },
                    textInputProps: {
                      label: "Last Name",
                      placeholder: "Please Enter LastName",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "middleName",
                    rules: {
                      required: {
                        value: false,
                        message: "Middle Name is required",
                      },
                    },
                    textInputProps: {
                      label: "Middle Name",
                      placeholder: "Please Enter MiddleName",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "email",
                    name: "email",
                    rules: {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                      pattern: {
                        value:
                          /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                        message: "Email is invalid",
                      },
                    },
                    textInputProps: {
                      label: "Email",
                      placeholder: "Please Enter Email",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "phone",
                    rules: {
                      required: {
                        value: true,
                        message: "Phone is required",
                      },
                    },
                    textInputProps: {
                      label: "Phone",
                      placeholder: "Please Enter Phone",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    name: "contactType",
                    type: "select",
                    textInputProps: {
                      placeholder: "Select Contact Type",
                      label: "Contact Type",
                    },
                    rules: {
                      required: {
                        value: true,
                        message: "Contact Type is required",
                      },
                    },
                    options: [
                      {
                        label: "Consultant",
                        value: "CONSULTANT",
                      },
                      {
                        label: "Vendor",
                        value: "VENDOR",
                      },
                      {
                        label: "Student",
                        value: "STUDENT",
                      },
                      {
                        label: "Manager",
                        value: "MANAGER",
                      },
                      {
                        label: "Recruiter",
                        value: "RECRUITER",
                      },
                    ],
                  },
                ]}
              />
            </ScrollView>
          </List.Accordion>
          <List.Accordion
            title="Personal Info"
            titleStyle={{ textAlign: "center" }}
          >
            <ScrollView contentContainerStyle={styles.scrollViewStyle}>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "jobTitle",
                    rules: {
                      required: {
                        value: false,
                        message: "Job Title is required",
                      },
                    },
                    textInputProps: {
                      label: "Job Title",
                      placeholder: "Please Enter  Job Title",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "dob",
                    rules: {
                      required: {
                        value: false,
                        message: "DOB is required",
                      },
                    },
                    textInputProps: {
                      label: "DOB",
                      placeholder: "Please Enter Date of Birth",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    name: "gender",
                    type: "select",
                    textInputProps: {
                      placeholder: "Select Gender ",
                      label: "Gender",
                    },
                    rules: {
                      required: {
                        value: false,
                        message: "Gender is required",
                      },
                    },
                    options: [
                      {
                        label: "Male",
                        value: "M",
                      },
                      {
                        label: "Female",
                        value: "F",
                      },
                    ],
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "ssn",
                    rules: {
                      required: {
                        value: false,
                        message: "SSN is required",
                      },
                    },
                    textInputProps: {
                      label: "SSN",
                      placeholder: "Please Enter last 4 digits of SSN",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "whatsappId",
                    rules: {
                      required: {
                        value: false,
                        message: "Whatsapp ID is required",
                      },
                    },
                    textInputProps: {
                      label: "Whatsapp ID",
                      placeholder: "Please Enter Whatsapp ID",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "address",
                    rules: {
                      required: {
                        value: false,
                        message: "Address is required",
                      },
                    },
                    textInputProps: {
                      label: "Address",
                      placeholder: "Please Enter Address",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "city",
                    rules: {
                      required: {
                        value: false,
                        message: "City is required",
                      },
                    },
                    textInputProps: {
                      label: "City",
                      placeholder: "Please Enter City",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "state",
                    rules: {
                      required: {
                        value: false,
                        message: "State is required",
                      },
                    },
                    textInputProps: {
                      label: "State",
                      placeholder: "Please Enter State",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "zip",
                    rules: {
                      required: {
                        value: false,
                        message: "Zip is required",
                      },
                    },
                    textInputProps: {
                      label: "Zip",
                      placeholder: "Please Enter Zip",
                    },
                  },
                ]}
              />
            </ScrollView>
          </List.Accordion>
          <List.Accordion
            title="Professional Info"
            titleStyle={{ textAlign: "center" }}
          >
            <ScrollView contentContainerStyle={styles.scrollViewStyle}>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "experience",
                    rules: {
                      required: {
                        value: false,
                        message: "Experience is required",
                      },
                    },
                    textInputProps: {
                      label: "Experience",
                      placeholder: "Please Enter Experience in Years",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    name: "visa",
                    type: "select",
                    textInputProps: {
                      placeholder: "Select Visa Type",
                      label: "Visa Type",
                    },
                    rules: {
                      required: {
                        value: false,
                        message: "Visa Type is required",
                      },
                    },
                    options: [
                      {
                        label: "US Citizen",
                        value: "usc",
                      },
                      {
                        label: "Greencard",
                        value: "greencard",
                      },
                      {
                        label: "H1B",
                        value: "h1b",
                      },
                      {
                        label: "H4",
                        value: "h4",
                      },
                      {
                        label: "F1 OPT",
                        value: "opt",
                      },
                      {
                        label: "F1 CPT",
                        value: "cpt",
                      },
                      {
                        label: "L2",
                        value: "l2",
                      },
                      {
                        label: "TN",
                        value: "tn",
                      },
                    ],
                  },
                ]}
              />
              {/* <FormBuilder
              control={control}
              setFocus={setFocus}
              formConfigArray={[
                {
                  name: "contractType",
                  type: "select",
                  textInputProps: {
                    placeholder: "Select Contract Type",
                    label: "Contract Type",
                  },
                  rules: {
                    required: {
                      value: true,
                      message: "Contract Type is required",
                    },
                  },
                  options: [
                    {
                      label: "C2C",
                      value: "C2C",
                    },
                    {
                      label: "W2",
                      value: "W2",
                    },
                  ],
                },
              ]}
            /> */}
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "primarySkill",
                    rules: {
                      required: {
                        value: false,
                        message: "Primary Skill is required",
                      },
                    },
                    textInputProps: {
                      label: "Primary Skill",
                      placeholder: "Please Enter Your Primary Skills",
                    },
                  },
                ]}
              />
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "secondarySkill",
                    rules: {
                      required: {
                        value: false,
                        message: "Secondary Skill is required",
                      },
                    },
                    textInputProps: {
                      label: "Secondary Skill",
                      placeholder: "Please Enter Your Secondary Skill",
                    },
                  },
                ]}
              />
              {/* <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "tags",
                    rules: {
                      required: {
                        value: true,
                        message: "Tags is required",
                      },
                    },
                    textInputProps: {
                      label: "Tags",
                      placeholder: "Please Enter Tags",
                    },
                  },
                ]}
              /> */}
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "relocation",
                    rules: {
                      required: {
                        value: false,
                        message: "Relocation is required",
                      },
                    },
                    textInputProps: {
                      label: "Relocation (Yes/No)",
                      placeholder: "Please Enter (Yes/No) ",
                    },
                  },
                ]}
              />
            </ScrollView>
          </List.Accordion>
          <List.Accordion
            title="Employer Info"
            id="1"
            titleStyle={{ textAlign: "center" }}
          >
            <ScrollView contentContainerStyle={styles.scrollViewStyle}>
              <Text>{"Employer Name"}</Text>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "employerName",
                    rules: {
                      required: {
                        value: false,
                        message: "Employer Name is required",
                      },
                    },
                    textInputProps: {
                      // label: 'Please Enter FirstName',
                      placeholder: "Please Enter Employer name",
                    },
                  },
                ]}
              />
              <Text>{"Employer Email"}</Text>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "employerEmail",
                    rules: {
                      required: {
                        value: false,
                        message: "Employer Email is required",
                      },
                    },
                    textInputProps: {
                      // label: 'Please Enter FirstName',
                      placeholder: "Please Enter Employer email",
                    },
                  },
                ]}
              />
              <Text>{"Employer Phone"}</Text>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "employerPhone",
                    rules: {
                      required: {
                        value: false,
                        message: "Employer Phone is required",
                      },
                    },
                    textInputProps: {
                      // label: 'Please Enter FirstName',
                      placeholder: "Please Enter Employer phone",
                    },
                  },
                ]}
              />
            </ScrollView>
          </List.Accordion>

          <List.Accordion
            title="Student Info"
            id="1"
            titleStyle={{ textAlign: "center" }}
          >
            <ScrollView contentContainerStyle={styles.scrollViewStyle}>
              <Text>{"University"}</Text>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "university",
                    rules: {
                      required: {
                        value: false,
                        message: "University is required",
                      },
                    },
                    textInputProps: {
                      placeholder: "Please Enter University",
                    },
                  },
                ]}
              />
              <Text>{"Degree / Specialization"}</Text>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "degree",
                    rules: {
                      required: {
                        value: false,
                        message: "Degree / Specialization is required",
                      },
                    },
                    textInputProps: {
                      placeholder: "Please Enter Degree / Specialization",
                    },
                  },
                ]}
              />
              <Text>{"Year Joined"}</Text>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "yearJoined",
                    rules: {
                      required: {
                        value: false,
                        message: "Year Joined is required",
                      },
                    },
                    textInputProps: {
                      placeholder: "Please Enter Year Joined",
                    },
                  },
                ]}
              />
              <Text>{"Linkedin Url"}</Text>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                formConfigArray={[
                  {
                    type: "text",
                    name: "linkedInUrl",
                    rules: {
                      required: {
                        value: false,
                        message: "Linkedin Url is required",
                      },
                    },
                    textInputProps: {
                      placeholder: "Please Enter Linkedin Url",
                    },
                  },
                ]}
              />
              {/* <Text>{"Current Visa Status "}</Text>
            <FormBuilder
              control={control}
              setFocus={setFocus}
              defaultValue={"Please Select Current Visa Status"}
              formConfigArray={[
                {
                  type: "select",
                  name: "currentVisaStatus",
                  rules: {
                    required: {
                      value: true,
                      message: "Current Visa Status  is required",
                    },
                  },
                  textInputProps: {
                    placeholder: "Please Enter Current Visa Status ",
                  },
                  options: [
                    {
                      label: "F1",
                      value: "F1",
                    },
                    {
                      label: "OPT",
                      value: "OPT",
                    },
                    {
                      label: "CPT",
                      value: "CPT",
                    },
                  ],
                },
              ]}
            /> */}
              <Text>{"Interested Platform"}</Text>
              <FormBuilder
                control={control}
                setFocus={setFocus}
                defaultValue={"Please Select Platform"}
                formConfigArray={[
                  {
                    name: "interest",
                    type: "select",
                    textInputProps: {
                      placeholder: "Please Enter Interested Platform",
                    },
                    rules: {
                      required: {
                        value: false,
                        message: "Interested Platform is required",
                      },
                    },
                    options: [
                      {
                        label: "Full Stack",
                        value: "Full Stack",
                      },
                      {
                        label: "DevOps",
                        value: "DevOps",
                      },
                      {
                        label: "QA",
                        value: "QA",
                      },
                      {
                        label: "BA",
                        value: "BA",
                      },
                      {
                        label: "Other",
                        value: "Other",
                      },
                      {
                        label: "Don't know",
                        value: "Don't know",
                      },
                    ],
                  },
                ]}
              />
            </ScrollView>
          </List.Accordion>
        </Card.Content>
      </ScrollView>
    </Card>
  );
};

export default EditContact;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  helpertext: {
    fontSize: 12,
    paddingHorizontal: 0,
  },
  scrollViewStyle: {
    padding: 15,
    justifyContent: "center",
  },
});
