import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  StyleSheet,
  Platform,
  ScrollView,
  SafeAreaView,
  Dimensions,
  FlatList,
} from "react-native";

import {
  Avatar,
  Button,
  Card,
  Title,
  Paragraph,
  TextInput,
  Text,
  DataTable,
  IconButton,
  Portal,
  Modal,
  RadioButton,
  ActivityIndicator,
  Divider,
} from "react-native-paper";
import Logo from "../../../global/components/Logo";

import Header from "../../../global/components/Header";
import FontAwesome from "react-native-vector-icons/FontAwesome";

import Amplify, {
  Auth,
  graphqlOperation,
  API,
  DataStore,
  Predicates,
} from "aws-amplify";
import awsconfig from "../../../aws-exports";

import { listContacts } from "../../../graphql/queries";

Auth.configure(awsconfig);
API.configure(awsconfig);

const Home = (props) => {
  let [team, setTeam] = useState([]);
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = React.useState({
    "All Time": false,
    "7 Days": false,
    "15 Days": false,
    "30 Days": false,
    "60 Days": false,
    "90 Days": false,
  });
  const [timeValue, setTimeValue] = useState("All Time");

  const containerStyle = {
    backgroundColor: "transparent",
    width: "100%",
    height: "40%",
    alignSelf: "center",
  };

  const fetchTeam = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    let userGroups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];

    await API.graphql({
      query: listContacts,
      variables: {
        filter: {
          or: [
            { contactType: { eq: "RECRUITER" } },
            { contactType: { eq: "MANAGER" } },
          ],
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        // console.log(res)
        setTeam(res.data.listContacts.items);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  useEffect(() => {
    fetchTeam();
  }, []);

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"E-Suite"}
      />
      <Card.Content
        style={{
          flex: 1,
          paddingHorizontal: 0,
        }}
      >
        <Card.Content
          style={{
            // flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Image
            source={require("../../../../assets/images/logo.png")}
            style={styles.image}
          />
          <Title
            style={{
              textAlign: "center",
              paddingHorizontal: 0,
              marginVertical: 20,
            }}
          >
            {"Team Dashboard"}
          </Title>
        </Card.Content>
        <Card.Content
          style={{
            justifyContent: "center",
            alignItems: "flex-end",
            flex: 1,
          }}
        >
          <Card.Content
            style={{
              borderWidth: 0.6,
              paddingVertical: 10,
            }}
          >
            <Text
              style={{
                paddingHorizontal: 0,
                fontSize: 12,
              }}
              onPress={() => {
                console.log("yes");
                setVisible(true);
              }}
            >
              {timeValue}
              {"  "}
              <FontAwesome
                name="angle-down"
                size={12}
                onPress={() => {
                  console.log("yes");
                  setVisible(true);
                }}
              />
            </Text>
          </Card.Content>
        </Card.Content>

        <ScrollView>
          <Card.Content
            style={{
              // flex: 1,
              // width: Platform.OS !== "web" ? "100%" : "50%",
              // paddingVertical: 15,
              // justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Card.Content style={styles.cardOne}>
              <Card.Content
                style={{
                  backgroundColor: "#f79000",
                  paddingHorizontal: 0,
                  width: Platform.OS !== "web" ? "48%" : "20%",
                  // height: 120
                  // justifyContent: "center",
                  // alignItems: "center",
                  marginRight: 10,
                }}
              >
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    marginTop: 20,
                    color: "white",
                  }}
                >
                  {"0"}
                </Title>
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    color: "white",
                    marginBottom: 10,
                  }}
                >
                  {"Consultants"}
                </Title>
                <Card.Content
                  style={{
                    backgroundColor: "#7a4901",
                    opacity: 0.3,
                    paddingHorizontal: 0,
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Title
                    style={{
                      textAlign: "center",
                      paddingHorizontal: 0,
                      color: "white",
                      fontSize: 12,
                      // width: "100%",
                      opacity: 1,
                      marginRight: 10,
                    }}
                  >
                    {"More Info"}
                  </Title>
                  <FontAwesome
                    style={{
                      marginTop: 10,
                    }}
                    name="arrow-circle-right"
                    size={16}
                  />
                </Card.Content>
              </Card.Content>
              <Card.Content
                style={{
                  backgroundColor: "#f76a3b",
                  width: Platform.OS !== "web" ? "48%" : "20%",
                  // height: 120,
                  marginLeft: 7,
                  paddingHorizontal: 0,
                }}
              >
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    marginTop: 20,
                    color: "white",
                  }}
                >
                  {"0"}
                </Title>
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    color: "white",
                    marginBottom: 10,
                  }}
                >
                  {"Vendors"}
                </Title>
                <Card.Content
                  style={{
                    backgroundColor: "#7a4901",
                    opacity: 0.3,
                    paddingHorizontal: 0,
                    flexDirection: "row",
                    justifyContent: "center",
                    // height:25,
                  }}
                >
                  <Title
                    style={{
                      textAlign: "center",
                      paddingHorizontal: 0,
                      color: "white",
                      fontSize: 12,
                      // width: "100%",
                      opacity: 1,
                      marginRight: 10,
                    }}
                  >
                    {"More Info"}
                  </Title>
                  <FontAwesome
                    style={{
                      marginTop: 10,
                    }}
                    name="arrow-circle-right"
                    size={16}
                  />
                </Card.Content>
              </Card.Content>
            </Card.Content>
            <Card.Content style={styles.cardOne}>
              <Card.Content
                style={{
                  backgroundColor: "#ab8bfc",
                  width: Platform.OS !== "web" ? "48%" : "20%",
                  // height: 120,
                  marginRight: 10,
                  paddingHorizontal: 0,
                }}
              >
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    marginTop: 20,
                    color: "white",
                  }}
                >
                  {"0"}
                </Title>
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    color: "white",
                    marginBottom: 10,
                  }}
                >
                  {"Submissions"}
                </Title>
                <Card.Content
                  style={{
                    backgroundColor: "#3e0475",
                    opacity: 0.3,
                    paddingHorizontal: 0,
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Title
                    style={{
                      textAlign: "center",
                      paddingHorizontal: 0,
                      color: "white",
                      fontSize: 12,
                      // width: "100%",
                      opacity: 1,
                      marginRight: 10,
                    }}
                  >
                    {"More Info"}
                  </Title>
                  <FontAwesome
                    style={{
                      marginTop: 10,
                    }}
                    name="arrow-circle-right"
                    size={16}
                  />
                </Card.Content>
              </Card.Content>
              <Card.Content
                style={{
                  backgroundColor: "#5587d9",
                  width: Platform.OS !== "web" ? "48%" : "20%",
                  // height: 120,
                  marginLeft: 7,
                  paddingHorizontal: 0,
                }}
              >
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    marginTop: 20,
                    color: "white",
                  }}
                >
                  {"0"}
                </Title>
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    color: "white",
                    marginBottom: 10,
                  }}
                >
                  {"Leads"}
                </Title>
                <Card.Content
                  style={{
                    backgroundColor: "#074482",
                    opacity: 0.3,
                    paddingHorizontal: 0,
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Title
                    style={{
                      textAlign: "center",
                      paddingHorizontal: 0,
                      color: "white",
                      fontSize: 12,
                      // width: "100%",
                      opacity: 1,
                      marginRight: 10,
                    }}
                  >
                    {"More Info"}
                  </Title>
                  <FontAwesome
                    style={{
                      marginTop: 10,
                    }}
                    name="arrow-circle-right"
                    size={16}
                  />
                </Card.Content>
              </Card.Content>
            </Card.Content>
            {/* <Card.Content style={styles.cardOne}>
              <Card.Content
                style={{
                  backgroundColor: "red",
                  width:Platform.OS !== "web" ? "48%" : "20%",
                  height: 100,
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    marginTop: 20,
                  }}
                >
                  {"Consultants"}
                </Title>
              </Card.Content>
              <Card.Content
                style={{
                  backgroundColor: "green",
                  width:Platform.OS !== "web" ? "48%" : "20%",
                  height: 100,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 7,
                }}
              >
                <Title
                  style={{
                    textAlign: "center",
                    paddingHorizontal: 0,
                    marginTop: 20,
                  }}
                >
                  {"Vendors"}
                </Title>
              </Card.Content>
            </Card.Content> */}
          </Card.Content>
        </ScrollView>

        <Portal theme={{ colors: { backdrop: "rgba(0, 0, 0, 0.6)" } }}>
          <Modal
            visible={visible}
            dismissable={true}
            onDismiss={() => setVisible(false)}
            contentContainerStyle={containerStyle}
          >
            <Card.Content
              style={{
                flex: 1,
                backgroundColor: "white",
                marginHorizontal: 30,
                paddingHorizontal: 0,
              }}
            >
              <TouchableOpacity
                style={{ paddingHorizontal: 0 }}
                onPress={() => {
                  setChecked({ "All Time": true });
                  setTimeValue("All Time");
                  setVisible(!visible);
                }}
              >
                <Card.Content
                  style={{ flexDirection: "row", paddingVertical: 10 }}
                >
                  <Card.Content
                    style={{ justifyContent: "flex-start", width: "50%" }}
                  >
                    <Text
                      style={{
                        marginTop: 5,
                        fontSize: 20,
                      }}
                    >
                      {"All Time"}
                    </Text>
                  </Card.Content>

                  <Card.Content
                    style={{
                      justifyContent: "flex-end",
                      width: "50%",
                      alignItems: "flex-end",
                    }}
                  >
                    <RadioButton
                      value="first"
                      status={checked["All Time"] ? "checked" : "unchecked"}
                    />
                  </Card.Content>
                </Card.Content>
                <Divider />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ paddingHorizontal: 0 }}
                onPress={() => {
                  setChecked({ "7 Days": true });
                  setTimeValue("7 Days");
                  setVisible(!visible);
                }}
              >
                <Card.Content
                  style={{ flexDirection: "row", paddingVertical: 10 }}
                >
                  <Card.Content
                    style={{ justifyContent: "flex-start", width: "50%" }}
                  >
                    <Text
                      style={{
                        marginTop: 5,
                        fontSize: 20,
                      }}
                    >
                      {"7 Days"}
                    </Text>
                  </Card.Content>

                  <Card.Content
                    style={{
                      justifyContent: "flex-end",
                      width: "50%",
                      alignItems: "flex-end",
                    }}
                  >
                    <RadioButton
                      value="first"
                      status={checked["7 Days"] ? "checked" : "unchecked"}
                    />
                  </Card.Content>
                </Card.Content>
                <Divider />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ paddingHorizontal: 0 }}
                onPress={() => {
                  setChecked({ "15 Days": true });
                  setTimeValue("15 Days");
                  setVisible(!visible);
                }}
              >
                <Card.Content
                  style={{ flexDirection: "row", paddingVertical: 10 }}
                >
                  <Card.Content
                    style={{ justifyContent: "flex-start", width: "50%" }}
                  >
                    <Text
                      style={{
                        marginTop: 5,
                        fontSize: 20,
                      }}
                    >
                      {"15 Days"}
                    </Text>
                  </Card.Content>

                  <Card.Content
                    style={{
                      justifyContent: "flex-end",
                      width: "50%",
                      alignItems: "flex-end",
                    }}
                  >
                    <RadioButton
                      value="first"
                      status={checked["15 Days"] ? "checked" : "unchecked"}
                    />
                  </Card.Content>
                </Card.Content>
                <Divider />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ paddingHorizontal: 0 }}
                onPress={() => {
                  setChecked({ "30 Days": true });
                  setTimeValue("30 Days");
                  setVisible(!visible);
                }}
              >
                <Card.Content
                  style={{ flexDirection: "row", paddingVertical: 10 }}
                >
                  <Card.Content
                    style={{ justifyContent: "flex-start", width: "50%" }}
                  >
                    <Text
                      style={{
                        marginTop: 5,
                        fontSize: 20,
                      }}
                    >
                      {"30 Days"}
                    </Text>
                  </Card.Content>

                  <Card.Content
                    style={{
                      justifyContent: "flex-end",
                      width: "50%",
                      alignItems: "flex-end",
                    }}
                  >
                    <RadioButton
                      value="first"
                      status={checked["30 Days"] ? "checked" : "unchecked"}
                    />
                  </Card.Content>
                </Card.Content>
                <Divider />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ paddingHorizontal: 0 }}
                onPress={() => {
                  setChecked({ "60 Days": true });
                  setTimeValue("60 Days");
                  setVisible(!visible);
                }}
              >
                <Card.Content
                  style={{ flexDirection: "row", paddingVertical: 10 }}
                >
                  <Card.Content
                    style={{ justifyContent: "flex-start", width: "50%" }}
                  >
                    <Text
                      style={{
                        marginTop: 5,
                        fontSize: 20,
                      }}
                    >
                      {"60 Days"}
                    </Text>
                  </Card.Content>

                  <Card.Content
                    style={{
                      justifyContent: "flex-end",
                      width: "50%",
                      alignItems: "flex-end",
                    }}
                  >
                    <RadioButton
                      value="first"
                      status={checked["60 Days"] ? "checked" : "unchecked"}
                    />
                  </Card.Content>
                </Card.Content>
                <Divider />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ paddingHorizontal: 0 }}
                onPress={() => {
                  setChecked({ "90 Days": true });
                  setTimeValue("90 Days");
                  setVisible(!visible);
                }}
              >
                <Card.Content
                  style={{ flexDirection: "row", paddingVertical: 10 }}
                >
                  <Card.Content
                    style={{ justifyContent: "flex-start", width: "50%" }}
                  >
                    <Text
                      style={{
                        marginTop: 5,
                        fontSize: 20,
                      }}
                    >
                      {"90 Days"}
                    </Text>
                  </Card.Content>

                  <Card.Content
                    style={{
                      justifyContent: "flex-end",
                      width: "50%",
                      alignItems: "flex-end",
                    }}
                  >
                    <RadioButton
                      value="first"
                      status={checked["90 Days"] ? "checked" : "unchecked"}
                    />
                  </Card.Content>
                </Card.Content>
                <Divider />
              </TouchableOpacity>
            </Card.Content>
          </Modal>
        </Portal>
      </Card.Content>
    </Card>
  );
};

export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  logoContainer: {
    marginBottom: 20,
    height: 90,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 380,
    height: 94,
    marginTop: 20,
  },
  card: {
    width: "100%",
    marginBottom: 20,
    marginTop: 20,
  },
  cardArea: {
    width: "100%",
    paddingLeft: Platform.OS == "web" ? 20 : 60,
    paddingRight: Platform.OS == "web" ? 20 : 60,
  },
  image: {
    width: 400,
    height: 70,
    marginTop: 10,
    resizeMode: "contain",
  },
  cardOne: {
    flexDirection: "row",
    paddingHorizontal: 0,
    marginBottom: 20,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
