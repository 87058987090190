import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Lessons from "../components/Lessons";

const LessonsScreen = (props) => {
  return <Lessons {...props} />;
};

export default LessonsScreen;

const styles = StyleSheet.create({});
