import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CreateCourseLesson from '../components/CreateCourseLesson'

const CreateCourseLessonScreen = (props) => {
    return <CreateCourseLesson {...props} />
}

export default CreateCourseLessonScreen

const styles = StyleSheet.create({})