import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CreateContactNote from '../components/CreateContactNote';

const CreateContactNoteScreen = (props) => {
 return <CreateContactNote {...props} />
}

export default CreateContactNoteScreen

const styles = StyleSheet.create({})