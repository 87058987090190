import React, { useState } from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";
import {
  Card,
  Title,
  Paragraph,
  Avatar,
  IconButton,
  Button,
} from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import AntDesign from "react-native-vector-icons/AntDesign";

const UserItem = (props) => {
  //   console.log(props.item?.contactType.charAt(0), "props");
  let label = props;
  return (
    <View style={styles.contactContainer}>
      <Card>
        <Card.Title
          title={`${props.item.firstName} ${props.item.lastName}`}
          subtitle={`${props.item.email} | ${props.item.phone}`}
          left={(props) => (
            <Avatar.Text
              {...props}
              color={"white"}
              size={48}
              label={label.item?.contactType.charAt(0)}
            />
          )}
          right={(props) => (
            <Card.Content style={{ flexDirection: "row" }}>
              <MaterialCommunityIcons
                {...props}
                name="lock-reset"
                onPress={() => {}}
              />
              <AntDesign {...props} name="deleteuser" onPress={() => {}} />
            </Card.Content>

            // <Card.Content style={{ flexDirection: "row" }}>
            // <TouchableOpacity
            //   style={{
            //     backgroundColor: "blue",
            //     borderWidth: 1,
            //     borderColor: "transparent",
            //     padding: 10,
            //     borderRadius: 5,
            //     // width: "30%",
            //     height: 40,
            //     justifyContent: "center",
            //     alignItems: "center",
            //   }}
            // >
            //   <Text
            //     style={{ color: "white", fontSize: 8, fontWeight: "bold" }}
            //   >
            //     ReSend Password
            //   </Text>
            // </TouchableOpacity>
            //   <TouchableOpacity
            //     style={{
            //       backgroundColor: "blue",
            //       borderWidth: 1,
            //       borderColor: "transparent",
            //       padding: 10,
            //       borderRadius: 5,
            //       width: "30%",
            //       height: 40,
            //       justifyContent: "center",
            //       alignItems: "center",
            //     }}
            //   >
            //     <Text
            //       style={{ color: "white", fontSize: 14, fontWeight: "bold" }}
            //     >
            //       Disable User
            //     </Text>
            //   </TouchableOpacity>
            // </Card.Content>
          )}
        />
      </Card>
    </View>
  );
};

export default UserItem;

const styles = StyleSheet.create({
  card: {
    marginTop: 20,
  },
  title: {
    top: 20,
    left: 100,
    fontSize: 24,
  },
  image: {
    height: 100,
  },
  action: {
    backgroundColor: "black",
    color: "white",
    paddingBottom: 5,
    paddingTop: 5,
  },
  icon: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "white",
    backgroundColor: "rgba(255,255,255,0)",
  },
  contactContainer: {
    margin: 10,
  },
  contactName: {
    fontSize: 16,
    margin: 2,
  },
});
