import React, { memo, useEffect, useRef, useState } from "react";
import { StyleSheet, Text, AppState } from "react-native";
import {
  Appbar,
  Portal,
  Modal,
  Dialog,
  Button,
  Paragraph,
} from "react-native-paper";
import { theme } from "../../../theme";
import moment from "moment";
import Amplify, { Auth } from 'aws-amplify';
import config from "../../../src/aws-exports";

Auth.configure(config);


const Header = (props) => {
  // console.log(props);
  const [visible, setVisible] = React.useState(false);
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  let today = new Date();
  let presentTime = today.getHours() + ":" + today.getMinutes();

  let futureDate = new Date(new Date().getTime() + 1 * 60000);
  let afterTime = futureDate.getHours() + ":" + futureDate.getMinutes();

  const logoutOfApp = () => {
    console.log(AppState.currentState);
    if (AppState.currentState !== 'active') {
      console.log("log");
      onSignOut()
    }
  }

  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  const onSignOut = () => {
    Auth.signOut().then(() => {
      props.navigation.navigate("Login");
    })

  };

  return (
    <Appbar.Header>
      {props.showBack ? (
        <Appbar.Action
          icon="chevron-left"
          onPress={() => {
            props.navigation.goBack();
            // console.log(route.name);
          }}
        ></Appbar.Action>
      ) : (
        <Appbar.Action
          icon="menu"
          onPress={() => {
            props.navigation.toggleDrawer();
            // console.log(route.name);
          }}
        ></Appbar.Action>
      )}
      <Appbar.Content title={props.children} />
      {
        props.leftType === 'icon' ?
          (<Appbar.Action
            icon={props.leftIcon}
            onPress={() => {
              props.navigation.navigate(props.routename, {
                type: props.typeName && props.typeName,
              });
            }}
          />)
          :
          (<Button mode='text' onPress={() => props.onPress()}
            color="white" uppercase={false}
          >
            {props.leftName}
          </Button>)
      }
    </Appbar.Header>
  );
};
export default Header;

const styles = StyleSheet.create({
  header: {
    fontSize: 26,
    color: theme.colors.primary,
    fontWeight: "bold",
    paddingVertical: 14,
  },
});
