import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import ContactDetails from '../components/ContactDetails'

const ContactDetailsScreen = (props) => {
  return (
    <ContactDetails {...props} />
  )
}

export default ContactDetailsScreen

const styles = StyleSheet.create({})