import { StyleSheet, Text, View } from "react-native";
import React from "react";
import ContactInfo from "../components/ContactInfo";

const ConsultantOnboardingScreen = (props) => {
  return <ContactInfo {...props} />;
};

export default ConsultantOnboardingScreen;

const styles = StyleSheet.create({});
