// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SubmissionStatus = {
  "PENDING": "PENDING",
  "SUBMITTED": "SUBMITTED",
  "SHORTLISTED": "SHORTLISTED",
  "PROFILE_REJECTED": "PROFILE_REJECTED",
  "INTERVIEW_REJECTED": "INTERVIEW_REJECTED",
  "ONHOLD": "ONHOLD",
  "WITHDRAWN": "WITHDRAWN"
};

const ContactType = {
  "CONSULTANT": "CONSULTANT",
  "VENDOR": "VENDOR",
  "RECRUITER": "RECRUITER",
  "BDM": "BDM",
  "MANAGER": "MANAGER",
  "STUDENT": "STUDENT",
  "CLIENT": "CLIENT",
  "APPLICANT": "APPLICANT"
};

const TaskType = {
  "CALL": "CALL",
  "EMAIL": "EMAIL",
  "SMS": "SMS",
  "MEETING": "MEETING",
  "FOLLOWUP": "FOLLOWUP"
};

const ContractType = {
  "W2": "W2",
  "C2_C": "C2C"
};

const { ContactCount, Notification, CourseLesson, Submission, Job, Contact, Course, Topic, Note, ContactFile, ContactTask, ContactAddress } = initSchema(schema);

export {
  ContactCount,
  Notification,
  CourseLesson,
  Submission,
  Job,
  Contact,
  Course,
  SubmissionStatus,
  ContactType,
  TaskType,
  ContractType,
  Topic,
  Note,
  ContactFile,
  ContactTask,
  ContactAddress
};