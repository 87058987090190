/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateContactRelation = /* GraphQL */ `
  subscription OnCreateContactRelation(
    $filter: ModelSubscriptionContactRelationFilterInput
  ) {
    onCreateContactRelation(filter: $filter) {
      id
      relation
      relationContactID
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactRelation = /* GraphQL */ `
  subscription OnUpdateContactRelation(
    $filter: ModelSubscriptionContactRelationFilterInput
  ) {
    onUpdateContactRelation(filter: $filter) {
      id
      relation
      relationContactID
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactRelation = /* GraphQL */ `
  subscription OnDeleteContactRelation(
    $filter: ModelSubscriptionContactRelationFilterInput
  ) {
    onDeleteContactRelation(filter: $filter) {
      id
      relation
      relationContactID
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLead = /* GraphQL */ `
  subscription OnCreateLead($filter: ModelSubscriptionLeadFilterInput) {
    onCreateLead(filter: $filter) {
      id
      name
      phone
      email
      extraData
      leadType
      leadSource
      verifiedEmail
      verifiedPhone
      unsubscribed
      assignedContact
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLead = /* GraphQL */ `
  subscription OnUpdateLead($filter: ModelSubscriptionLeadFilterInput) {
    onUpdateLead(filter: $filter) {
      id
      name
      phone
      email
      extraData
      leadType
      leadSource
      verifiedEmail
      verifiedPhone
      unsubscribed
      assignedContact
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLead = /* GraphQL */ `
  subscription OnDeleteLead($filter: ModelSubscriptionLeadFilterInput) {
    onDeleteLead(filter: $filter) {
      id
      name
      phone
      email
      extraData
      leadType
      leadSource
      verifiedEmail
      verifiedPhone
      unsubscribed
      assignedContact
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContactCount = /* GraphQL */ `
  subscription OnCreateContactCount {
    onCreateContactCount {
      id
      consultantCount
      submissionCount
      countDate
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactCount = /* GraphQL */ `
  subscription OnUpdateContactCount {
    onUpdateContactCount {
      id
      consultantCount
      submissionCount
      countDate
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactCount = /* GraphQL */ `
  subscription OnDeleteContactCount {
    onDeleteContactCount {
      id
      consultantCount
      submissionCount
      countDate
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      notificationType
      entityType
      entityId
      entityName
      actorId
      actorName
      message
      notifiers
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      notificationType
      entityType
      entityId
      entityName
      actorId
      actorName
      message
      notifiers
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      notificationType
      entityType
      entityId
      entityName
      actorId
      actorName
      message
      notifiers
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCourseLesson = /* GraphQL */ `
  subscription OnCreateCourseLesson {
    onCreateCourseLesson {
      id
      title
      description
      courseID
      topics {
        title
        description
        videoUrl
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourseLesson = /* GraphQL */ `
  subscription OnUpdateCourseLesson {
    onUpdateCourseLesson {
      id
      title
      description
      courseID
      topics {
        title
        description
        videoUrl
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourseLesson = /* GraphQL */ `
  subscription OnDeleteCourseLesson {
    onDeleteCourseLesson {
      id
      title
      description
      courseID
      topics {
        title
        description
        videoUrl
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSubmission = /* GraphQL */ `
  subscription OnCreateSubmission {
    onCreateSubmission {
      id
      job {
        id
        title
        vendor {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        client
        primeVendor
        positionType
        location
        ecorfyRate
        description
        additionalNotes
        status
        jobNotes {
          message
          userName
          createdAt
        }
        createdAt
        updatedAt
        jobVendorId
      }
      consultant {
        id
        userId
        firstName
        lastName
        phone
        email
        contactType
        email2
        email3
        phone2
        phone3
        middleName
        title
        jobTitle
        dob
        linkedinUrl
        githubUrl
        contactFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        contactNotes {
          message
          userName
          createdAt
        }
        contactTasks {
          message
          taskType
          priority
          userId
          taskStatus
        }
        shippingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        timezone
        leadSource
        gender
        openToWork
        primarySkill
        secondarySkill
        skillTags
        experience
        relocation
        whatsappId
        ssn
        employerName
        employerEmail
        employerPhone
        visaType
        contractType
        managerId
        consultantCount
        submissionCount
        deviceToken
        deviceOS
        university
        degree
        yearJoined
        seasonJoined
        interest
        city
        state
        contactCounts {
          nextToken
        }
        contactMessages {
          messageUserName
          message
          createdAt
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      status
      recruiter {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      manager {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      consultantRate
      updatedAt
      submissionJobId
      submissionConsultantId
      submissionRecruiterId
      submissionManagerId
    }
  }
`;
export const onUpdateSubmission = /* GraphQL */ `
  subscription OnUpdateSubmission {
    onUpdateSubmission {
      id
      job {
        id
        title
        vendor {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        client
        primeVendor
        positionType
        location
        ecorfyRate
        description
        additionalNotes
        status
        jobNotes {
          message
          userName
          createdAt
        }
        createdAt
        updatedAt
        jobVendorId
      }
      consultant {
        id
        userId
        firstName
        lastName
        phone
        email
        contactType
        email2
        email3
        phone2
        phone3
        middleName
        title
        jobTitle
        dob
        linkedinUrl
        githubUrl
        contactFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        contactNotes {
          message
          userName
          createdAt
        }
        contactTasks {
          message
          taskType
          priority
          userId
          taskStatus
        }
        shippingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        timezone
        leadSource
        gender
        openToWork
        primarySkill
        secondarySkill
        skillTags
        experience
        relocation
        whatsappId
        ssn
        employerName
        employerEmail
        employerPhone
        visaType
        contractType
        managerId
        consultantCount
        submissionCount
        deviceToken
        deviceOS
        university
        degree
        yearJoined
        seasonJoined
        interest
        city
        state
        contactCounts {
          nextToken
        }
        contactMessages {
          messageUserName
          message
          createdAt
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      status
      recruiter {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      manager {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      consultantRate
      updatedAt
      submissionJobId
      submissionConsultantId
      submissionRecruiterId
      submissionManagerId
    }
  }
`;
export const onDeleteSubmission = /* GraphQL */ `
  subscription OnDeleteSubmission {
    onDeleteSubmission {
      id
      job {
        id
        title
        vendor {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        client
        primeVendor
        positionType
        location
        ecorfyRate
        description
        additionalNotes
        status
        jobNotes {
          message
          userName
          createdAt
        }
        createdAt
        updatedAt
        jobVendorId
      }
      consultant {
        id
        userId
        firstName
        lastName
        phone
        email
        contactType
        email2
        email3
        phone2
        phone3
        middleName
        title
        jobTitle
        dob
        linkedinUrl
        githubUrl
        contactFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        contactNotes {
          message
          userName
          createdAt
        }
        contactTasks {
          message
          taskType
          priority
          userId
          taskStatus
        }
        shippingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        timezone
        leadSource
        gender
        openToWork
        primarySkill
        secondarySkill
        skillTags
        experience
        relocation
        whatsappId
        ssn
        employerName
        employerEmail
        employerPhone
        visaType
        contractType
        managerId
        consultantCount
        submissionCount
        deviceToken
        deviceOS
        university
        degree
        yearJoined
        seasonJoined
        interest
        city
        state
        contactCounts {
          nextToken
        }
        contactMessages {
          messageUserName
          message
          createdAt
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      status
      recruiter {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      manager {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      consultantRate
      updatedAt
      submissionJobId
      submissionConsultantId
      submissionRecruiterId
      submissionManagerId
    }
  }
`;
export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse {
    onCreateCourse {
      id
      title
      description
      courseNotes {
        message
        userName
        createdAt
      }
      courseFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      courseLessons {
        items {
          id
          title
          description
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse {
    onUpdateCourse {
      id
      title
      description
      courseNotes {
        message
        userName
        createdAt
      }
      courseFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      courseLessons {
        items {
          id
          title
          description
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse {
    onDeleteCourse {
      id
      title
      description
      courseNotes {
        message
        userName
        createdAt
      }
      courseFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      courseLessons {
        items {
          id
          title
          description
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJob = /* GraphQL */ `
  subscription OnCreateJob {
    onCreateJob {
      id
      title
      vendor {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      client
      primeVendor
      positionType
      location
      ecorfyRate
      description
      additionalNotes
      status
      jobNotes {
        message
        userName
        createdAt
      }
      createdAt
      updatedAt
      jobVendorId
    }
  }
`;
export const onUpdateJob = /* GraphQL */ `
  subscription OnUpdateJob {
    onUpdateJob {
      id
      title
      vendor {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      client
      primeVendor
      positionType
      location
      ecorfyRate
      description
      additionalNotes
      status
      jobNotes {
        message
        userName
        createdAt
      }
      createdAt
      updatedAt
      jobVendorId
    }
  }
`;
export const onDeleteJob = /* GraphQL */ `
  subscription OnDeleteJob {
    onDeleteJob {
      id
      title
      vendor {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      client
      primeVendor
      positionType
      location
      ecorfyRate
      description
      additionalNotes
      status
      jobNotes {
        message
        userName
        createdAt
      }
      createdAt
      updatedAt
      jobVendorId
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact($owner: String) {
    onCreateContact(owner: $owner) {
      id
      userId
      firstName
      lastName
      phone
      email
      contactType
      email2
      email3
      phone2
      phone3
      middleName
      title
      jobTitle
      dob
      linkedinUrl
      githubUrl
      contactFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      contactNotes {
        message
        userName
        createdAt
      }
      contactTasks {
        message
        taskType
        priority
        userId
        taskStatus
      }
      shippingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      billingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      timezone
      leadSource
      gender
      openToWork
      primarySkill
      secondarySkill
      skillTags
      experience
      relocation
      whatsappId
      ssn
      employerName
      employerEmail
      employerPhone
      visaType
      contractType
      managerId
      consultantCount
      submissionCount
      deviceToken
      deviceOS
      university
      degree
      yearJoined
      seasonJoined
      interest
      city
      state
      contactCounts {
        items {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        nextToken
      }
      contactMessages {
        messageUserName
        message
        createdAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact($owner: String) {
    onUpdateContact(owner: $owner) {
      id
      userId
      firstName
      lastName
      phone
      email
      contactType
      email2
      email3
      phone2
      phone3
      middleName
      title
      jobTitle
      dob
      linkedinUrl
      githubUrl
      contactFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      contactNotes {
        message
        userName
        createdAt
      }
      contactTasks {
        message
        taskType
        priority
        userId
        taskStatus
      }
      shippingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      billingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      timezone
      leadSource
      gender
      openToWork
      primarySkill
      secondarySkill
      skillTags
      experience
      relocation
      whatsappId
      ssn
      employerName
      employerEmail
      employerPhone
      visaType
      contractType
      managerId
      consultantCount
      submissionCount
      deviceToken
      deviceOS
      university
      degree
      yearJoined
      seasonJoined
      interest
      city
      state
      contactCounts {
        items {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        nextToken
      }
      contactMessages {
        messageUserName
        message
        createdAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact($owner: String) {
    onDeleteContact(owner: $owner) {
      id
      userId
      firstName
      lastName
      phone
      email
      contactType
      email2
      email3
      phone2
      phone3
      middleName
      title
      jobTitle
      dob
      linkedinUrl
      githubUrl
      contactFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      contactNotes {
        message
        userName
        createdAt
      }
      contactTasks {
        message
        taskType
        priority
        userId
        taskStatus
      }
      shippingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      billingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      timezone
      leadSource
      gender
      openToWork
      primarySkill
      secondarySkill
      skillTags
      experience
      relocation
      whatsappId
      ssn
      employerName
      employerEmail
      employerPhone
      visaType
      contractType
      managerId
      consultantCount
      submissionCount
      deviceToken
      deviceOS
      university
      degree
      yearJoined
      seasonJoined
      interest
      city
      state
      contactCounts {
        items {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        nextToken
      }
      contactMessages {
        messageUserName
        message
        createdAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
