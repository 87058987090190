import React, { memo, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";

import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import { Card, ActivityIndicator, Surface } from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";
import { Auth, API, graphqlOperation, DataStore, Storage } from "aws-amplify";

import config from "../../../aws-exports";

import IntlPhoneField from "react-native-intl-phone-field";

API.configure(config);
DataStore.configure(config);
Storage.configure(config);

const ReferencesInfo = (props) => {
  // console.log(props.getState(), "propsstep");
  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reference1Name: "",
      reference1Email: "",
      reference1Phone: "",
      reference2Name: "",
      reference2Email: "",
      reference2Phone: "",
    },
    mode: "onChange",
  });
  const nextStep = (datas) => {
    const { next, saveState } = props;
    next();
    saveState({
      ProfessionalReferences: datas,
      navigation: props.getState().navigation,
    });
  };
  const goBack = () => {
    const { back } = props;
    // Go to previous step
    back();
  };
  return (
    <Card style={{ flex: 1, backgroundColor: "transparent" }}>
      <ScrollView>
        <View style={{ flex: 1, marginVertical: 20 }}>
          <Text
            variant="displayLarge"
            style={{ fontSize: 24, marginBottom: 20, fontWeight: "bold" }}
          >
            Professional References
          </Text>
          <Text>{"Reference 1 Name"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "text",
                name: "reference1Name",
                rules: {
                  required: {
                    value: true,
                    message: "Reference 1 Name is required",
                  },
                  minLength: {
                    value: 3,
                    message: "Reference 1 Name should be atleast 3 characters",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Reference 1 Name",
                },
              },
            ]}
          />
           <Text>{"Reference 1 Email"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "email",
                name: "reference Email",
                rules: {
                  required: {
                    value: true,
                    message: "Reference 1 Email is required",
                  },
                  pattern: {
                    value:
                      /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                    message: "Reference 1 Email is invalid",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Reference 1 Email",
                },
              },
            ]}
          />
          <Text>{"Reference 1 Phone"}</Text>
          <Controller
            control={control}
            name="reference1Phone"
            render={({ field: { onChange, value, onBlur } }) => (
              <IntlPhoneField
                onEndEditing={(result) => onChange(result.value)}
                onValidation={(isValid) => console.log(isValid)}
                defaultCountry="IN"
                defaultPrefix="+91"
              />
            )}
            rules={{
              required: {
                value: true,
                message: "Phone Number is required",
              },
              pattern: {
                value: !!/^\+?\d[\d\s-]+$/,
                message: "Phone Number is invalid",
              },
            }}
          />
          {errors?.reference1Phone?.message ? (
            <Text
              style={{
                color: "#fc4c4c",
                fontSize: 12,
                paddingVertical: 2,
                paddingHorizontal: 10,
              }}
            >
              {errors?.reference1Phone?.message}
            </Text>
          ) : null}

          <Text style={{ marginTop: 15 }}>{"Reference 2 Name"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "text",
                name: "reference2Name",
                rules: {
                  required: {
                    value: true,
                    message: "Reference 2 Name is required",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Reference 2 Name",
                },
              },
            ]}
          />
          <Text>{"Reference 2 Email"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "email",
                name: "reference2Email",
                rules: {
                  required: {
                    value: true,
                    message: "Reference 2 Email is required",
                  },
                  pattern: {
                    value:
                      /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                    message: "Reference 2 Email is invalid",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Reference 2 Email",
                },
              },
            ]}
          />
          <Text>{"Reference 2 Phone"}</Text>
          <Controller
            control={control}
            name="reference2Phone"
            render={({ field: { onChange, value, onBlur } }) => (
              <IntlPhoneField
                onEndEditing={(result) => onChange(result.value)}
                  onValidation={(isValid) => console.log(isValid)}
                defaultCountry="IN"
                defaultPrefix="+91"
              />
            )}
            rules={
              {
                required: {
                  value: true,
                  message: "Phone Number is required",
                },
                  pattern: {
                    value: !!/^\+?\d[\d\s-]+$/,
                    message: "Phone Number is invalid",
                  },
              }
            }
          />
          {errors?.reference2Phone?.message ? (
            <Text
              style={{
                color: "#fc4c4c",
                fontSize: 12,
                paddingVertical: 2,
                paddingHorizontal: 10,
              }}
            >
              {errors?.reference2Phone?.message}
            </Text>
          ) : null}
        </View>
        <Card.Content
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginVertical: 30,
          }}
        >
          <TouchableOpacity onPress={goBack} style={styles.button}>
            <Text style={{ color: "white" }}>Back</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={handleSubmit((data) => {
              // console.log("form data", data);
              nextStep(data);
            })}
          >
            <Text style={{ color: "white" }}>Next</Text>
          </TouchableOpacity>
        </Card.Content>
      </ScrollView>
    </Card>
  );
};

export default ReferencesInfo;

const styles = StyleSheet.create({
  button: {
    backgroundColor: "blue",
    borderWidth: 1,
    borderColor: "transparent",
    padding: 10,
    borderRadius: 5,
    width: "30%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});
