import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CreateJobNote from '../components/CreateJobNote';

const CreateJobNoteScreen = (props) => {
    return  <CreateJobNote {...props} />
}

export default CreateJobNoteScreen

const styles = StyleSheet.create({})