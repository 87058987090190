import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CreateJob from '../components/CreateJob';

const CreateJobScreen = (props) => {
    return <CreateJob {...props} />
}

export default CreateJobScreen

const styles = StyleSheet.create({})