import { Platform, StyleSheet, Text, View,Image } from "react-native";
import React, { useEffect, useState } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";

import { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";

import EsuiteDrawer from "../global/components/EsuiteDrawer";

Auth.configure(awsconfig);

//Home
import HomeScreen from "./home/screens/HomeScreen";

//Jobs
import JobsScreen from "./jobs/screens/JobsScreen";
import JobDetailsScreen from "./jobs/screens/JobDetailsScreen";
import CreateJobNoteScreen from "./jobs/screens/CreateJobNoteScreen";
import CreateJobScreen from "./jobs/screens/CreateJobScreen";
import EditJobScreen from "./jobs/screens/EditJobScreen";
import CreateJobSubmissionScreen from "./jobs/screens/CreateJobSubmissionScreen";

//Contacts
import ContactsScreen from "./contacts/screens/ContactsScreen";
import AddContactScreen from "./contacts/screens/AddContactScreen";
import EditContactScreen from "./contacts/screens/EditContactScreen";
import ContactDetailsScreen from "./contacts/screens/ContactDetailsScreen";
import CreateContactFileScreen from "./contacts/screens/CreateContactFileScreen";
import CreateContactNoteScreen from "./contacts/screens/CreateContactNoteScreen";

//Training
import CoursesScreen from "./training/screens/CoursesScreen";
import CourseDetailScreen from "./training/screens/CourseDetailScreen";
import EditCourseScreen from "./training/screens/EditCourseScreen";
import CreateCourseScreen from "./training/screens/CreateCourseScreen";
import CreateCourseFileScreen from "./training/screens/CreateCourseFileScreen";
import CreateCourseNoteScreen from "./training/screens/CreateCourseNoteScreen";
import LessonsScreen from "./training/screens/LeasonsScreen";
import EditLessonScreen from "./training/screens/EditLessonScreen";
import CreateCourseLessonScreen from "./training/screens/CreateCourseLessonScreen";
import CreateCourseLessonTopic from "./training/components/CreateCourseLessonTopic";
import NotificationsScreen from "./notifications/screens/NotificationsScreen";
import UserScreen from "./user/screens/UserScreen";
import ContactChatScreen from "./contacts/screens/ContactChatScreen";

import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import AntDesign from "react-native-vector-icons/AntDesign";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const ContactStack = (props) => {
  return (
    <Stack.Navigator initialRouteName="Contacts">
      <Stack.Screen
        name="Contacts"
        component={ContactsScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="ContactDetails"
        component={ContactDetailsScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="AddContact"
        component={AddContactScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="EditContact"
        component={EditContactScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateContactNote"
        component={CreateContactNoteScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateContactFile"
        component={CreateContactFileScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="Chat"
        component={ContactChatScreen}
        options={{
          header: () => null,
        }}
      />
    </Stack.Navigator>
  );
};

const JobStack = (props) => {
  return (
    <Stack.Navigator initialRouteName="Jobs">
      <Stack.Screen
        name="Jobs"
        component={JobsScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="JobDetails"
        component={JobDetailsScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateJob"
        component={CreateJobScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="EditJob"
        component={EditJobScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateJobNote"
        component={CreateJobNoteScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateJobSubmission"
        component={CreateJobSubmissionScreen}
        options={{
          header: () => null,
        }}
      />
    </Stack.Navigator>
  );
};

const TrainingStack = (props) => {
  return (
    <Stack.Navigator initialRouteName="Courses">
      <Stack.Screen
        name="Courses"
        component={CoursesScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CourseDetails"
        component={CourseDetailScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateCourse"
        component={CreateCourseScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="EditCourse"
        component={EditCourseScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateCourseNote"
        component={CreateCourseNoteScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateCourseFile"
        component={CreateCourseFileScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CourseLesson"
        component={LessonsScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="EditLesson"
        component={EditLessonScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateLesson"
        component={CreateCourseLessonScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="CreateLessonTopic"
        component={CreateCourseLessonTopic}
        options={{
          header: () => null,
        }}
      />
    </Stack.Navigator>
  );
};

const NotificationStack = (props) => {
  return (
    <Stack.Navigator initialRouteName="Notifications">
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{
          header: () => null,
        }}
      />
    </Stack.Navigator>
  );
};

const UserStack = (props) => {
  return (
    <Stack.Navigator initialRouteName="User">
      <Stack.Screen
        name="User"
        component={UserScreen}
        options={{
          header: () => null,
        }}
      />
    </Stack.Navigator>
  );
};

const DashboardStack = (props) => {
  let [userGroups, setUserGroups] = useState([]);
  let [user, setUser] = useState({});

  const getUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setUserGroups(user.signInUserSession.accessToken.payload["cognito:groups"]);
    setUser(user);
  };

  useEffect(() => {
    getUser();
  }, [props]);

  return (
    <Drawer.Navigator
      initialRouteName={
        userGroups.indexOf("students") == -1 ? "Home" : "Training"
      }
      drawerContent={(props) => <EsuiteDrawer {...props} user={user} />}
      screenOptions={{
        gestureEnabled: false,
        swipeEnabled: false,
        drawerContentContainerStyle: {
          marginTop: 20,
        },
      }}
    >
      {userGroups.indexOf("students") == -1 ? (
        <>
          <Drawer.Screen
            name="Home"
            component={HomeScreen}
            options={{
              headerShown: false,
              gestureEnabled: false,
              drawerIcon: ({ focused, size }) => (
                <MaterialIcons name="dashboard" size={20} />
              ),
            }}
          />
          <Drawer.Screen
            name="Contacts"
            component={ContactStack}
            options={{
              headerShown: false,
              drawerIcon: ({ focused, size }) => (
                <MaterialIcons name="contacts" size={20} />
              ),
            }}
          />
          <Drawer.Screen
            name="Jobs"
            component={JobStack}
            options={{
              headerShown: false,
              gestureEnabled: false,
              drawerIcon: ({ focused, size }) => (
                <Image
                  source={require("../assets/images/jobs.png")}
                  style={{ width: 20, height: 20 }}
                />
              ),
            }}
          />
          <Drawer.Screen
            name="Training"
            component={TrainingStack}
            options={{ headerShown: false, gestureEnabled: false,
              drawerIcon: ({ focused, size }) => (
                <Image
                  source={require("../assets/images/training.png")}
                  style={{ width: 18, height: 18 }}
                />
              ), }}
          />
          <Drawer.Screen
            name="User"
            component={UserStack}
            options={{
              headerShown: false,
              gestureEnabled: false,
              drawerIcon: ({ focused, size }) => (
                <AntDesign name="user" size={20} />
              ),
            }}
          />
          <Drawer.Screen
            name="Notifications"
            component={NotificationStack}
            options={{
              headerShown: false,
              gestureEnabled: false,
              drawerIcon: ({ focused, size }) => (
                <MaterialIcons name="notifications-none" size={20} />
              ),
            }}
          />
        </>
      ) : (
        <>
          <Drawer.Screen
            name="Training"
            component={TrainingStack}
            options={{ headerShown: false, gestureEnabled: false }}
          />
        </>
      )}
    </Drawer.Navigator>
  );
};

export default DashboardStack;

const styles = StyleSheet.create({});
