import { StyleSheet, Text, View } from "react-native";
import React, { useState, useCallback, useEffect } from "react";
import { Card } from "react-native-paper";
import Header from "../../../global/components/Header";
import { Bubble, GiftedChat } from "react-native-gifted-chat";
import axios from "axios";
import moment from "moment";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { sendSMS, updateContact } from "../../../graphql/mutations";
import { getContact } from "../../../graphql/queries";
import { onUpdateContact } from "../../../graphql/subscriptions";

import awsconfig from "../../../aws-exports";
import { useSelector } from "react-redux";

API.configure(awsconfig);
Auth.configure(awsconfig);

const ContactChat = (props) => {
  // console.log(props.route.params.contact);
  const [messages, setMessages] = useState([]);
  const [contact, setContact] = useState(props?.route?.params?.contact);

  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);

  // console.log(contact.contactMessages, "contact.contactMessages[0],", messages);
  useEffect(() => {
    fetchContact();

    console.log('Registering Subscription')
    const subscription = API.graphql(
      {
        query: onUpdateContact,
        variables: {
          owner: user.userName,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }
    ).subscribe({
      next: ({ provider, value }) => {
        fetchContact();
      },
      error: (error) => console.warn(error)
    });
    console.log(subscription)

    // let subscription=subscribeForContact();

    // subscription()
    // .then((r)=>{
    //   console.log("Subscribed" + r)
    // })

    return () => {
      console.log('Unregistering Subscription')
      subscription.unsubscribe();
    };
  }, []);

  const fetchContact = async () => {
    await API.graphql({
      query: getContact,
      variables: {
        id: props ? props.route.params.contact.id : contact.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        // console.log(res.data.getContact.contactMessages, "getContact");
        setContact(res.data.getContact);
        // console.log(res.data.getContact);
        let _messages =
          res.data.getContact.contactMessages &&
          res.data.getContact.contactMessages.map((item, index) => {
            // console.log(item, "item");
            return {
              _id: index + 1,
              text: item.message.replace(/\+/g,' '),
              createdAt: item.createdAt,
              user: {
                _id: item.messageUserName == "Contact" ? 2 : 1,
                name: item.messageUserName,
              },
            };
          });
        // console.log(_messages, "_messages");
        _messages !== null && setMessages(_messages);
        // setMessages([_messages]);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  const onSend = async (message) => {
    let contactChat = {
      message: message[0].text.replace(/\+/g,' '),
      messageUserName: `You`,
      createdAt: moment(new Date()).format(),
    };
    // console.log(contactChat, "contactChat");
    await API.graphql({
      query: updateContact,
      variables: {
        input: {
          id: contact.id,
          contactMessages:
            contact.contactMessages !== null
              ? [...contact.contactMessages, contactChat]
              : [contactChat],
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        let lastMessage =
          res.data.updateContact.contactMessages[
            res.data.updateContact.contactMessages.length - 1
          ];

        // console.log(res.data.updateContact.contactMessages);

        let message = {
          _id: messages.length+1,
          text: lastMessage.message,
          createdAt: lastMessage.createdAt,
          user: {
            _id: 1,
            name: "You",
          },
        };

        let mes = [...messages];

        await API.graphql({
          query: sendSMS,
          variables: {
              contactId: contact.id,
              phone:contact.phone,
              message:contactChat.message
          }
        })
        .then(async (res) => {
          setMessages([...mes,message]);
        })
        
        
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };
  // console.log(messages, "messages");
  const renderBubble = (props) => {
    return (
      <Bubble
        key={props.index}
        {...props}
        wrapperStyle={{
          right: {
            paddingHorizontal: 10,
            paddingVertical: 5,
          },
          left: {
            paddingHorizontal: 10,
            paddingVertical: 5,
          },
        }}
      />
    );
  };

  const displayMessages = () => {
    let messagesList = [];

    let messagesOrder = messages.sort(function (a, b) {
      let timeA = new Date(a.createdAt);
      let timeB = new Date(b.createdAt);
      // console.log(timeA.getTime() - timeB.getTime(), "time", index);
      //   console.log(a.getTime());
      return timeB - timeA;
    });
    messagesList = messagesOrder;
    return messagesList;
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={`${contact.firstName} ${contact.lastName}`}
        showBack={true}
      />
      <GiftedChat
        messages={displayMessages()}
        onSend={(messages) => onSend(messages)}
        user={{
          _id: 1,
        }}
        isTyping={true}
        placeholder={"Type a message..."}
        alwaysShowSend={true}
        renderBubble={renderBubble}
      />
    </Card>
  );
};

export default ContactChat;

const styles = StyleSheet.create({});
