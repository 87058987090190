import React, { useState, useEffect } from "react";

import { useFocusEffect } from '@react-navigation/native';

import {
    View,
    StyleSheet,
    FlatList,
    Platform,
    SafeAreaView,
    ScrollView,
    Image,
    ActivityIndicator,
    TouchableOpacity,
    Alert,
} from "react-native";
import {
    Paragraph,
    Card,
    Text,
    Surface,
    FAB
} from "react-native-paper";
import { listSubmissions } from "../../../graphql/queries";
import SubmissionItem from "./SubmissionItem";

import Amplify, { Auth, graphqlOperation, API, DataStore, Predicates } from "aws-amplify";
import awsconfig from "../../../aws-exports";

API.configure(awsconfig);

const JobSubmissions = (props) => {

    const [loading, setLoading] = useState(false);
    const [job, setJob] = useState({});
    const [submissions, setSubmissions] = useState([]);

    const fetchSubmissions = async () => {
            await API.graphql({
                query: listSubmissions,
                variables: {
                    filter: {
                        submissionJobId: {
                            eq: job.id
                        }
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            .then(async (res) => {
                setSubmissions(res.data.listSubmissions.items);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err, "errr");
            });
    };

    useFocusEffect(
        React.useCallback(() => {
            let isActive = true;
            fetchSubmissions();
            return () => {
                isActive = false;
            };
        }, [])
    );

    useEffect(() => {
        setJob(props.job)
    }, [props])

    const renderItem = ({ item }) => {
        return (
            <SubmissionItem {...props} item={item}/>
        );
    };

    return (
        <Card style={{ flex: 1 }}>
            <Card.Content>
                <FlatList data={submissions} renderItem={renderItem} />
            </Card.Content>
            <FAB
                icon="plus"
                style={styles.fab}
                onPress={() => props.navigation.navigate("CreateJobSubmission", { job })}
            />
        </Card>
    )
}

export default JobSubmissions

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
    },
    fab: {
        position: 'absolute',
        margin: 16,
        right: 0,
        bottom: 0,
        backgroundColor: 'blue'
    },
})