 import React,{useState,useEffect} from 'react'
 import { View, Text, StyleSheet } from 'react-native'
 import Login from '../components/Login'

 export function LoginScreen(props) {
    return (
        <Login {...props}/>
    );
  }

  
  export default LoginScreen;