import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import { Card, Surface } from "react-native-paper";
import Header from "../../../global/components/Header";
import { useFocusEffect } from "@react-navigation/native";

import {
  Tabs,
  TabScreen,
  useTabIndex,
  useTabNavigation,
} from "react-native-paper-tabs";

import JobFullData from "./JobFullData";
import JobNotes from "./JobNotes";
import JobSubmissions from "./JobSubmissions";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";

import awsconfig from "../../../aws-exports";

import { getJob } from "../../../graphql/queries";

API.configure(awsconfig);

const JobDetails = (props) => {
  const [job, setJob] = useState(props.route.params.item);
  console.log(props.route.params.item);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchJob();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const fetchJob = async () => {
    await API.graphql({
      query: getJob,
      variables: {
        id: props ? props.route.params.item.id : job.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        // console.log(res.data.getJob, "getContact");
        setJob(res.data.getJob);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={`${job.title}`}
        showBack={true}
      />
      <Card.Content style={{ flex: 1 }}>
        <Tabs
          iconPosition={"top"} // leading, top | default=leading
          style={{ backgroundColor: "#fff" }} // works the same as AppBar in react-native-paper
        >
          <TabScreen label="Details" icon="details">
            <JobFullData data={job} {...props} />
          </TabScreen>
          <TabScreen label="Submissions" icon="account-multiple-plus">
            <JobSubmissions job={job} {...props} />
          </TabScreen>
          <TabScreen label="Notes" icon="notebook">
            <JobNotes job={job} {...props} />
          </TabScreen>
        </Tabs>
      </Card.Content>
    </Card>
  );
};

export default JobDetails;

const styles = StyleSheet.create({
  detailcardText: {
    fontSize: 16,
    fontWeight: "bold",
    width: "50%",
    textAlign: "left",
  },
  detailcardText1: {
    fontSize: 14,
    marginVertical: 2,
    textAlign: "left",
    width: "50%",
  },
  DetailsCard: {
    marginTop: 5,
    paddingHorizontal: 0,
    elevation: 2,
  },
});
