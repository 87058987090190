import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import { Card, Surface, List, FAB } from "react-native-paper";

import { useFocusEffect } from "@react-navigation/native";

import Amplify, { API } from "aws-amplify";
import awsconfig from "../../../aws-exports";

import { getCourse } from "../../../graphql/queries";

API.configure(awsconfig);

const CourseFullData = (props) => {
  console.log(props.data, "props.data)");
  const [loading, setLoading] = useState(false);

  let [course, setCourse] = useState(props.data);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchCourse();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const fetchCourse = async () => {
    await API.graphql({
      query: getCourse,
      variables: {
        id: props ? props.data.id : course.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        // console.log(res.data.getCourse);
        setCourse(res.data.getCourse);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  useEffect(() => {
    setCourse(props.course);
  }, [props]);
  return (
    <Card
      style={{
        flex: 1,
      }}
    >
      {!loading ? 
        course && (
        <Surface
          style={[
            styles.details,
            {
              backgroundColor: "white",
              paddingVertical: 5,
              marginHorizontal: 5,
              marginBottom: 5,
            },
          ]}
        >
          <ScrollView style={{}}>
            <Card.Content style={{ flex: 1, paddingHorizontal: 0 }}>
              <List.Item title={"Title"} description={course.title} />
              <List.Item
                title={"Description"}
                description={course.description}
              />
            </Card.Content>
            <FAB
              icon="pencil"
              style={styles.fab}
              onPress={() =>
                props.navigation.navigate("EditCourse", { course })
              }
            />
          </ScrollView>
        </Surface>
      ) : (
        // )
        <ActivityIndicator
          size="large"
          color="#390485"
          style={styles.loading}
        />
      )}
    </Card>
  );
};

export default CourseFullData;

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  details: {
    flex: 1,
    marginTop: 5,
    paddingHorizontal: 0,
    elevation: 2,
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
});
