import React, { useState } from 'react';
import { Text, View, StyleSheet, Image } from 'react-native';
import { Card, Title, Paragraph, Avatar, IconButton } from 'react-native-paper';

import Amplify, { Storage } from "aws-amplify";

import awsconfig from "../../aws-exports";

Storage.configure(awsconfig)

const styles = StyleSheet.create({
    card: {
        marginTop: 20,
    },
    title: {
        top: 20,
        left: 100,
        fontSize: 24,
    },
    image: {
        height: 100,
    },
    action: {
        backgroundColor: 'black',
        color: 'white',
        paddingBottom: 5,
        paddingTop: 5,
    },
    icon: {
        position: 'absolute',
        top: 10,
        left: 10,
        color: 'white',
        backgroundColor: 'rgba(255,255,255,0)',
    },
    itemContainer: {
        margin: 10
    },
    itemName: {
        fontSize: 16,
        margin: 2
    }
});


const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

let download = async (fileKey) => {
    const result = await Storage.get(fileKey, { download: true });
    downloadBlob(result.Body, fileKey);
}

const FileItem = (props) => {
    let _that = props;
    return (
        <View style={styles.itemContainer}>
            <Card>
                <Card.Title
                    title={`${props.item.filePurpose}`}
                    subtitle={props.item.fileName}
                    left={(props) => <Avatar.Text {...props} color={'white'} size={48} label={"F"} />}
                    right={(props) => <IconButton {...props} icon="download" onPress={() => {
                        download(_that.item.fileName);
                    }} />}
                />
            </Card>
        </View>
    )
}

export default FileItem;