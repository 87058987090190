import React, { useState, useEffect, useLayoutEffect } from "react";

import { View, StyleSheet, ScrollView } from "react-native";

import {
  Button,
  IconButton,
  Paragraph,
  Card,
  Text,
  Surface,
  Title,
  TextInput,
  HelperText,
} from "react-native-paper";

import { useToast } from "react-native-toast-notifications";

import Header from "../../../global/components/Header";
import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { createCourse } from "../../../graphql/mutations";

import awsconfig from "../../../aws-exports";

import { useSelector } from "react-redux";

API.configure(awsconfig);
Auth.configure(awsconfig);

const CreateCourse = (props) => {
  const [course, setCourse] = useState({});

  const toast = useToast();

  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
    },
    mode: "onChange",
  });

  const onSave = async (data) => {
    let courseForm = {};
    courseForm = {
      title: data.title,
      description: data.description,
    };

    console.log(courseForm);

    await API.graphql({
      query: createCourse,
      variables: { input: courseForm },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        console.log(res, "res");

        toast.show("New course has been added successfully.", {
          type: "success",
          placement: "top",
          duration: 4000,
          offset: 30,
          animationType: "zoom-in",
        });

        props.navigation.goBack();
      })
      .catch((err) => {
        console.log(err, "errr");
        toast.show("Failed to save new course.", {
          type: "danger",
          placement: "top",
          duration: 4000,
          offset: 30,
          animationType: "zoom-in",
        });
      });
  };

  return (
    <Card style={styles.container}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"New Course"}
        showBack={true}
        leftType={"name"}
        leftName={"Save"}
        onPress={handleSubmit((data) => {
          console.log("form data", data);
          setCourse(data);
          onSave(data);
        })}
      />
      <ScrollView contentContainerStyle={styles.scrollViewStyle}>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              type: "text",
              name: "title",
              rules: {
                required: {
                  value: true,
                  message: "Title is required",
                },
                minLength: {
                  value: 3,
                  message: "title should be atleast 3 characters",
                },
              },
              textInputProps: {
                label: "Title",
                placeholder: "Please Enter Title",
              },
            },
          ]}
        />
        <Text>{"Description"}</Text>
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              multiline={true}
              placeholder="Enter Description"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              style={{ height: 100 }}
              outlineColor={errors.description && "#fc4c4c"}
              activeOutlineColor={errors.description && "#fc4c4c"}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "description is required",
            },
          }}
        />
      </ScrollView>
    </Card>
  );
};

export default CreateCourse;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  helpertext: {
    fontSize: 12,
    paddingHorizontal: 0,
  },
  scrollViewStyle: {
    padding: 15,
    justifyContent: "center",
  },
});
