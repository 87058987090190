import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-native-paper'
import Header from '../../../global/components/Header'

import {
  Title,
  Paragraph,
} from 'react-native-paper';

import {
  Tabs,
  TabScreen,
  useTabIndex,
  useTabNavigation,
} from 'react-native-paper-tabs';

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";

import awsconfig from "../../../aws-exports";
API.configure(awsconfig);

import CourseFullData from './CourseFullData'
import CourseNotes from './CourseNotes';
import CourseFiles from './CourseFiles'
import Lessons from './Lessons';


const CourseDetails = (props) => {

  const [course, setCourse] = useState(props.route.params.item);

  useEffect(() => {
    console.log(props.route.params.item)
    setCourse(props.route.params.item)
  }, [props])

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={course?.title}
        showBack={true}
      />
      <Card.Content style={{ flex: 1, backgroundColor: 'transparent' }}>
        <Tabs
          iconPosition={"top"} // leading, top | default=leading
          style={{ backgroundColor: '#fff' }} // works the same as AppBar in react-native-paper
        >
          <TabScreen label="Details" icon="details" >
            <CourseFullData data={course} {...props} />
          </TabScreen>
          <TabScreen label="Lessons" icon="book" >
            <Lessons data={course.courseLessons} course={course} {...props} />
          </TabScreen>
          <TabScreen label="Notes" icon="notebook" >
            <CourseNotes data={course.courseNotes} course={course} {...props} />
          </TabScreen>
          <TabScreen
            label="Docs"
            icon="file-document">
            <CourseFiles data={course.courseFiles} course={course} {...props} />
          </TabScreen>
        </Tabs>
      </Card.Content>
    </Card>
  )
}

export default CourseDetails

const styles = StyleSheet.create({})