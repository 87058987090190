import { StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";

import AccountStack from "./account/AccountStack";
import DashboardStack from "./dashboard/DashboardStack";

import { useDispatch } from "react-redux";
import { setView } from "./redux_esuite";
import OnboardingStack from "./onboarding/OnboardingStack";

const Stack = createStackNavigator();

const RootStack = (props) => {
  console.log(props)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setView(props.view));
  }, [props]);

  useEffect(() => {
   
    if (props.view === "recruiter") {
      props.navigation.navigate("OnboardingStack", { view:"recruiter" });
    }
    if (props.view === "student") {
      props.navigation.navigate("OnboardingStack", { view:"student" });
    }
    if (props.view === "consultant") {
      props.navigation.navigate("OnboardingStack", { view:"consultant" });
    }
  }, []);

  return (
    <Stack.Navigator initialRouteName="AccountStack">
      <Stack.Screen name="AccountStack" options={{ header: () => null }}>
        {(props) => <AccountStack {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name="DashboardStack"
        component={DashboardStack}
        options={({ route, navigation }) => ({
          header: () => null,
        })}
      />
      <Stack.Screen
        name="OnboardingStack"
        component={OnboardingStack}
        options={({ route, navigation }) => ({
          header: () => null,
        })}
      />
    </Stack.Navigator>
  );
};

export default RootStack;

const styles = StyleSheet.create({});
