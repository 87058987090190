/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendSMS = /* GraphQL */ `
  mutation SendSMS($contactId: String, $phone: String, $message: String) {
    sendSMS(contactId: $contactId, phone: $phone, message: $message) {
      status
      response
    }
  }
`;
export const createContactRelation = /* GraphQL */ `
  mutation CreateContactRelation(
    $input: CreateContactRelationInput!
    $condition: ModelContactRelationConditionInput
  ) {
    createContactRelation(input: $input, condition: $condition) {
      id
      relation
      relationContactID
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const updateContactRelation = /* GraphQL */ `
  mutation UpdateContactRelation(
    $input: UpdateContactRelationInput!
    $condition: ModelContactRelationConditionInput
  ) {
    updateContactRelation(input: $input, condition: $condition) {
      id
      relation
      relationContactID
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactRelation = /* GraphQL */ `
  mutation DeleteContactRelation(
    $input: DeleteContactRelationInput!
    $condition: ModelContactRelationConditionInput
  ) {
    deleteContactRelation(input: $input, condition: $condition) {
      id
      relation
      relationContactID
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      name
      phone
      email
      extraData
      leadType
      leadSource
      verifiedEmail
      verifiedPhone
      unsubscribed
      assignedContact
      createdAt
      updatedAt
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      name
      phone
      email
      extraData
      leadType
      leadSource
      verifiedEmail
      verifiedPhone
      unsubscribed
      assignedContact
      createdAt
      updatedAt
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      name
      phone
      email
      extraData
      leadType
      leadSource
      verifiedEmail
      verifiedPhone
      unsubscribed
      assignedContact
      createdAt
      updatedAt
    }
  }
`;
export const createContactCount = /* GraphQL */ `
  mutation CreateContactCount(
    $input: CreateContactCountInput!
    $condition: ModelContactCountConditionInput
  ) {
    createContactCount(input: $input, condition: $condition) {
      id
      consultantCount
      submissionCount
      countDate
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const updateContactCount = /* GraphQL */ `
  mutation UpdateContactCount(
    $input: UpdateContactCountInput!
    $condition: ModelContactCountConditionInput
  ) {
    updateContactCount(input: $input, condition: $condition) {
      id
      consultantCount
      submissionCount
      countDate
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactCount = /* GraphQL */ `
  mutation DeleteContactCount(
    $input: DeleteContactCountInput!
    $condition: ModelContactCountConditionInput
  ) {
    deleteContactCount(input: $input, condition: $condition) {
      id
      consultantCount
      submissionCount
      countDate
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      notificationType
      entityType
      entityId
      entityName
      actorId
      actorName
      message
      notifiers
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      notificationType
      entityType
      entityId
      entityName
      actorId
      actorName
      message
      notifiers
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      notificationType
      entityType
      entityId
      entityName
      actorId
      actorName
      message
      notifiers
      status
      createdAt
      updatedAt
    }
  }
`;
export const createCourseLesson = /* GraphQL */ `
  mutation CreateCourseLesson(
    $input: CreateCourseLessonInput!
    $condition: ModelCourseLessonConditionInput
  ) {
    createCourseLesson(input: $input, condition: $condition) {
      id
      title
      description
      courseID
      topics {
        title
        description
        videoUrl
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseLesson = /* GraphQL */ `
  mutation UpdateCourseLesson(
    $input: UpdateCourseLessonInput!
    $condition: ModelCourseLessonConditionInput
  ) {
    updateCourseLesson(input: $input, condition: $condition) {
      id
      title
      description
      courseID
      topics {
        title
        description
        videoUrl
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseLesson = /* GraphQL */ `
  mutation DeleteCourseLesson(
    $input: DeleteCourseLessonInput!
    $condition: ModelCourseLessonConditionInput
  ) {
    deleteCourseLesson(input: $input, condition: $condition) {
      id
      title
      description
      courseID
      topics {
        title
        description
        videoUrl
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSubmission = /* GraphQL */ `
  mutation CreateSubmission(
    $input: CreateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    createSubmission(input: $input, condition: $condition) {
      id
      job {
        id
        title
        vendor {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        client
        primeVendor
        positionType
        location
        ecorfyRate
        description
        additionalNotes
        status
        jobNotes {
          message
          userName
          createdAt
        }
        createdAt
        updatedAt
        jobVendorId
      }
      consultant {
        id
        userId
        firstName
        lastName
        phone
        email
        contactType
        email2
        email3
        phone2
        phone3
        middleName
        title
        jobTitle
        dob
        linkedinUrl
        githubUrl
        contactFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        contactNotes {
          message
          userName
          createdAt
        }
        contactTasks {
          message
          taskType
          priority
          userId
          taskStatus
        }
        shippingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        timezone
        leadSource
        gender
        openToWork
        primarySkill
        secondarySkill
        skillTags
        experience
        relocation
        whatsappId
        ssn
        employerName
        employerEmail
        employerPhone
        visaType
        contractType
        managerId
        consultantCount
        submissionCount
        deviceToken
        deviceOS
        university
        degree
        yearJoined
        seasonJoined
        interest
        city
        state
        contactCounts {
          nextToken
        }
        contactMessages {
          messageUserName
          message
          createdAt
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      status
      recruiter {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      manager {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      consultantRate
      updatedAt
      submissionJobId
      submissionConsultantId
      submissionRecruiterId
      submissionManagerId
    }
  }
`;
export const updateSubmission = /* GraphQL */ `
  mutation UpdateSubmission(
    $input: UpdateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    updateSubmission(input: $input, condition: $condition) {
      id
      job {
        id
        title
        vendor {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        client
        primeVendor
        positionType
        location
        ecorfyRate
        description
        additionalNotes
        status
        jobNotes {
          message
          userName
          createdAt
        }
        createdAt
        updatedAt
        jobVendorId
      }
      consultant {
        id
        userId
        firstName
        lastName
        phone
        email
        contactType
        email2
        email3
        phone2
        phone3
        middleName
        title
        jobTitle
        dob
        linkedinUrl
        githubUrl
        contactFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        contactNotes {
          message
          userName
          createdAt
        }
        contactTasks {
          message
          taskType
          priority
          userId
          taskStatus
        }
        shippingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        timezone
        leadSource
        gender
        openToWork
        primarySkill
        secondarySkill
        skillTags
        experience
        relocation
        whatsappId
        ssn
        employerName
        employerEmail
        employerPhone
        visaType
        contractType
        managerId
        consultantCount
        submissionCount
        deviceToken
        deviceOS
        university
        degree
        yearJoined
        seasonJoined
        interest
        city
        state
        contactCounts {
          nextToken
        }
        contactMessages {
          messageUserName
          message
          createdAt
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      status
      recruiter {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      manager {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      consultantRate
      updatedAt
      submissionJobId
      submissionConsultantId
      submissionRecruiterId
      submissionManagerId
    }
  }
`;
export const deleteSubmission = /* GraphQL */ `
  mutation DeleteSubmission(
    $input: DeleteSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    deleteSubmission(input: $input, condition: $condition) {
      id
      job {
        id
        title
        vendor {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        client
        primeVendor
        positionType
        location
        ecorfyRate
        description
        additionalNotes
        status
        jobNotes {
          message
          userName
          createdAt
        }
        createdAt
        updatedAt
        jobVendorId
      }
      consultant {
        id
        userId
        firstName
        lastName
        phone
        email
        contactType
        email2
        email3
        phone2
        phone3
        middleName
        title
        jobTitle
        dob
        linkedinUrl
        githubUrl
        contactFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        contactNotes {
          message
          userName
          createdAt
        }
        contactTasks {
          message
          taskType
          priority
          userId
          taskStatus
        }
        shippingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        timezone
        leadSource
        gender
        openToWork
        primarySkill
        secondarySkill
        skillTags
        experience
        relocation
        whatsappId
        ssn
        employerName
        employerEmail
        employerPhone
        visaType
        contractType
        managerId
        consultantCount
        submissionCount
        deviceToken
        deviceOS
        university
        degree
        yearJoined
        seasonJoined
        interest
        city
        state
        contactCounts {
          nextToken
        }
        contactMessages {
          messageUserName
          message
          createdAt
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      status
      recruiter {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      manager {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      consultantRate
      updatedAt
      submissionJobId
      submissionConsultantId
      submissionRecruiterId
      submissionManagerId
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      title
      description
      courseNotes {
        message
        userName
        createdAt
      }
      courseFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      courseLessons {
        items {
          id
          title
          description
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      title
      description
      courseNotes {
        message
        userName
        createdAt
      }
      courseFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      courseLessons {
        items {
          id
          title
          description
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      title
      description
      courseNotes {
        message
        userName
        createdAt
      }
      courseFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      courseLessons {
        items {
          id
          title
          description
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      title
      vendor {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      client
      primeVendor
      positionType
      location
      ecorfyRate
      description
      additionalNotes
      status
      jobNotes {
        message
        userName
        createdAt
      }
      createdAt
      updatedAt
      jobVendorId
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      title
      vendor {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      client
      primeVendor
      positionType
      location
      ecorfyRate
      description
      additionalNotes
      status
      jobNotes {
        message
        userName
        createdAt
      }
      createdAt
      updatedAt
      jobVendorId
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      title
      vendor {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      client
      primeVendor
      positionType
      location
      ecorfyRate
      description
      additionalNotes
      status
      jobNotes {
        message
        userName
        createdAt
      }
      createdAt
      updatedAt
      jobVendorId
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      userId
      firstName
      lastName
      phone
      email
      contactType
      email2
      email3
      phone2
      phone3
      middleName
      title
      jobTitle
      dob
      linkedinUrl
      githubUrl
      contactFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      contactNotes {
        message
        userName
        createdAt
      }
      contactTasks {
        message
        taskType
        priority
        userId
        taskStatus
      }
      shippingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      billingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      timezone
      leadSource
      gender
      openToWork
      primarySkill
      secondarySkill
      skillTags
      experience
      relocation
      whatsappId
      ssn
      employerName
      employerEmail
      employerPhone
      visaType
      contractType
      managerId
      consultantCount
      submissionCount
      deviceToken
      deviceOS
      university
      degree
      yearJoined
      seasonJoined
      interest
      city
      state
      contactCounts {
        items {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        nextToken
      }
      contactMessages {
        messageUserName
        message
        createdAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      userId
      firstName
      lastName
      phone
      email
      contactType
      email2
      email3
      phone2
      phone3
      middleName
      title
      jobTitle
      dob
      linkedinUrl
      githubUrl
      contactFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      contactNotes {
        message
        userName
        createdAt
      }
      contactTasks {
        message
        taskType
        priority
        userId
        taskStatus
      }
      shippingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      billingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      timezone
      leadSource
      gender
      openToWork
      primarySkill
      secondarySkill
      skillTags
      experience
      relocation
      whatsappId
      ssn
      employerName
      employerEmail
      employerPhone
      visaType
      contractType
      managerId
      consultantCount
      submissionCount
      deviceToken
      deviceOS
      university
      degree
      yearJoined
      seasonJoined
      interest
      city
      state
      contactCounts {
        items {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        nextToken
      }
      contactMessages {
        messageUserName
        message
        createdAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      userId
      firstName
      lastName
      phone
      email
      contactType
      email2
      email3
      phone2
      phone3
      middleName
      title
      jobTitle
      dob
      linkedinUrl
      githubUrl
      contactFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      contactNotes {
        message
        userName
        createdAt
      }
      contactTasks {
        message
        taskType
        priority
        userId
        taskStatus
      }
      shippingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      billingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      timezone
      leadSource
      gender
      openToWork
      primarySkill
      secondarySkill
      skillTags
      experience
      relocation
      whatsappId
      ssn
      employerName
      employerEmail
      employerPhone
      visaType
      contractType
      managerId
      consultantCount
      submissionCount
      deviceToken
      deviceOS
      university
      degree
      yearJoined
      seasonJoined
      interest
      city
      state
      contactCounts {
        items {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        nextToken
      }
      contactMessages {
        messageUserName
        message
        createdAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
