import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CreateJobSubmission from '../components/CreateJobSubmission';

const CreateJobSubmissionScreen = (props) => {
    return  <CreateJobSubmission {...props} />
}

export default CreateJobSubmissionScreen

const styles = StyleSheet.create({})