import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CreateContactFile from '../components/CreateContactFile'

const CreateContactFileScreen = (props) => {
    return <CreateContactFile {...props} />
}

export default CreateContactFileScreen

const styles = StyleSheet.create({})