import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  FlatList,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";

import {
  Searchbar,
  IconButton,
  Paragraph,
  Card,
  Surface,
  FAB,
  ActivityIndicator,
} from "react-native-paper";

import Header from "../../../global/components/Header";
import { useFocusEffect } from "@react-navigation/native";

import { DataStore } from "@aws-amplify/datastore";
import { Course } from "../../../models";
import Amplify, { Auth, graphqlOperation, API, Predicates } from "aws-amplify";

import awsconfig from "../../../aws-exports";
import CourseItem from "./CourseItem";
import { listCourses } from "../../../graphql/queries";

import { useSelector } from 'react-redux'

DataStore.configure(awsconfig);

const Courses = (props) => {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);


  useFocusEffect(
    React.useCallback(() => {
      console.log("ok");
      let isActive = true;
      fetchCourses();
      return () => {
        isActive = false;
      };
    }, [])
  );

  useEffect(() => {
    fetchCourses();
  }, [props]);

  const fetchCourses = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();

    let userGroups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];

    if (userGroups.indexOf("managers") != -1) {
      await API.graphql({
        query: listCourses,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then((res) => {
          setCourses(res.data.listCourses.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    } else {
      await API.graphql({
        query: listCourses,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setCourses(res.data.listCourses.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    }
  };
  const onItemPressed = (item) => {
    console.log(item);
    props.navigation.navigate("CourseDetails", { item });
  };

  const renderItem = ({ item }) => {
    // console.log(item, "item");
    return <CourseItem item={item} onItemPressed={onItemPressed} />;
  };
  console.log(courses, "courses");
  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"Training"}
      />
      {!loading ? (
        <FlatList data={courses} renderItem={renderItem} />
      ) : (
        <ActivityIndicator
          size="large"
          color="#390485"
          style={styles.loading}
        />
      )}

      {
        (user.group == "managers") &&
        (
          <FAB
            icon="plus"
            style={styles.fab}
            onPress={() => props.navigation.navigate("CreateCourse", { courses })}
          />
        )
      }

    </Card>
  );
};

export default Courses;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  addnewuserbtn: {
    color: "#fff",
    height: 40,
    width: Platform.OS == "web" ? 150 : "60%",
    justifyContent: "center",
    alignItems: Platform.OS == "web" ? "center" : "stretch",
    marginTop: 10,
    borderRadius: 10,
  },
  flatListView: {
    width: "100%",
    marginTop: 20,
    borderBottomColor: "red",
    borderBottomWidth: 2,
    padding: 5,
    backgroundColor: "#ccc",
    flexDirection: "row",
    alignItems: "center",
  },
  actions: {
    color: "blue",
    width: 20,
    height: 20,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  flatListText: {
    flex: 1,
  },
  flatListButton: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    width: "75%",
    textTransform: "capitalize",
    paddingTop: 10,
  },
  discription: {
    fontSize: 14,
    width: "90%",
    paddingBottom: 10,
  },
  time: {
    fontSize: 16,
  },
  titlePosition: {
    flexDirection: "column",
    width: "70%",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
});
