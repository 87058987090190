import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  ImageBackground,
  Image,
  TouchableOpacity,
} from "react-native";
import {
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";

import Ionicons from "react-native-vector-icons/Ionicons";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
// import * as Font from "expo-font";
import { useFonts } from "expo-font";

import { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";

import { useSelector } from "react-redux";
import { store } from "../../redux_esuite";

Auth.configure(awsconfig);

const EsuiteDrawer = (props) => {
  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);
  const [fontLoaded] = useFonts({
    "Roboto-Medium": require("../../../assets/fonts/Roboto-Medium.ttf"),
  });

  useEffect(() => {
    var state = store.getState();
    setUser(state.login.user);
  }, []);

  const { state, ...rest } = props;
  const studentsState = { ...state };
  studentsState.routes = studentsState.routes.filter(
    (item) => item.name === "Training"
  );
  if (!fontLoaded) {
    return null;
  }
  // console.log(user, "user");
  return (
    <View style={{ flex: 1 }}>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ backgroundColor: "#8200d6" }}
      >
        <ImageBackground
          source={require("../../assets/images/menu-bg.jpeg")}
          style={{ padding: 20 }}
        >
          <Image
            source={require("../../assets/images/user-profile.jpg")}
            style={{
              height: 80,
              width: 80,
              borderRadius: 40,
              marginBottom: 10,
            }}
          />
          <Text
            style={{
              color: "#fff",
              fontSize: 18,
              fontFamily: "Roboto-Medium",
              marginBottom: 5,
            }}
          >
            {user && user.firstName
              ? user.firstName + " " + user.lastName
              : user.email}
          </Text>
          {/* <View style={{flexDirection: 'row'}}>
            <Text
              style={{
                color: '#fff',
                fontFamily: 'Roboto-Regular',
                marginRight: 5,
              }}>
              0 Contacts
            </Text>
            <FontAwesome5 name="coins" size={14} color="#fff" />
          </View> */}
        </ImageBackground>
        <View style={{ flex: 1, backgroundColor: "#fff", paddingTop: 10 }}>
          {user.group == "students" ? (
            <DrawerItemList state={studentsState} {...rest} />
          ) : (
            <DrawerItemList {...props} />
          )}
        </View>
      </DrawerContentScrollView>
      <View style={{ padding: 20, borderTopWidth: 1, borderTopColor: "#ccc" }}>
        {/* <TouchableOpacity onPress={() => {}} style={{paddingVertical: 15}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Ionicons name="share-social-outline" size={22} />
            <Text
              style={{
                fontSize: 15,
                fontFamily: 'Roboto-Medium',
                marginLeft: 5,
              }}>
              Tell a Friend
            </Text>
          </View>
        </TouchableOpacity> */}
        <TouchableOpacity
          onPress={() => {
            Auth.signOut().then(() => {
              props.navigation.navigate("Login");
            });
          }}
          style={{ paddingVertical: 15 }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Ionicons name="exit-outline" size={22} />
            <Text
              style={{
                fontSize: 15,
                fontFamily: "Roboto-Medium",
                marginLeft: 5,
              }}
            >
              Sign Out
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default EsuiteDrawer;
