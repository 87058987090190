import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import RecruiterOnboardingScreen from "./recruiter/screens/RecruiterOnboardingScreen";
import StudentOnboardingScreen from "./student/screens/StudentOnboardingScreen";
import ConsultantOnboardingScreen from "./consultant/screens/ConsultantOnboardingScreen";

const Stack = createStackNavigator();

const OnboardingStack = (props) => {
  // console.log(props.route.params.view);
  let view = props?.route?.params?.view;
  return (
    <Stack.Navigator initialRouteName={view}>
      <Stack.Screen
        name="recruiter"
        component={RecruiterOnboardingScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="student"
        component={StudentOnboardingScreen}
        options={{
          header: () => null,
        }}
      />
      <Stack.Screen
        name="consultant"
        component={ConsultantOnboardingScreen}
        options={{
          header: () => null,
        }}
      />
    </Stack.Navigator>
  );
};

export default OnboardingStack;

const styles = StyleSheet.create({});
