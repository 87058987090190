import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Alert, Dimensions
} from "react-native";
import {
  DataTable,
  Searchbar,
  Button,
  IconButton,
  Portal,
  Paragraph,
  Dialog,
  TouchableRipple,
  Avatar,
  Card,
  Text,
  Surface,
  FAB
} from "react-native-paper";
import Header from "../../../global/components/Header";
import { listJobs } from "../../../graphql/queries";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import awsconfig from "../../../aws-exports";

import { useFocusEffect } from '@react-navigation/native';

import { useSelector } from 'react-redux'
import JobItem from "./JobItem";

API.configure(awsconfig);

const Jobs = (props) => {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([])

  const loggedinUser = useSelector((state) => state.login.user);
  let [user,setUser]=useState(loggedinUser);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchJobs();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const searchJobs = async (keyword) => {
      await API.graphql({
        query: listJobs,
        variables: {
          filter: {
            or:[
              {title: {
                contains: keyword
              }},
              {description: {
                contains: keyword
              }},
              {client: {
                contains: keyword
              }},
              {primeVendor: {
                contains: keyword
              }}
            ],
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
        .then(async (res) => {
          setJobs(res.data.listJobs.items);
          setLoading(false)
        })
        .catch((err) => {
          console.log(err, "errr");
        });
  };

  const fetchJobs = async () => {
    await API.graphql({
      query: listJobs,
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
      .then(async (res) => {
        setJobs(res.data.listJobs.items);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  const onJobItemPressed=(item)=>{
    props.navigation.navigate("JobDetails", { item })
  }

  const renderItem = ({ item }) => {
    return (
      <JobItem item={item} onJobItemPressed={onJobItemPressed}/>
    );
  };


  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"Jobs"}
      // leftIcon={"plus"}
      // routename={"CreateJob"}
      // leftType={"icon"}
      />
      <Card.Content style={{ paddingHorizontal: 0 }}>
        <Searchbar
          style={{
            height: 50,
            marginBottom: 19,
            borderColor: "black",
          }}
          placeholder="Search..."
          // value={search}
          onChangeText={(text) => searchJobs(text)}
        />
        <FlatList
          data={jobs}
          renderItem={renderItem}
        />
      </Card.Content>
      {
        (user.group=="managers") &&
        (
          <FAB
            icon="plus"
            style={styles.fab}
            onPress={() => props.navigation.navigate("CreateJob")}
          />
        )
      }
    </Card>
  );
};

export default Jobs;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  addnewuserbtn: {
    color: "#fff",
    height: 40,
    width: Platform.OS == "web" ? 150 : "60%",
    justifyContent: "center",
    alignItems: Platform.OS == "web" ? "center" : "stretch",
    marginTop: 10,
    borderRadius: 10,
  },
  flatListView: {
    width: "100%",
    marginTop: 20,
    borderBottomColor: "red",
    borderBottomWidth: 2,
    padding: 5,
    backgroundColor: "#ccc",
    flexDirection: "row",
    alignItems: "center",
  },
  actions: {
    color: "blue",
    width: 20,
    height: 20,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  flatListText: {
    flex: 1,
  },
  flatListButton: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    width: "75%",
    textTransform: "capitalize",
    paddingTop: 10,
  },
  discription: {
    fontSize: 14,
    width: "90%",
    paddingBottom: 10,
  },
  time: {
    fontSize: 16,
  },
  titlePosition: {
    flexDirection: "column",
    width: "70%",
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: 'blue'
  },
})
