import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  Button,
  IconButton,
  Paragraph,
  Surface,
  Title,
  TextInput,
  HelperText,
  Menu,
  Divider,
} from "react-native-paper";
import Header from "../../../global/components/Header";
import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";
import * as DocumentPicker from "expo-document-picker";
import { useToast } from "react-native-toast-notifications";

import Amplify, { Auth, graphqlOperation, API, Storage } from "aws-amplify";
import { updateCourseLesson } from "../../../graphql/mutations";

import awsconfig from "../../../aws-exports";


import { useSelector } from "react-redux";

import { addNotification } from "../../../../notifications";

API.configure(awsconfig);

const CreateCourseLessonTopic = (props) => {
  // console.log(props.route.params.item, props.route.params.course, ".......");
  const toast = useToast();
  let [course, setCourse] = useState(props.course);
  let [lesson, setLesson] = useState(props.lesson);

  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);

  useEffect(async () => {
    setCourse(props.route.params.course);
    setLesson(props.route.params.lesson);
  }, [props]);

  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      videoUrl:  "",
    },
    mode: "onChange",
  });

  const onSave = async (data) => {
    try {
      
      let topic = {
        title: data.title,
        description: data.description,
        videoUrl: data.videoUrl,
      };

      console.log("course" + JSON.stringify(course));
      console.log("lesson" + JSON.stringify(lesson));
      console.log("topic" + JSON.stringify(topic));

      await API.graphql({
        query: updateCourseLesson,
        variables: {
          input: {
            id: lesson.id,
            topics: (lesson.topics)?[...lesson.topics, topic]:[topic],
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          console.log(res, "res");

          addNotification({
            notificationType: 'New Topic',
            entityType: 'TOPIC',
            entityId: course.id,
            entityName: data.title,
            actorId: user.username,
            actorName: user.firstName,
            message: `${user.firstName} added new topic ${data.title} for lesson ${lesson.title} of course ${course.title}`
          })

          toast.show("Your Data has been saved successfully.", {
            type: "success",
            placement: "top",
            duration: 4000,
            offset: 30,
            animationType: "zoom-in",
          });
          props.navigation.goBack();
        })
        .catch((err) => {
          console.log("Error saving topic", err);
        });
    } catch (error) {
      console.log("Error saving topic", error);
      toast.show("Error saving your Data. Please try again", {
        type: "success",
        placement: "top",
        duration: 4000,
        offset: 30,
        animationType: "zoom-in",
      });
      // props.navigation.goBack();
    }
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"New Topic"}
        showBack={true}
        leftType={"name"}
        leftName={"Save"}
        onPress={handleSubmit((data) => {
          console.log(data, "...data");
          onSave(data);
        })}
      />
      <Card.Content style={{ marginTop: 30 }}>
        <Text>{"Title"}</Text>
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              multiline={false}
              placeholder="Enter Title"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              outlineColor={errors.note && "#fc4c4c"}
              activeOutlineColor={errors.note && "#fc4c4c"}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Title is required",
            },
          }}
        />
        <Text>{"Description"}</Text>
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              multiline={true}
              placeholder="Enter Description"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              style={{ height: 100 }}
              outlineColor={errors.note && "#fc4c4c"}
              activeOutlineColor={errors.note && "#fc4c4c"}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Description is required",
            },
          }}
        />
        <Text>{"Vadoo Url"}</Text>
        <Controller
          control={control}
          name="videoUrl"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              multiline={false}
              placeholder="Enter Video Url"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              outlineColor={errors.note && "#fc4c4c"}
              activeOutlineColor={errors.note && "#fc4c4c"}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Video Url is required",
            },
          }}
        />
      </Card.Content>
    </Card>
  );
};

export default CreateCourseLessonTopic;

const styles = StyleSheet.create({});
