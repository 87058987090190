import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import ContactChat from '../components/ContactChat'

const ContactChatScreen = (props) => {
  return (
   <ContactChat {...props} />
  )
}

export default ContactChatScreen

const styles = StyleSheet.create({})