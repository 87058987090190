import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  FlatList,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
  Alert,
} from "react-native";
import { Card, Text, Surface, FAB, List } from "react-native-paper";

import { useFocusEffect } from "@react-navigation/native";
import { useSelector } from 'react-redux'

import Amplify, { API } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import FileItem from "../../../global/components/FileItem";


import { getCourse } from "../../../graphql/queries";

API.configure(awsconfig);

const CourseFiles = (props) => {
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({});
  const [courseFiles, setCourseFiles] = useState([]);

  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchCourse();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const fetchCourse = async () => {
    await API.graphql({
      query: getCourse,
      variables: {
        id: props ? props.course.id : course.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        console.log(res.data.getCourse);
        setCourse(res.data.getCourse);
        setCourseFiles(res.data.getCourse.courseFiles);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  useEffect(() => {
    setCourse(props.course);
    setCourseFiles(props.course.courseFiles);
  }, [props]);

  const renderItem = ({ item }) => {
    return (
      <FileItem item={item} />
    );
  };

  return (
    <Card style={{ flex: 1 }}>
      <Card.Content>
        <FlatList
          data={courseFiles}
          renderItem={renderItem}
          // keyExtractor={(item) => item.id}
        />
      </Card.Content>

      {
        (user.group == "managers") &&
        (
          <FAB
            icon="plus"
            style={styles.fab}
            onPress={() =>
              props.navigation.navigate("CreateCourseFile", { course })
            }
          />
        )
      }
    </Card>
  );
};

export default CourseFiles;

const styles = StyleSheet.create({
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
});
