import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Jobs from '../components/Jobs'

const OpenJobsScreen = (props) => {
  return (
    <Jobs {...props} />
  )
}

export default OpenJobsScreen

const styles = StyleSheet.create({})