import React, { memo, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Alert,
  Platform,
  Dimensions,
  ImageBackground,
  ScrollView,
} from "react-native";
import * as Device from "expo-device";
import AsyncStorage from "@react-native-async-storage/async-storage";

import Background from "../../global/components/Background";
import Logo from "../../global/components/Logo";
import Header from "../../global/components/Header";
import Button from "../../global/components/Button";
import TextInput from "../../global/components/TextInput";

import { emailValidator, passwordValidator } from "../../../utils";

import { theme } from "../../../theme";
import * as Notifications from "expo-notifications";

import { store, setUser } from "../../redux_esuite";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  Title,
  Paragraph,
  Modal,
  Portal,
  ActivityIndicator,
} from "react-native-paper";
import { updateContact } from "../../graphql/mutations";

import { Auth, API, graphqlOperation } from "aws-amplify";
import config from "../../../src/aws-exports";

Auth.configure(config);
API.configure(config);

const { width } = Dimensions.get("window");

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

const containerStyle = {
  // backgroundColor: "transparent",
  // width: "10%",
  // height: "10%",
  alignSelf: "center",
};

const Login = (props) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });

  const [hidePassword, setHidePassword] = useState(true);
  let [loginStatus, setLoginStatus] = useState("");
  let [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  let logo = require("../../../assets/images/logo.png");

  const [expoPushToken, setExpoPushToken] = useState("");
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  const view = useSelector((state) => state.login.view);
  console.log(view);

  if (view === "recruiter") {
    props.navigation.navigate("OnboardingStack", { view:"recruiter" });
  }
  if (view === "student") {
    props.navigation.navigate("OnboardingStack", { view:"student" });
  }
  if (view === "consultant") {
    props.navigation.navigate("OnboardingStack", { view:"consultant" });
  }


  useEffect(() => {
    //sendPushNotification()

    let registerForPushNotificationsAsync = async () => {
      let token;
      if (Device.isDevice) {
        const { status: existingStatus } =
          await Notifications.getPermissionsAsync();
        // console.log( await Notifications.getPermissionsAsync());
        let finalStatus = existingStatus;
        console.log(existingStatus, "finalStatus");
        if (existingStatus !== "granted") {
          console.log("final");
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== "granted") {
          console.log("final");
          alert("Failed to get push token for push notification!");
          return;
        }
        token = (await Notifications.getExpoPushTokenAsync()).data;
        console.log(token, "....");
      } else {
        alert("Must use physical device for Push Notifications");
      }

      if (Platform.OS === "android") {
        Notifications.setNotificationChannelAsync("default", {
          name: "default",
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: "#FF231F7C",
        });
      }

      return token;
    };

    registerForPushNotificationsAsync()
      .then((res) => {
        console.log(res, "res");
        let startInd = res.indexOf("[");
        let endInd = res.indexOf("]");
        let tokenExt = res.substring(startInd + 1, endInd);
        //  console.log(tokenExt,"....a");
        setExpoPushToken(tokenExt);
        onUpdateToken(tokenExt);
      })
      .catch((err) => console.log(err, "error og notificcation"));

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification);
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log(response, "resppinfg");
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  useEffect(() => {
    props?.route?.params?.stopInterval();

    Auth.currentSession().then(async (session) => {
      // console.log(session)
      if (session) {
        let userGroups = session.accessToken.payload["cognito:groups"];

        dispatch(
          setUser({
            userName: session.accessToken.payload.username,
            group: userGroups ? userGroups[0] : "students",
            email: session.idToken.payload.email,
            firstName: session.idToken.payload.given_name,
            lastName: session.idToken.payload.family_name,
          })
        );

        props.navigation.navigate("DashboardStack");
      }
    });
  }, [props]);

  useEffect(() => {
    if (props.user) {
      onRedirect(props.user);
    }
  }, [props.user]);

  const getEmailData = async () => {
    let emailAddr = await AsyncStorage.getItem("@email");

    if (emailAddr) {
      setEmail({ ...email, value: emailAddr });
    }
  };

  useEffect(() => {
    getEmailData();
  }, []);

  console.log(notification, "notification");
  const onRedirect = (user) => {
    props.navigation.navigate("DashboardStack");
  };

  const sendPushNotification = async () => {
    // console.log("enter", "......");
    // const message = {
    //   // to: expoPushToken,
    //   sound: "default",
    //   title: "Original Title",
    //   body: "And here is the body!",
    //   data: { someData: "goes here" },
    //   trigger: { seconds: 2 },
    // };
    // await fetch("https://exp.host/--/api/v2/push/send", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Accept-encoding": "gzip, deflate",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(message),
    // });
  };

  const onLogin = async () => {
    setLoadingPage(true);
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);

    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }
    setLoading(true);

    await AsyncStorage.setItem("@email", email.value);

    Auth.signIn({
      username: email.value,
      password: password.value,
    })
      .then(async (user) => {
        console.log(user);

        let userGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];

        dispatch(
          setUser({
            userName: user.username,
            group: userGroups ? userGroups[0] : "students",
            email: email.value,
            firstName: user.signInUserSession.idToken.payload.given_name,
            lastName: user.signInUserSession.idToken.payload.family_name,
          })
        );

        onRedirect(user);
        setLoading(false);
        setLoadingPage(false);
        sendPushNotification();
      })
      .catch((err) => {
        setLoading(false);
        Alert.alert(err.message);
        setLoginStatus(err.message);
        setLoadingPage(false);
      });

    // props.navigation.navigate('DrawerStack')
  };

  const onUpdateToken = async (data) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    console.log(currentUser.username, "currentUser");
    console.log(expoPushToken, "....", data);
    let contactForm = {
      id: currentUser.username,
      deviceToken: expoPushToken,
      deviceOS: Platform.OS,
    };
    console.log(contactForm, "contactForm");
    await API.graphql({
      query: updateContact,
      variables: { input: contactForm },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        console.log(res, "res");
        // alert("New Details has been updated successfully. ");
        // props.navigation.goBack();
      })
      .catch((err) => {
        console.log(err, "errr");
        // toast.show("Failed to Edit contact Details.", {
        //   type: "danger",
        //   placement: "top",
        //   duration: 4000,
        //   offset: 30,
        //   animationType: "zoom-in",
        // });
      });
  };
  return (
    <Card.Content style={styles.container}>
      <ScrollView>
        <Background>
          <View style={{ marginTop: 40 }}>
            <Logo />
          </View>
          <View style={{ alignItems: "center" }}>
            {loading && (
              <Portal theme={{ colors: { backdrop: "rgba(0, 0, 0, 0.6)" } }}>
                <Modal
                  visible={loadingPage}
                  dismissable={false}
                  onDismiss={() => setLoadingPage(false)}
                  contentContainerStyle={containerStyle}
                >
                  <ActivityIndicator size={40} />
                </Modal>
              </Portal>
            )}
            <Text
              style={{
                color: "red",
                fontSize: Platform.OS == "web" ? 20 : 12,
                marginBottom: 10,
              }}
            >
              {loginStatus}
            </Text>
          </View>
          <Title>Login</Title>
          <TextInput
            label="Email"
            returnKeyType="next"
            value={email.value}
            onChangeText={(text) => setEmail({ value: text, error: "" })}
            error={!!email.error}
            errorText={email.error}
            autoCapitalize="none"
            autoCompleteType="email"
            textContentType="emailAddress"
            keyboardType="email-address"
          />

          <TextInput
            label="Password"
            returnKeyType="done"
            value={password.value}
            onChangeText={(text) => setPassword({ value: text, error: "" })}
            error={!!password.error}
            errorText={password.error}
            secureTextEntry
          />

          <Button mode="contained" onPress={onLogin}>
            Login
          </Button>

          <View style={styles.row}>
            <Text style={styles.label}>&copy; Copyright 2022, Ecorfy Inc</Text>
          </View>
        </Background>
      </ScrollView>
    </Card.Content>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  forgotPassword: {
    width: "100%",
    alignItems: "flex-end",
    marginBottom: 24,
  },
  row: {
    flexDirection: "row",
    marginTop: 4,
  },
  label: {
    color: theme.colors.secondary,
    marginTop: 20,
  },
  link: {
    fontWeight: "bold",
    color: theme.colors.primary,
  },
});

export default memo(Login);
