import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import User from '../components/User';

const UserScreen = (props) => {
  return (
    <User {...props} />
  )
}

export default UserScreen

const styles = StyleSheet.create({})