import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  Button,
  IconButton,
  Paragraph,
  Surface,
  Title,
  TextInput,
  HelperText,
  Menu,
  Divider,
} from "react-native-paper";
import Header from "../../../global/components/Header";
import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import { v4 as uuidv4 } from "uuid";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { createCourseLesson } from "../../../graphql/mutations";

import awsconfig from "../../../aws-exports";

import { useSelector } from "react-redux";

import { addNotification } from "../../../../notifications";

API.configure(awsconfig);

const CreateCourseLesson = (props) => {
  console.log(props.route.params, ".....");
  const toast = useToast();
  let [course, setCourse] = useState({});
  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);

  useEffect(async () => {
    setCourse(props.route.params.course);
  }, [props]);

  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
    },
    mode: "onChange",
  });
console.log(typeof  course.id );
  const onSave = async (data) => {
    try {
      console.log("Uploading resume");

      await API.graphql({
        query: createCourseLesson,
        variables: {
          input: {
            courseID: course.id,
            title:data.title,
            description:data.description
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          console.log(res, "res");
          
          addNotification({
            notificationType: 'New Lesson',
            entityType: 'LESSON',
            entityId: course.id,
            entityName: data.title,
            actorId: user.username,
            actorName: user.firstName,
            message: `${user.firstName} added new lesson ${data.title} for course ${course.title}`
          })

          toast.show("Your Data has been saved successfully.", {
            type: "success",
            placement: "top",
            duration: 4000,
            offset: 30,
            animationType: "zoom-in",
          });
          props.navigation.goBack();
        })
        .catch((err) => {
          console.log("Error saving contact", err);
        });
    } catch (error) {
      console.log("Error saving contact", error);
      toast.show("Error saving your Data. Please try again", {
        type: "success",
        placement: "top",
        duration: 4000,
        offset: 30,
        animationType: "zoom-in",
      });
      // props.navigation.goBack();
    }
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"New Lesson"}
        showBack={true}
        leftType={"name"}
        leftName={"Save"}
        onPress={handleSubmit((data) => {
          onSave(data);
        })}
      />
      <Card.Content style={{ marginTop: 30 }}>
        <Text>{"Title"}</Text>
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              multiline={false}
              placeholder="Enter Title"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              outlineColor={errors.note && "#fc4c4c"}
              activeOutlineColor={errors.note && "#fc4c4c"}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Title is required",
            },
          }}
        />
        <Text>{"Description"}</Text>
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              multiline={true}
              placeholder="Enter Description"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              style={{ height: 100 }}
              outlineColor={errors.note && "#fc4c4c"}
              activeOutlineColor={errors.note && "#fc4c4c"}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Description is required",
            },
          }}
        />
      </Card.Content>
    </Card>
  );
};

export default CreateCourseLesson;

const styles = StyleSheet.create({});
