import React, { useState, useEffect } from "react";

import {
  View,
  StyleSheet,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Alert,
} from "react-native";

import {
  DataTable,
  Searchbar,
  Button,
  IconButton,
  Portal,
  Paragraph,
  Dialog,
  TouchableRipple,
  Avatar,
  Card,
  Text,
  Surface,
} from "react-native-paper";

import Header from "../../../global/components/Header";
import * as DocumentPicker from "expo-document-picker";

import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";

import Amplify, { Auth, graphqlOperation, API, Storage } from "aws-amplify";

import awsconfig from "../../../aws-exports";

import mime from "mime-types";
import { updateContact } from "../../../graphql/mutations";

API.configure(awsconfig);
Storage.configure(awsconfig);

const ContactFileUpload = (props) => {
  let [contact, setContact] = useState({});
  let [user, setUser] = useState({});
  console.log(props.route.params.contact, "contact");
  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      filePurpose: "",
      fileName: "",
    },
    mode: "onChange",
  });
  const [contactFile, setContactFile] = useState({
    fileName: "",
    fileType: "",
    blobData: null,
    contactId: null,
  });

  useEffect(() => {
    getUser();
  }, [props]);

  const getUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setUser(user);
    setContact(props.route.params.contact);
  };
  const showDocumentPicker = async () => {
    // Ask the user for the permission to access the media library
    const result = await DocumentPicker.getDocumentAsync({});

    console.log(result);

    const fileName = contact.id + "_" + result.name;
    const fileType = result.mimeType;

    const fileData = await fetch(result.uri);
    const blobData = await fileData.blob();

    let contactFile = {
      fileName,
      fileType,
      blobData,
      contactId: contact.id,
    };

    setContactFile(contactFile);
  };

  const onSave = async (data) => {
    try {
      await Storage.put(contactFile.fileName, contactFile.blobData, {
        level: "public",
      }).then(async () => {
        contactFile.filePurpose = data.filePurpose;
        delete contactFile.blobData;

        console.log(contactFile, "contactFile");
        console.log(contact.contactFiles, "contact.contactFiles");
        await API.graphql({
          query: updateContact,
          variables: {
            input: {
              id: contact.id,
              contactFiles:
                contact.contactFiles !== null
                  ? [
                      ...contact.contactFiles,
                      {
                        fileName: contactFile.fileName,
                        fileType: contactFile.fileType,
                        filePurpose: contactFile.filePurpose,
                      },
                    ]
                  : [
                      {
                        fileName: contactFile.fileName,
                        fileType: contactFile.fileType,
                        filePurpose: contactFile.filePurpose,
                      },
                    ],
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
          .then(async (res) => {
            console.log(res, "res");
            alert("New File has been added successfully.");
            props.navigation.goBack();
          })
          .catch((err) => {
            console.log(err, "errr");
          });
      });
    } catch (err) {
      console.log("error: ", err);
    }
  };
  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"Contact File"}
        showBack={true}
        leftType={"name"}
        leftName={"Save"}
        onPress={handleSubmit((data) => {
          onSave(data);
        })}
        // leftIcon={"account-plus"}
        // routename={"AddContact"}
        // leftType={"icon"}
      />
      <Card.Content style={{ marginVertical: 30 }}>
        <Text>{"File Purpose"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              name: "filePurpose",
              type: "select",
              textInputProps: {
                placeholder: "Select File Purpose",
                //   label: 'City',
                //   left: <TextInput.Icon name={'office-building'} />,
              },
              rules: {
                required: {
                  value: true,
                  message: "File Purpose is required",
                },
              },
              options: [
                {
                  label: "Resume",
                  value: "resume",
                },
                {
                  label: "Passport",
                  value: "passport",
                },
                {
                  label: "Visa",
                  value: "visa",
                },
                {
                  label: "Driving License",
                  value: "dl",
                },
              ],
            },
          ]}
        />
        <Text>{"File Name"}</Text>
        <Text>{contactFile.fileName}</Text>
        <Card.Content
          style={{
            paddingHorizontal: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "blue",
              borderWidth: 1,
              borderColor: "transparent",
              padding: 10,
              borderRadius: 5,
              width: "30%",
              height: 50,
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={showDocumentPicker}
          >
            <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>
              Attach File
            </Text>
          </TouchableOpacity>
        </Card.Content>
      </Card.Content>
    </Card>
  );
};

export default ContactFileUpload;

const styles = StyleSheet.create({});
