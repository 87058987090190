import { Amplify,API } from "aws-amplify";
import config from "./src/aws-exports";

import { createNotification } from "./src/graphql/mutations";
import { Notification } from "./src/models";

API.configure(config);

export const addNotification = async (n) => {
  let notification = {
    notificationType: n.notificationType,
    entityType: n.entityType,
    entityId: n.entityId,
    entityName: n.entityName,
    actorId: n.actorId,
    actorName: n.actorName,
    message: n.message,
    status: false
  };
  console.log(notification);
  try {
    console.log("Uploading resume");

    await API.graphql({
      query: createNotification,
      variables: {
        input: notification
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        console.log(res, "res");
      })
      .catch((err) => {
        console.log("Error saving notification", err);
      });
  } catch (error) {
    console.log("Error saving notification", error);
  }
}