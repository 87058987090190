import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Notifications from '../components/Notifications'

const NotificationsScreen = (props) => {
  return  <Notifications {...props} />
}

export default NotificationsScreen

const styles = StyleSheet.create({})