import { StyleSheet, View, TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import { Card, Text } from "react-native-paper";
import Header from "../../../global/components/Header";
import Feather from "react-native-vector-icons/Feather";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import FontAwesome from "react-native-vector-icons/FontAwesome";

import * as Linking from "expo-linking";

const Communicate = (props) => {
  let [contact, setContact] = useState(props.contact);

  useEffect(() => {
    setContact(props.contact);
  }, [props]);

  const onSendSms = () => {
    // let url = `sms:${contact.phone}`;
    // console.log(url);
    // Linking.openURL(url);
    props.navigation.navigate("Chat",{contact:contact});
  };

  const dialCall = () => {
    let url = `tel:${contact.phone}`;
    console.log(url);
    Linking.openURL(url);
  };

  const onSendWhatsapp = () => {
    let url = `https://wa.me/${contact.whatsappId.replace("+", "")}?text=`;
    console.log(url);
    Linking.openURL(url);
  };

  const onSendMail = () => {
    let url = `mailto:${contact.email}`;
    console.log(url);
    Linking.openURL(url);
  };

  return (
    <Card style={{ width: "99%", marginTop: 5 }}>
      <Card.Content
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginHorizontal: 20,
        }}
      >
        <TouchableOpacity
          style={[
            styles.callIconBtn,
            {
              backgroundColor: "#81B095",
            },
          ]}
          onPress={() => {
            dialCall();
          }}
        >
          <Feather
            style={[styles.iconStyle, { color: "#F1F3F4" }]}
            name="phone-call"
          />
        </TouchableOpacity>

        <TouchableOpacity
          style={[
            styles.smsIconBtn,
            {
              backgroundColor: "#6B8BC2",
            },
          ]}
          onPress={onSendSms}
          // onPress={() => {
          //   props.navigation.navigate("Chat");
          // }}
        >
          <MaterialIcons
            style={[styles.iconStyle, , { color: "#F1F3F4" }]}
            name="sms"
            size={40}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.whatsappIconBtn,
            {
              backgroundColor: "#81B095",
            },
          ]}
          onPress={() => {
            onSendWhatsapp();
          }}
        >
          <FontAwesome
            style={[styles.iconStyle, { color: "#F1F3F4" }]}
            name="whatsapp"
          />
        </TouchableOpacity>

        <TouchableOpacity
          style={[
            styles.mailIconBtn,
            {
              backgroundColor: "#6B8BC2",
            },
          ]}
          onPress={onSendMail}
        >
          <MaterialIcons
            style={[styles.iconStyle, , { color: "#F1F3F4" }]}
            name="mail-outline"
          />
        </TouchableOpacity>
      </Card.Content>
    </Card>
  );
};

export default Communicate;

const styles = StyleSheet.create({
  smsIconBtn: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  callIconBtn: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whatsappIconBtn: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mailIconBtn: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconStyle: {
    fontSize: 20,
  },
});
