import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  FlatList,
  Platform,
  ActivityIndicator,
} from "react-native";
import { Searchbar, Card, FAB } from "react-native-paper";
import Header from "../../../global/components/Header";
import Ionicons from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

import { listContacts } from "../../../graphql/queries";

import { useFocusEffect } from "@react-navigation/native";

import Amplify, { Auth, API } from "aws-amplify";

import awsconfig from "../../../aws-exports";
import UserItem from "./UserItem";

API.configure(awsconfig);

const User = (props) => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchContacts();
      return () => {
        isActive = false;
      };
    }, [])
  );

  useEffect(() => {
    fetchContacts();
    // console.log(contacts, "contacts");
  }, [props]);

  const fetchContacts = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    console.log(currentUser.username);

    let userGroups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];

    // console.log(userGroups, "userGroups..");
    if (userGroups.indexOf("managers") != -1) {
      await API.graphql({
        query: listContacts,
        variables: {
          filter: {
            managerId: {
              eq: currentUser.username,
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setContacts(res.data.listContacts.items);
          setLoading(false);
          let filData = res.data.listContacts.items.filter((item) => {
            return (
              item.contactType === "MANAGER" || item.contactType === "RECRUITER"
            );
          });
          setFilteredContacts(filData);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    } else {
      await API.graphql({
        query: listContacts,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setContacts(res.data.listContacts.items);
          setLoading(false);
          let filData = res.data.listContacts.items.filter((item) => {
            return (
              item.contactType === "MANAGER" || item.contactType === "RECRUITER"
            );
          });
          setFilteredContacts(filData);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    }
  };

  const searchContacts = async (keyword) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    // console.log(currentUser.username);

    let userGroups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];

    if (userGroups.indexOf("managers") != -1) {
      await API.graphql({
        query: listContacts,
        variables: {
          filter: {
            and: [
              {
                managerId: {
                  eq: currentUser.username,
                },
              },
              {
                or: [
                  {
                    firstName: {
                      contains: keyword,
                    },
                  },
                  {
                    lastName: {
                      contains: keyword,
                    },
                  },
                  {
                    email: {
                      contains: keyword,
                    },
                  },
                ],
              },
            ],
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setContacts(res.data.listContacts.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    } else {
      await API.graphql({
        query: listContacts,
        variables: {
          filter: {
            or: [
              {
                firstName: {
                  contains: keyword,
                },
              },
              {
                lastName: {
                  contains: keyword,
                },
              },
              {
                email: {
                  contains: keyword,
                },
              },
            ],
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setContacts(res.data.listContacts.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    }
  };

  const renderItem = ({ item }) => {
    return <UserItem item={item} />;
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"Users"}
      />
      <Searchbar
        style={{
          borderColor: "black",
        }}
        placeholder="Search here..."
        onChangeText={(text) => searchContacts(text)}
      />
      {!loading ? (
        <FlatList data={filteredContacts} renderItem={renderItem} />
      ) : (
        <ActivityIndicator
          size="large"
          color="#390485"
          style={styles.loading}
        />
      )}
      {/* <FAB
        icon="plus"
        style={styles.fab}
        onPress={() => props.navigation.navigate("AddContact")}
      /> */}
    </Card>
  );
};

export default User;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  addnewuserbtn: {
    color: "#fff",
    height: 40,
    width: Platform.OS == "web" ? 150 : "60%",
    justifyContent: "center",
    alignItems: Platform.OS == "web" ? "center" : "stretch",
    marginTop: 10,
    borderRadius: 10,
  },
  flatListView: {
    width: "100%",
    marginTop: 20,
    borderBottomColor: "red",
    borderBottomWidth: 2,
    padding: 5,
    backgroundColor: "#ccc",
    flexDirection: "row",
    alignItems: "center",
  },
  actions: {
    color: "blue",
    width: 20,
    height: 20,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  flatListText: {
    flex: 1,
  },
  flatListButton: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    textTransform: "capitalize",
    paddingTop: 10,
  },
  discription: {
    fontSize: 14,
    paddingBottom: 10,
  },
  time: {
    fontSize: 16,
  },
  titlePosition: {
    flexDirection: "column",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
});
