import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  Button,
  IconButton,
  Paragraph,
  Surface,
  Title,
  TextInput,
  HelperText,
  Menu,
  Divider,
} from "react-native-paper";
import Header from "../../../global/components/Header";
import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { updateContact } from "../../../graphql/mutations";

import awsconfig from "../../../aws-exports";

import { useSelector } from "react-redux";
import { getContact } from "../../../graphql/queries";

API.configure(awsconfig);

const CreateContactNote = (props) => {
  console.log(props);

  let [contact, setContact] = useState({});

  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);

  useEffect(async () => {
    setContact(props.route.params.contact);
  }, [props]);

  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      note: "",
    },
    mode: "onChange",
  });

  const onSave = async (data) => {
    console.log(data,user);
    let contactNote = {
      message: data.note,
      userName: `${user.firstName} ${user.lastName}`,
    };
    console.log(contact);

    await API.graphql({
      query: updateContact,
      variables: {
        input: {
          id: contact.id,
          contactNotes: [...contact.contactNotes,contactNote],
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        console.log(res, "res");
        alert("New Note has been added successfully.");
        props.navigation.goBack();
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"New Note"}
        showBack={true}
        leftType={"name"}
        leftName={"Save"}
        onPress={handleSubmit((data) => {
          // console.log('form data', data, Object.values(data).every(a => a !== ''), "hh");
          onSave(data);
        })}
        // leftIcon={"account-plus"}
        // routename={"AddContact"}
        // leftType={"icon"}
      />
      <Card.Content style={{ marginTop: 30 }}>
        <Text>{"Note"}</Text>
        <Controller
          control={control}
          name="note"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              multiline={true}
              placeholder="Enter Note"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              style={{ height: 100 }}
              outlineColor={errors.note && "#fc4c4c"}
              activeOutlineColor={errors.note && "#fc4c4c"}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Note is required",
            },
            // pattern: {
            //     value: /^((\\+91-?)|0)?[0-9]{10}$/,
            //     message: 'phone Number is invalid',
            // }
          }}
        />
        <Card.Content>
          {errors.note?.message ? (
            <Text
              style={{ color: "#fc4c4c", fontSize: 12, paddingVertical: 2 }}
            >
              {errors.note?.message}
            </Text>
          ) : null}
        </Card.Content>
      </Card.Content>
    </Card>
  );
};

export default CreateContactNote;

const styles = StyleSheet.create({});
