import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import EditContact from '../components/EditContact';

const EditContactScreen = (props) => {
  return (
    <EditContact {...props} />
  )
}

export default EditContactScreen

const styles = StyleSheet.create({})