import { StyleSheet, View, TouchableOpacity,Alert } from "react-native";
import React, { useState, useEffect } from "react";
import { Card, Text } from "react-native-paper";
import Header from "../../../global/components/Header";
import Feather from "react-native-vector-icons/Feather";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import * as Clipboard from "expo-clipboard";

import * as Linking from "expo-linking";

import { useSelector } from "react-redux";

const CommunicateJob = (props) => {
    // console.log(props.job,"job....");
  let [job, setJob] = useState(props.job);
  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);

  useEffect(() => {
    setJob(props.job);
  }, [props]);

  const onSendSms = () => {
    // console.log(props?.route?.params?.sms);
    props?.route?.params?.sms
      ? Linking.openURL(`sms:${" "}`)
      : Linking.openURL(`sms:+${" "}`);
  };
  const openTwitter = () => {
    // props?.route?.params?.phone
    //   ? Linking.openURL(`tel:${contact.phone}`)
    //   : Linking.openURL(`tel:${contact.phone}`);
  };
  const onSendFaceBook = () => {
    // props?.route?.params?.whatsapp
    //   ? Linking.openURL(
    //       `https://api.whatsapp.com/send/?phone=${contact.whatsappId}&text&app_absent=0`
    //     )
    //   : Linking.openURL(
    //       `https://api.whatsapp.com/send/?phone=${contact.whatsappId}&text&app_absent=0`
    //     );
  };
  const onSendMail = () => {
    props?.route?.params?.email
      ? Linking.openURL(`mailto:${' '}`)
      : Linking.openURL(`mailto:${' '}`);
  };
  const copyToClipboard = async () => {
    let jobSummary=`
    Hi all,
      Hope you are doing great,
      We have a urgent requirements for C2C

      Title : ${job.title}
      Location : ${job.location}
      
      Visa : USC, GC, GC EAD, L2 EAD, H4 EAD, TN, OPT-EAD, H1b

      Reach me at: +1 (737) 332-6739 (Ext : ${user?.contact?.extension})
      E-mail: ${user?.contact?.email}
    `;

    await Clipboard.setStringAsync(`${jobSummary}`);
    Alert.alert('Address Copied to Clipboard!');
  };

  return (
    <Card style={{ width: "99%", marginTop: 5 }}>
      <Card.Content
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginHorizontal: 20,
        }}
      >
        {/* <TouchableOpacity
          style={[
            styles.callIconBtn,
            {
              backgroundColor: "#81B095",
            },
          ]}
          onPress={() => {
            openTwitter();
          }}
        >
          <Feather
            style={[styles.iconStyle, { color: "#F1F3F4" }]}
            name="twitter"
          />
        </TouchableOpacity> */}

        <TouchableOpacity
          style={[
            styles.smsIconBtn,
            {
              backgroundColor: "#6B8BC2",
            },
          ]}
          onPress={onSendSms}
        >
          <MaterialIcons
            style={[styles.iconStyle, , { color: "#F1F3F4" }]}
            name="sms"
            size={40}
          />
        </TouchableOpacity>
        {/* <TouchableOpacity
          style={[
            styles.whatsappIconBtn,
            {
              backgroundColor: "#81B095",
            },
          ]}
          onPress={() => {
            onSendFaceBook();
          }}
        >
          <FontAwesome
            style={[styles.iconStyle, { color: "#F1F3F4" }]}
            name="facebook"
          />
        </TouchableOpacity> */}

        <TouchableOpacity
          style={[
            styles.mailIconBtn,
            {
              backgroundColor: "#6B8BC2",
            },
          ]}
          onPress={onSendMail}
        >
          <MaterialIcons
            style={[styles.iconStyle, , { color: "#F1F3F4" }]}
            name="mail-outline"
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.mailIconBtn,
            {
              backgroundColor: "#81B095",
            },
          ]}
          onPress={copyToClipboard}
        >
          <Feather
            style={[styles.iconStyle, { color: "#F1F3F4" }]}
            name="clipboard"
          />
        </TouchableOpacity>
      </Card.Content>
    </Card>
  );
};

export default CommunicateJob;

const styles = StyleSheet.create({
  smsIconBtn: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  callIconBtn: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whatsappIconBtn: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mailIconBtn: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconStyle: {
    fontSize: 20,
  },
});
