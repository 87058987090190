import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CourseDetails from '../components/CourseDetails'

const CourseDetailScreen = (props) => {
  return (
    <CourseDetails {...props}/>
  )
}

export default CourseDetailScreen

const styles = StyleSheet.create({})