import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import { Card, Surface, List, FAB } from "react-native-paper";
import Communicate from "./Communicate";
import { useFocusEffect } from "@react-navigation/native";

import Amplify, { API } from "aws-amplify";
import awsconfig from "../../../aws-exports";

import { getContact } from "../../../graphql/queries";

API.configure(awsconfig);

const ContactFullData = (props) => {
    // console.log(props.contacts, "props.data");
  let [contact, setContact] = useState();
  const [contacts,setContacts]=useState(props?.contacts)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setContact(props?.data);
  }, [props]);
  return (
    <Card
      style={{
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: "white",
      }}
    >
      <Communicate contact={contact} {...props} />
      <Surface
        style={[
          styles.details,
          {
            backgroundColor: "white",
            paddingVertical: 5,
            marginHorizontal: 5,
            marginBottom: 5,
          },
        ]}
      >
        <ScrollView style={{}}>
          <Card.Content style={{ flex: 1, paddingHorizontal: 0 }}>
            <List.Item title={"First Name"} description={contact?.firstName} />
            <List.Item title={"Last Name"} description={contact?.lastName} />
            <List.Item
              title={"Middle Name"}
              description={contact?.middleName}
            />
            <List.Item title={"Email"} description={contact?.email} />
            <List.Item title={" Phone Number"} description={contact?.phone} />
            <List.Item
              title={"Contact Type"}
              description={contact?.contactType}
            />
            {/* <List.Item
              title={"Contract Type"}
              description={contact?.contractType}
            /> */}
            <List.Item title={"Job Title"} description={contact?.jobTitle} />
            <List.Item title={"DOB"} description={contact?.dob} />
            <List.Item title={"Gender"} description={contact?.gender} />
            <List.Item title={"SSN"} description={contact?.ssn} />
            <List.Item
              title={"Whatsapp Id"}
              description={contact?.whatsappId}
            />
            <List.Item
              title={"Address"}
              description={contact?.shippingAddress?.address1}
            />
            <List.Item title={"City"} description={contact?.city} />
            <List.Item title={"State"} description={contact?.state} />
            <List.Item
              title={"Zip"}
              description={contact?.shippingAddress?.zip}
            />
            <List.Item
              title={"Primary Skill"}
              description={contact?.primarySkill}
            />
            <List.Item
              title={"Secondary Skill"}
              description={contact?.secondarySkill}
            />
            <List.Item title={"Visa Type"} description={contact?.visaType} />
            <List.Item title={"Experience"} description={contact?.experience} />
            <List.Item
              title={"Employer Phone"}
              description={contact?.employerPhone}
            />
            <List.Item
              title={"Employer Name"}
              description={contact?.employerName}
            />
            <List.Item
              title={"Employer Email"}
              description={contact?.employerEmail}
            />
            <List.Item title={"Relocation"} description={contact?.relocation} />
            <List.Item title={"University"} description={contact?.university} />
            <List.Item title={"Degree"} description={contact?.degree} />
            <List.Item
              title={"Year Of Joined"}
              description={contact?.yearJoined}
            />
            <List.Item title={"Interest In"} description={contact?.interest} />
          </Card.Content>

          <FAB
            icon="pencil"
            style={styles.fab}
            onPress={() =>
              props.navigation.navigate("EditContact", { contact, contacts })
            }
          />
        </ScrollView>
      </Surface>
    </Card>
  );
};

export default ContactFullData;

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  details: {
    flex: 1,
    marginTop: 5,
    paddingHorizontal: 0,
    elevation: 2,
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
});
