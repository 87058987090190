import { StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { Card } from "react-native-paper";
import Header from "../../../global/components/Header";
import { useFocusEffect } from "@react-navigation/native";

import { Title, Paragraph } from "react-native-paper";

import {
  Tabs,
  TabScreen,
  useTabIndex,
  useTabNavigation,
} from "react-native-paper-tabs";
import ContactFullData from "./ContactFullData";
import ContactNotes from "./ContactNotes";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";

import awsconfig from "../../../aws-exports";
import ContactFiles from "./ContactFiles";

import { getContact } from "../../../graphql/queries";

API.configure(awsconfig);

const ContactDetails = (props) => {
  const [contact, setContact] = useState(props.route.params.item);
  // console.log(props.route.params.contacts.length,"contact");
  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchContact();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const fetchContact = async () => {
    await API.graphql({
      query: getContact,
      variables: {
        id: props ? props.route.params.item.id : contact.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        // console.log(res.data.getContact, "getContact");
        setContact(res.data.getContact);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={`${contact.firstName} ${contact.lastName}`}
        showBack={true}
      />
      <Card.Content style={{ flex: 1, backgroundColor: "transparent" }}>
        <Tabs
          iconPosition={"top"} // leading, top | default=leading
          style={{ backgroundColor: "#fff" }} // works the same as AppBar in react-native-paper
        >
          <TabScreen label="Details" icon="details">
            <ContactFullData
              data={contact}
              {...props}
              contacts={props.route.params.contacts}
            />
          </TabScreen>
          <TabScreen label="Docs" icon="file-document">
            <ContactFiles
              data={contact.contactFiles}
              contact={contact}
              {...props}
            />
          </TabScreen>
          <TabScreen label="Notes" icon="notebook" >
              <ContactNotes data={contact.contactNotes} contact={contact} {...props} />
          </TabScreen>
        </Tabs>
      </Card.Content>
    </Card>
  );
};

export default ContactDetails;

const styles = StyleSheet.create({});
