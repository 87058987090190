import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Alert,
} from "react-native";
import { Paragraph, Card, Text, Surface, FAB, List } from "react-native-paper";

import { useFocusEffect } from "@react-navigation/native";

import Amplify, { API } from "aws-amplify";
import awsconfig from "../../../aws-exports";

import { getCourse } from "../../../graphql/queries";

import { useSelector } from 'react-redux'

API.configure(awsconfig);

const CourseNotes = (props) => {

  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({});
  const [notes, setNotes] = useState([]);

  const loggedinUser = useSelector((state) => state.login.user);
  let [user, setUser] = useState(loggedinUser);


  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchCourse();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const fetchCourse = async () => {
    await API.graphql({
      query: getCourse,
      variables: {
        id: props ? props.course.id : course.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        console.log(res.data.getCourse);
        setCourse(res.data.getCourse);
        setNotes(res.data.getCourse.courseNotes);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  useEffect(() => {
    setCourse(props.course);
    setNotes(props.course.courseNotes);
  }, [props]);

  const renderItem = ({ item }) => {
    // console.log(item);
    return (
      <Surface
        style={{
          backgroundColor: "white",
          flex: 1,
          marginVertical: 8,
          marginHorizontal: 16,
          elevation: 4,
        }}
      >
        <ScrollView>
          <Card.Content style={{ flexDirection: "row", paddingHorizontal: 0 }}>
          <Card.Content style={{paddingVertical: 7}}>
              <Paragraph style={{ color: "black",  }}>
                {item.message}
              </Paragraph>
              <Paragraph
                style={{
                  color: "#696969",
                  textTransform: "capitalize",
                }}
              >
                {item.userName}
              </Paragraph>
            </Card.Content>
          </Card.Content>
        </ScrollView>
      </Surface>
    );
  };

  return (
    <Card
      style={{
        flex: 1,
      }}
    >
      <Card.Content >
        <FlatList data={notes} renderItem={renderItem} />
      </Card.Content>
      {
        (user.group == "managers") &&
        (
          <FAB
            icon="plus"
            style={styles.fab}
            onPress={() =>
              props.navigation.navigate("CreateCourseNote", { course })
            }
          />
        )
      }

    </Card>
  );
};

export default CourseNotes;

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
});
