import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState } from "react";
import { Card, Surface, List, FAB } from "react-native-paper";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import CommunicateJob from "./CommunicateJob";

const JobFullData = (props) => {
//   console.log(props.data, "props");
  let job = props.data;

  const onShareThis = () => {};

  return (
    <Card
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: "white",
      }}
    >
      <CommunicateJob job={job} />
      <Surface
        style={[
          styles.DetailsCard,
          {
            backgroundColor: "white",
            paddingVertical: 25,
            marginHorizontal: 5,
            marginBottom: 5,
          },
        ]}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ flexGrow: 1 }}
        >
          <Card.Content style={{ flex: 1, paddingHorizontal: 0 }}>
            <List.Item title={"Title"} description={job?.title} />

            <List.Item title={"Status"} description={job?.status} />
            <List.Item title={"PositionType"} description={job?.positionType} />
            <List.Item title={"Client"} description={job?.client} />
            <List.Item title={"PrimeVendor"} description={job?.primeVendor} />
            <List.Item
              title={"Location"}
              description={job?.location}
              // titleStyle={{ color: 'black', }}
            />
            <List.Item
              title={"EcorfyRate"}
              description={`$${job?.ecorfyRate}`}
            />
            <List.Item title={"Vendor"} description={job?.vendor?.firstName} />
            <List.Item title={"Description"}
            descriptionNumberOfLines={70}
             description={job?.description} />
          </Card.Content>
        </ScrollView>
        <Card style={{ flex: 1, width: 400 }}>
          <Card.Content
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              style={[
                styles.mailIconBtn,
                {
                  backgroundColor: "#6B8BC2",
                },
              ]}
              onPress={onShareThis}
            >
              <MaterialIcons
                style={[styles.iconStyle, , { color: "#F1F3F4" }]}
                name="share"
              />
            </TouchableOpacity>
          </Card.Content>
        </Card>
        <FAB
          icon="pencil"
          style={styles.fab}
          onPress={() => props.navigation.navigate("EditJob", { job })}
        />
      </Surface>
    </Card>
  );
};

export default JobFullData;

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  detailcardText: {
    fontSize: 16,
    fontWeight: "bold",
    width: "50%",
    textAlign: "left",
  },
  detailcardText1: {
    fontSize: 14,
    marginVertical: 2,
    textAlign: "left",
    width: "50%",
  },
  DetailsCard: {
    flex: 1,
    marginTop: 5,
    paddingHorizontal: 0,
    elevation: 2,
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
  shareIconBtn: {
    width: 70,
    height: 70,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconStyle: {
    fontSize: 30,
  },
});
