import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Alert,
} from "react-native";
import {
  DataTable,
  Searchbar,
  Button,
  IconButton,
  Portal,
  Paragraph,
  Dialog,
  TouchableRipple,
  Avatar,
  Card,
  Text,
  Surface,
  FAB,
} from "react-native-paper";
import Header from "../../../global/components/Header";
import Ionicons from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

import { listContacts } from "../../../graphql/queries";

import { Contact } from "../../../models";

import { useFocusEffect } from "@react-navigation/native";

import Amplify, {
  Auth,
  graphqlOperation,
  API,
  DataStore,
  Predicates,
} from "aws-amplify";
// import { deleteUser, updateUser } from "../../graphql/mutations";

import awsconfig from "../../../aws-exports";
import ContactItem from "./ContactItem";

Amplify.configure(awsconfig);

const Contacts = (props) => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchContacts();
      return () => {
        isActive = false;
      };
    }, [])
  );

  useEffect(() => {
    fetchContacts();
  }, [props]);

  const getManager = async () => {
    const userData = await Auth.currentAuthenticatedUser();
    await API.graphql({
      query: listContacts,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        setContacts(res.data.listContacts.items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  const fetchContacts = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    console.log(currentUser.username);

    let userGroups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];

    if (userGroups.indexOf("managers") != -1) {
      await API.graphql({
        query: listContacts,
        variables: {
          filter: {
            managerId: {
              eq: currentUser.username,
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setContacts(res.data.listContacts.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    } else {
      await API.graphql({
        query: listContacts,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setContacts(res.data.listContacts.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    }
  };
  
  const searchContacts = async (keyword) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    console.log(currentUser.username);

    let userGroups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];

    if (userGroups.indexOf("managers") != -1) {
      await API.graphql({
        query: listContacts,
        variables: {
          filter: {
            and: [
              {
                managerId: {
                  eq: currentUser.username,
                },
              },
              {
                or: [
                  {
                    firstName: {
                      contains: keyword,
                    },
                  },
                  {
                    lastName: {
                      contains: keyword,
                    },
                  },
                  {
                    email: {
                      contains: keyword,
                    },
                  },
                  {
                    phone: {
                      contains: keyword,
                    },
                  },
                ],
              },
            ],
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setContacts(res.data.listContacts.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    } else {
      await API.graphql({
        query: listContacts,
        variables: {
          filter: {
            or: [
              {
                firstName: {
                  contains: keyword,
                },
              },
              {
                lastName: {
                  contains: keyword,
                },
              },
              {
                email: {
                  contains: keyword,
                },
              },
            ],
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          setContacts(res.data.listContacts.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    }
  };

  const onContactItemPressed = (item) => {
    props.navigation.navigate("ContactDetails", { item , contacts});
  };

  const slectedItemsList = (item) => {
    // console.log(item.id, "item...");
    if (selectedItems.includes(item.id)) {
      console.log("yes");
      const newListItems = selectedItems.filter(
        (listItem) => listItem !== item.id
      );
      return setSelectedItems([...newListItems]);
    } else setSelectedItems([...selectedItems, item.id]);
  };

  const renderItem = ({ item }) => {
    return (
      <ContactItem
        item={item}
        onContactItemPressed={onContactItemPressed}
        slectedItemsList={slectedItemsList}
        selectedItems={selectedItems}
        contacts={contacts}
      />
    );
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"Contacts"}
        leftType={"name"}
        leftName={selectedItems.length > 0 ? "Send" : ""}
      />
      <Searchbar
        style={{
          borderColor: "black",
        }}
        placeholder="Search here..."
        onChangeText={(text) => searchContacts(text)}
      />
      {/* <Text>{selectedItems.length}</Text> */}
      {!loading ? (
        <FlatList data={contacts} renderItem={renderItem} />
      ) : (
        <ActivityIndicator
          size="large"
          color="#390485"
          style={styles.loading}
        />
      )}
      <FAB
        icon="plus"
        style={styles.fab}
        onPress={() => props.navigation.navigate("AddContact")}
      />
    </Card>
  );
};

export default Contacts;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  addnewuserbtn: {
    color: "#fff",
    height: 40,
    width: Platform.OS == "web" ? 150 : "60%",
    justifyContent: "center",
    alignItems: Platform.OS == "web" ? "center" : "stretch",
    marginTop: 10,
    borderRadius: 10,
  },
  flatListView: {
    width: "100%",
    marginTop: 20,
    borderBottomColor: "red",
    borderBottomWidth: 2,
    padding: 5,
    backgroundColor: "#ccc",
    flexDirection: "row",
    alignItems: "center",
  },
  actions: {
    color: "blue",
    width: 20,
    height: 20,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  flatListText: {
    flex: 1,
  },
  flatListButton: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    textTransform: "capitalize",
    paddingTop: 10,
  },
  discription: {
    fontSize: 14,
    paddingBottom: 10,
  },
  time: {
    fontSize: 16,
  },
  titlePosition: {
    flexDirection: "column",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
});
