import React from 'react'
import Onboarding from '../components/Onboarding'

export function RecruiterOnboardingScreen(props) {
   return (
       <Onboarding {...props}/>
   );
 }

 
 export default RecruiterOnboardingScreen;