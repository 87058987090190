import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Alert,
} from "react-native";
import React, { useState } from "react";
import {
  Card,
  ActivityIndicator,
  Surface,
  Portal,
  Modal,
} from "react-native-paper";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { useToast } from "react-native-toast-notifications";
import { Contact } from "../../../models";
import { useNavigation } from "@react-navigation/native";
import { Auth, API, graphqlOperation, DataStore, Storage } from "aws-amplify";

import config from "../../../aws-exports";

API.configure(config);
DataStore.configure(config);
Storage.configure(config);

const Agreement = (props) => {
  console.log(props, props.getState().navigation, "step3");

  const toast = useToast();
  const navigation = useNavigation();
  const [checkAgree, setCheckAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const containerStyle = {
    // backgroundColor: "transparent",
    // width: "10%",
    // height: "10%",
    alignSelf: "center",
  };

  const goBack = () => {
    const { back } = props;
    // Go to previous step
    back();
  };

  const onSave = async () => {
    // props.getState().navigation.navigate("Login");
    console.log(props.getState().stepOneData, props.getState().stepTwoData);
    setLoading(true);
    setLoadingPage(true);
    let formData = props.getState().stepOneData;
    let filesData = props.getState().stepTwoData;
    let contactAadharFile = props.getState().stepTwoData[2];
    console.log(contactAadharFile, "contactAadharFile");
    let contactPanFile = props.getState().stepTwoData[1];
    let contactResumeFile = props.getState().stepTwoData[0];
    let contactProfilePic = props.getState().stepTwoData[3];
    try {
      // if (verifyUser(data.email, data.phone)) {
      //   alert("User is already registered either with email and/or phone. Please use different email and/or phone to register again");
      //   return;
      // }
      console.log("Uploading resume");
      let resume = await Storage.put(
        contactResumeFile.fileName,
        contactResumeFile.blobData,
        { level: "public" }
      );
      console.log("Uploading aadhar");
      let aadhar = await Storage.put(
        contactAadharFile.fileName,
        contactAadharFile.blobData,
        { level: "public" }
      );
      console.log("Uploading pan");
      let pan = await Storage.put(
        contactPanFile.fileName,
        contactPanFile.blobData,
        { level: "public" }
      );
      let profilePic = await Storage.put(
        contactProfilePic.fileName,
        contactProfilePic.blobData,
        { level: "public" }
      );
      delete contactResumeFile.blobData;
      delete contactAadharFile.blobData;
      delete contactPanFile.blobData;
      delete contactProfilePic.blobData;
      let contactForm = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email.toLowerCase(),
        contactType: "APPLICANT",
        phone: formData.phone,
        userId: "0145c4fd-97dc-4ef2-8f4e-59f7b4c76aab",
        contactFiles: [
          contactAadharFile,
          contactPanFile,
          contactResumeFile,
          contactProfilePic,
        ],
      };
      console.log(contactForm);
      await DataStore.save(new Contact(contactForm));
      toast.show("Your application has been saved successfully.", {
        type: "success",
        placement: "top",
        duration: 4000,
        offset: 30,
        animationType: "zoom-in",
      });
      console.log("Contact saved successfully!");
      // props.navigation.navigate("Login");
      props.getState().navigation.navigate("Login");
      setLoading(false);
      setLoadingPage(false);
    } catch (error) {
      console.log("Error saving contact", error);
      toast.show("Error saving your application. Please try again", {
        type: "success",
        placement: "top",
        duration: 4000,
        offset: 30,
        animationType: "zoom-in",
      });
      props.getState().navigation.navigate("Login");
    }
  };

  return (
    <Card style={{ flex: 1, backgroundColor: "transparent" }}>
      <Card.Content>
        <Text
          variant="displayLarge"
          style={{
            fontSize: 24,
            marginBottom: 10,
            marginTop: 10,
            fontWeight: "bold",
          }}
        >
          Independent Contractor Agreement
        </Text>
        <Surface style={{ height: 300, marginBottom: 20 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Text style={{ padding: 20 }}>
              {`
                      THIS AGREEMENT entered into on this ${new Date().toLocaleString()} is for the Professional association agreement for the services between ECorfy Technologies Pvt Ltd whose principal place of business is located at HIG-129, Pramani Plaza, 5th Floor, KPHB, Hyderabad, TS 500085 and the Contractor who is submitting this agreement
                      WHEREAS, the Company requests the Contractor to perform services for it and may request the Contractor to perform other services in the future; and
                      WHEREAS, the Company and the Contractor desire to enter into an agreement, which will define respective rights and duties as to all services to be performed,
                      WHEREAS, the Contractor affirms that he or she understands all the provisions contained in this Agreement, and in the case that he or she requires clarification as to one or more of the provisions contained herein, he or she has requested clarification or otherwise sought legal guidance,
                      NOW, THEREFORE, in consideration of the covenants and agreements contained herein, the parties hereto agree as follows:
                      1.0 Services. Beginning on the Effective Date, and remaining in effect for the 24 Months duration of this Agreement, the Contractor shall provide the Company with the following services, without limitation.
                      2.0 Contractor Representations and Warranties. Beginning on the Effective Date, and remaining in effect for the duration of this Agreement, the Contractor makes the following representations and warranties.
                      2.1 That he or she is fully authorized and empowered to enter into this Agreement, and that his or her performance of the obligations under this Agreement will not violate any agreement between the Contractor and any other person, firm or organization or any law or governmental regulation.
                      2.2 That he or she is more than eighteen (18) years of age and not otherwise incapacitated at the time of the Agreement.
                      2.3 That he or she will in good faith attempt to notify the Company of any change(s) to the Contractor’s schedule that could adversely affect the availability of the Contractor, whether
                      , and _______________________________, whose principal place of business/residence is at ____________________________________ herein after,
                      called the "The Parties."
                      2.4 That he or she will bear all expenses incurred in the performance of this Agreement.
                      2.5 That he or she will adhere to the Company decision
                      3.0 Company Representations and Warranties. Beginning on the Effective Date, and remaining in effect for the duration of this Agreement, the Company makes the following representations and warranties.
                      3.1 That it is fully authorized and empowered to enter into this Agreement, and that its performance of the obligations under this Agreement will not violate any agreement between the Company and any other person, firm or organization or any law or governmental regulation.
                      3.2 That it is in full compliance with any and all laws and/or statutes applicable to the services described hereunder.
                      3.3 That in its sole and absolute discretion, determine whether or not to proceed or whether to modify or change the terms of this agreement with the Contractor.
                      4.0 Compensation.
                      4.1 The compensation is applicable for the revenue received from the resources introduced to the company by the Contractor under following cases.
                      4.2 Compensation structure above is only applicable and limited to the period of resource within company payroll. However, Both Company and Contractor responsible to try their best effort to convince the resource to stay. No special provisions will be given to any friends and family referrals from Contractor or Company.
                      4.3 Any resources introduced either by Company or Contractor must abide resource contract terms of the company provisioned with. At any time, Resource or Contractor should not circumvent and/or litigate terms or things done in bad faith or bad name to company brand. In such cases, Company has rights to terminate this agreement without notifying either party to protect clientele / resources of the company.
                      5.0 Independent Contractor Status.
                      5.1 The Contractor is an independent contractor of the Company. Nothing contained in this Agreement shall be construed to create the relationship of employer and employee, principal and agent, partnership or joint venture, or any other fiduciary relationship.
                      5.2 The Contractor shall have no authority to act as agent for, or on behalf of, the Company, or to represent the Company, or bind the Company in any manner.
                      5.3 The Contractor shall not be entitled to worker's compensation, retirement, insurance or other benefits afforded to employees of the Company.
                      6.0 Confidential Information.
                      6.1 The Contractor and his or her employees shall not, during the time of rendering services to the Company or thereafter, disclose to anyone other than authorized employees of the Company (or persons designated by such duly authorized employees of the Company) or use for the benefit of the Contractor and his or her employees or for any entity other than the Company, any information of a confidential nature, including but not limited to, information relating to: any such materials or intellectual property; any of the Company projects or programs; the technical,
                        
                      commercial or any other affairs of the Company; or, any confidential information which the Company has received from a third party.
                      6.2 Contractor shall, upon request of Company and/or upon termination of this Agreement, immediately return such confidential information and all copies thereof in any form whatsoever under the power or control of Contractor to Company, and delete such information from all retrieval systems and databases or destroy same as directed by Company.
                      7.0 Intellectual Property.
                      7.1 The Contractor represents that all content provided by the Contractor to the Company, in furtherance of the services described hereunder, including, without limitation, images, videos and text, including any intellectual property, such as copyrights or trademarks (the “Content”), is owned solely and legally by the Contractor.
                      7.2 The Contractor hereby assigns to Company or Company's designee, for no additional consideration, all Contractor's rights, including copyrights, in all deliverables and other works prepared by the Contractor under this agreement. The Contractor shall, and shall cause its employees and agents to, promptly sign and deliver any documents and take any actions that company reasonably requests to establish and perfect the rights assigned to company or its designee under this provision.
                      7.3 Any materials developed by the Company, making use of Content, remains the sole property of the Company subject to all applicable laws and/or statutes.
                      8.0 Liability.
                      8.1 The Company shall not be responsible for any costs incurred by the Contractor, including, without limitation, any and all fees and expenses, such as those described in Section 2.4 above.
                      8.2 The Company makes no guarantees regarding the physical and/or mental fitness of any Clients. The Contractor shall perform the services set out in this Agreement at his or her own risk.
                      8.3 EXCEPT WITH RESPECT TO THE PARTIES’ INDEMNIFICATION OBLIGATIONS, NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT, INCLUDING BODILY INJURY, DEATH, LOSS OF REVENUE, OR PROFITS OR OTHER BENEFITS, AND CLAIMS BY ANY THIRD PARTY, EVEN IF THE PARTIES HAVE BEEN
                        
                      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION APPLIES TO ALL CAUSES OF ACTION IN THE AGGREGATE, INCLUDING WITHOUT LIMITATION TO BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, AND OTHER TORTS.
                      9.0 Disclaimer of Warranty.
                      9.1 THE WARRANTIES CONTAINED HEREIN ARE THE ONLY WARRANTIES MADE BY THE PARTIES HEREUNDER. EACH PARTY MAKES NO OTHER WARRANTY, WHETHER EXPRESS OR IMPLIED, AND EXPRESSLY EXCLUDES AND DISCLAIMS ALL OTHER WARRANTIES AND REPRESENTATIONS OF ANY KIND, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. COMPANY DOES NOT PROVIDE ANY WARRANTY THAT OPERATION OF ANY SERVICES HEREUNDER WILL BE UNINTERRUPTED OR ERROR-FREE.
                      10.0 Indemnification.
                      10.1 The Contractor agrees to indemnify and hold harmless the Company, its affiliates, and its respective officers, directors, agents and employees from any and all claims, demands, losses, causes of action, damage, lawsuits, judgments, including attorneys’ fees and costs, arising out of, or relating to, the Contractor’s services under this Agreement. This provision shall survive the duration of this Agreement.
                      10.2 The Contractor agrees to defend against any and all claims, demands, causes of action, lawsuits, and/or judgments arising out of, or relating to, the Contractor’s services under this Agreement, unless expressly stated otherwise by the Company, in writing.
                      11.0 Waiver of Rights.
                      11.1 A failure or delay in exercising any right, power or privilege in respect of this Agreement will not be presumed to operate as a waiver, and a single or partial exercise of any right, power or privilege will not be presumed to preclude any subsequent or further exercise, of that right, power or privilege or the exercise of any other right, power or privilege.
                      IN WITNESS WHEREOF, the Parties, intending to be legally bound, have each executed this agreement as of the Effective Date.
                      `}
            </Text>
          </ScrollView>
        </Surface>
      </Card.Content>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        {checkAgree ? (
          <MaterialIcons
            name={"check-circle"}
            color={"blue"}
            size={30}
            onPress={() => {
              setCheckAgree(!checkAgree);
            }}
          />
        ) : (
          <FontAwesome
            name={"circle-thin"}
            color={"blue"}
            size={28}
            onPress={() => {
              setCheckAgree(!checkAgree);
            }}
          />
        )}
        <Text style={{ marginLeft: 10 }}>
          I agree to all terms of the agreement displayed above.{" "}
        </Text>
      </View>
      <Card.Content
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        <TouchableOpacity onPress={goBack} style={styles.button}>
          <Text style={{ color: "white" }}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={props.getState().handleSubmit(() => {
            console.log("form data");
            checkAgree ? onSave() : alert("please agree the agreement");
          })}
        >
          <Text style={{ color: "white" }}>Submit</Text>
        </TouchableOpacity>
      </Card.Content>
      {loading && (
        <Portal theme={{ colors: { backdrop: "rgba(0, 0, 0, 0.6)" } }}>
          <Modal
            visible={loadingPage}
            dismissable={false}
            onDismiss={() => setLoadingPage(false)}
            contentContainerStyle={containerStyle}
          >
            <ActivityIndicator size={40} />
          </Modal>
        </Portal>
      )}
    </Card>
  );
};

export default Agreement;

const styles = StyleSheet.create({
  button: {
    backgroundColor: "blue",
    borderWidth: 1,
    borderColor: "transparent",
    padding: 10,
    borderRadius: 5,
    width: "30%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});
