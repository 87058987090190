import React, {useState} from 'react';
import { Text, View, StyleSheet, Image } from 'react-native';
import { Card, Title, Paragraph, Avatar,IconButton } from 'react-native-paper';

const styles = StyleSheet.create({
    card: {
        marginTop: 20,
    },
    title: {
        top: 20,
        left: 100,
        fontSize: 24,
    },
    image: {
        height: 100,
    },
    action: {
        backgroundColor: 'black',
        color: 'white',
        paddingBottom: 5,
        paddingTop: 5,
    },
    icon: {
        position: 'absolute',
        top: 10,
        left: 10,
        color: 'white',
        backgroundColor: 'rgba(255,255,255,0)',
    },
    jobContainer: {
        margin: 10
    },
    jobName: {
        fontSize: 16,
        margin: 2
    }
});

const JobItem = (props) => {
    let _that=props;
    return (
        <View style={styles.jobContainer}>
            <Card>
                <Card.Title
                    title={`${props.item.title} `}
                    subtitle={`Client: ${props.item.client} |  Prime Vendor: ${props.item.primeVendor}  |  Ecorfy Rate: $${props.item.ecorfyRate} | Position: ${props.item.positionType}`} 
                    left={(props) => <Avatar.Text {...props} color={'white'} size={48} label={_that.item?.positionType.charAt(0)} />}
                    right={(props) => <IconButton {...props} icon="arrow-right" onPress={() => {
                       _that.onJobItemPressed(_that.item);
                    }} />}
                />
            </Card>
        </View>
    )
}

export default JobItem;