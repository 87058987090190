import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CreateCourseFile from '../components/CreateCourseFile'

const CreateCourseFileScreen = (props) => {
    return <CreateCourseFile {...props} />
}

export default CreateCourseFileScreen

const styles = StyleSheet.create({})