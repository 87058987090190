import React, { useState } from "react";
import { Text, View, StyleSheet, Image, Pressable } from "react-native";
import { Card, Title, Paragraph, Avatar, IconButton } from "react-native-paper";

const ContactItem = (props) => {
    // console.log(props.item.contactType);
  let _that = props;
  //   const [selectedItems, setSelectedItems] = useState([]);

//   const deSelectItems = (item) => {
//     // if (selectedItems.includes(item.id)) {
//     //   console.log("yes");
//     //   const newListItems = selectedItems.filter(
//     //     (listItem) => listItem !== item.id
//     //   );
//     //   console.log(newListItems, "newListItems");
//     //   return setSelectedItems([...newListItems]);
//     // }
//     // setSelectedItems([]);

//   };

  const selectItems = (item) => {
    console.log(item.id, "item", props.slectedItemsList(item));
    // if (selectedItems.includes(item.id)) {
    //   console.log("yes");
    //   const newListItems = selectedItems.filter(
    //     (listItem) => listItem !== item.id
    //   );
    //   return setSelectedItems([...newListItems]);
    // }
    // console.log(selectedItems);
    // setSelectedItems([...selectedItems, item.id]);
    return props.slectedItemsList(item);
  };

  return (
    <View style={styles.contactContainer}>
      <Pressable
        onPress={() => {
          selectItems(props.item);
        }}
      >
        <Card
          style={[props.selectedItems.includes(props.item.id) && styles.select]}
        >
          <Card.Title
            title={`${props.item.firstName} ${props.item.lastName}`}
            subtitle={`${props.item.email} | ${props.item.phone}`}
            left={(props) => (
              <Avatar.Text
                {...props}
                color={"white"}
                size={48}
                label={_that.item?.contactType.charAt(0)}
              />
            )}
            right={(props) => (
              <IconButton
                {...props}
                icon="arrow-right"
                onPress={() => {
                  _that.onContactItemPressed(_that.item);
                }}
              />
            )}
          />
        </Card>
      </Pressable>
    </View>
  );
};

export default ContactItem;
const styles = StyleSheet.create({
  card: {
    marginTop: 20,
  },
  title: {
    top: 20,
    left: 100,
    fontSize: 24,
  },
  image: {
    height: 100,
  },
  action: {
    backgroundColor: "black",
    color: "white",
    paddingBottom: 5,
    paddingTop: 5,
  },
  icon: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "white",
    backgroundColor: "rgba(255,255,255,0)",
  },
  contactContainer: {
    margin: 10,
  },
  contactName: {
    fontSize: 16,
    margin: 2,
  },
  select: {
    backgroundColor: "lightblue",
    color: "white",
  },
});
