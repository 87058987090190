import React, { useState, useEffect } from "react";

import {
  View,
  StyleSheet,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Alert,
} from "react-native";

import {
  DataTable,
  Searchbar,
  Button,
  IconButton,
  Portal,
  Paragraph,
  Dialog,
  TouchableRipple,
  Avatar,
  Card,
  Text,
  Surface,
  FAB
} from "react-native-paper";

import FileItem from "../../../global/components/FileItem";

import { useFocusEffect } from '@react-navigation/native';

import Amplify, { API } from "aws-amplify";
import awsconfig from "../../../aws-exports";

import { getContact } from '../../../graphql/queries';

API.configure(awsconfig);


const ContactFiles = (props) => {

  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState({});
  const [contactFiles, setContactFiles] = useState([]);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchContact();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const fetchContact = async () => {

    await API.graphql({
      query: getContact,
      variables: {
        id: props ? props.contact.id : contact.id
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
      .then(async (res) => {
        // console.log(res)
        setContact(res.data.getContact);
        setContactFiles(res.data.getContact.contactFiles)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  }

  useEffect(() => {
    setContact(props.contact)
    // console.log(props.contact.contactFiles,"contactFiles")
    setContactFiles(props.contact.contactFiles)
  }, [props])

  const renderItem = ({ item }) => {
    return (
      <FileItem item={item} />
    );
  };

  return (
    <Card style={{ flex: 1, marginTop: 10 }}>
      <Card.Content style={{
        flexDirection: 'row', justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 20,
        paddingHorizontal: 0
      }}>

        <FlatList
          data={contactFiles}
          renderItem={renderItem}
        />
      </Card.Content>

      <FAB
        icon="plus"
        style={styles.fab}
        onPress={() => props.navigation.navigate("CreateContactFile", { contact })}
      />
    </Card>
  )
}

export default ContactFiles;

const styles = StyleSheet.create({
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: 'blue'
  },
})
