import React, { useEffect, useState } from "react";

import * as Font from "expo-font";
import { View, Image, StyleSheet, AppState, Alert } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { ToastProvider } from "react-native-toast-notifications";

import { Appbar } from "react-native-paper";
import UserInactivity from "react-native-user-inactivity";

import { NavigationContainer } from "@react-navigation/native";

import { theme } from "./theme";

import { Provider } from "react-redux";
import { store } from "./src/redux_esuite";
import RootStack from "./src/RootStack";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Amplify, { Auth, DataStore, AuthModeStrategyType } from "aws-amplify";
import config from "./src/aws-exports";

import { ExpoSQLiteAdapter } from "@aws-amplify/datastore-storage-adapter/ExpoSQLiteAdapter";
import * as Linking from "expo-linking";

DataStore.configure({
  storageAdapter: ExpoSQLiteAdapter,
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
});

Auth.configure(config);

const Header = (props) => {
  const [visible, setVisible] = React.useState(false);

  let logo = require("./src/global/components/Logo");

  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  const _goBack = () => props.navigation.goBack();

  const onSignOut = () => {
    props.navigation.navigate("Login");
  };

  return (
    <Appbar.Header>
      <Appbar.Action
        icon="menu"
        onPress={() => {
          navigation.toggleDrawer();
        }}
      ></Appbar.Action>
      <Appbar.Content
        title={
          <Image
            source={require("./assets/images/logo.png")}
            style={{ width: 90, height: 20 }}
          />
        }
      ></Appbar.Content>
      <Appbar.Content title="E-Suite" />
    </Appbar.Header>
  );
};

const App = (props) => {
  // console.log(props, "props");
  const [timer, setTimer] = useState(60000 * 30);
  const [inActivity, setInActivity] = React.useState(false);
  const [active, setActive] = useState(true);
  const [view, setView] = useState("login");

  const myRef = React.useRef();

  // import { useDispatch } from "react-redux";

  // const dispatch = useDispatch()

  // dispatch(setView(queryParams.view));

  let loadFonts = async () => {
    await Font.loadAsync({
      "open-sans-bold": require("./assets/fonts/OpenSans-Bold.ttf"),
    });
  };

  const initialCall = async () => {
    let initialUrl = await Linking.getInitialURL();
    let { queryParams } = Linking.parse(initialUrl);
    setView(queryParams.view);
    loadFonts();
  };
  useEffect(() => {
    initialCall()
  }, []);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (nextAppState === "active") {
        // getTimer();
        // console.log("App has come to the foreground!");
      }
    });
    return () => {
      subscription && subscription.remove();
    };
  }, []);

  const getTimer = async (t) => {
    // console.log("getrimere", t);
    let time = await AsyncStorage.getItem("inactivity");
    // console.log(time, "time");
    if (time) {
      let parsed = JSON.parse(time);
      let newTime = new Date();
      let diff = newTime - new Date(parsed);
      let timediff = 60000 * 30;
      // console.log(time, timediff, diff, "time1", parsed);

      if (diff && diff > timediff) {
        // console.log("yes");
        alert("You have been logged out due to inactivity");
        await AsyncStorage.setItem("inactivity", JSON.stringify(new Date()));
        Auth.signOut().then(async (res) => {
          await AsyncStorage.setItem("inactivity", JSON.stringify(new Date()));

          myRef.current?.resetRoot({
            index: 0,
            routes: [{ name: "AccountStack" }],
          });
          setActive(false);
          setInActivity(true);
        });
      } else {
        await AsyncStorage.setItem("inactivity", JSON.stringify(new Date()));
      }
    } else {
      await AsyncStorage.setItem("inactivity", JSON.stringify(new Date()));
    }
  };

  return (
    <Provider store={store}>
      <ToastProvider>
        <UserInactivity
          isActive={active}
          timeForInactivity={timer}
          onAction={async (isActive) => {
            // console.log("isActive", isActive);
            if (!isActive) {
              getTimer("user inactivity");
            }
          }}
          style={{ flex: 1 }}
        >
          <PaperProvider theme={theme}>
            <NavigationContainer ref={myRef}>
              <RootStack view={view} />
            </NavigationContainer>
          </PaperProvider>
        </UserInactivity>
      </ToastProvider>
    </Provider>
  );
};

export default App;
