import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Student from '../components/Student'

const StudentOnboardingScreen = (props) => {
  return (
   <Student {...props} />
  )
}

export default StudentOnboardingScreen

const styles = StyleSheet.create({})