import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  Button,
  IconButton,
  Paragraph,
  Surface,
  Title,
  TextInput,
  HelperText,
  Menu,
  Divider,
} from "react-native-paper";
import Header from "../../../global/components/Header";
import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { updateCourse } from "../../../graphql/mutations";

import awsconfig from "../../../aws-exports";

import { useSelector } from "react-redux";

API.configure(awsconfig);

const CreateCourseNote = (props) => {
  console.log(props.route.params.course);
  let courseData = props.route.params.course;

  let [Course, setCourse] = useState({});
  let [user, setUser] = useState({});

  useEffect(async () => {
    const user = await Auth.currentAuthenticatedUser();
    console.log(user, "user");
    setUser(user);
    setCourse(props?.route?.params?.course);
  }, [props]);

  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      note: "",
    },
    mode: "onChange",
  });

  const onSave = async (data) => {
    console.log(user);
    let courseNote = {
      message: data.note,
      userName: user.username,
    };
    await API.graphql({
      query: updateCourse,
      variables: {
        input: {
          id: Course.id,
          courseNotes: [...Course.courseNotes, courseNote],
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        console.log(res, "res");
        alert("New Note has been added successfully.");
        props.navigation.goBack();
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"New Note"}
        showBack={true}
        leftType={"name"}
        leftName={"Save"}
        onPress={handleSubmit((data) => {
          console.log(data, "data");
          onSave(data);
        })}
      />
      <Card.Content style={{ marginTop: 30 }}>
        <Text>{"Note"}</Text>
        <Controller
          control={control}
          name="note"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              multiline={true}
              placeholder="Enter Note"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              style={{ height: 100 }}
              outlineColor={errors.note && "#fc4c4c"}
              activeOutlineColor={errors.note && "#fc4c4c"}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Note is required",
            },
          }}
        />
        <Card.Content>
          {errors.note?.message ? (
            <Text
              style={{ color: "#fc4c4c", fontSize: 12, paddingVertical: 2 }}
            >
              {errors.note?.message}
            </Text>
          ) : null}
        </Card.Content>
      </Card.Content>
    </Card>
  );
};

export default CreateCourseNote;

const styles = StyleSheet.create({});
