import { StyleSheet, Text, View, ScrollView, TouchableOpacity } from 'react-native'

import React, { useState, useEffect } from 'react'
import {
    Card, List, Button,
    IconButton,
    Paragraph,
    Surface,
    Title,
    TextInput, HelperText, Menu, Divider
} from 'react-native-paper';

import Header from "../../../global/components/Header";
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm, useController, Controller } from 'react-hook-form';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import { createJob } from "../../../graphql/mutations";
import { listContacts } from "../../../graphql/queries";


import { useSelector } from 'react-redux'

import { addNotification } from "../../../../notifications";

import awsconfig from "../../../aws-exports";

API.configure(awsconfig);

const CreateJob = (props) => {
    const [expanded, setExpanded] = React.useState(true);
    const [vendors, setVendors] = useState([])
    const [menuValue, setMenuValue] = useState('');
    const [menuvisible, setMenuVisible] = useState(false);

    const loggedinUser = useSelector((state) => state.login.user);
    let [user, setUser] = useState(loggedinUser);


    const { control, setFocus, handleSubmit, formState: { errors }, getValues } = useForm({
        defaultValues: {
            status: "",
            title: "",
            vendor: '',
            client: '',
            primeVendor: '',
            ecorfyRate: "",
            positionType: '',
            location: '',
            description: ""
        },
        mode: 'onChange',
    });

    useEffect(() => {
        console.log('vendors')
        fetchVendors();
    }, []);

    const fetchVendors = async () => {
        await API.graphql({
            query: listContacts,
            variables: {
                filter: {
                    contactType: {
                        eq: 'VENDOR'
                    }
                }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(async (res) => {
                console.log(res)
                setVendors(res.data.listContacts.items);
            })
            .catch((err) => {
                console.log(err, "errr");
            });
    };

    const onSave = async (data) => {
        console.log(data)

        let job = {
            status: data.status,
            title: data.title,
            jobVendorId: data.vendor,
            client: data.client,
            primeVendor: data.primeVendor,
            ecorfyRate: data.ecorfyRate,
            positionType: data.positionType,
            location: data.location,
            description: data.description
        };
        // console.log(updateUserObj);

        await API.graphql({
            query: createJob,
            variables: { input: job },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(async (res) => {
                console.log(res, "res");

                addNotification({
                    notificationType: 'New Job',
                    entityType: 'JOB',
                    entityId: res.data.createJob.id,
                    entityName: job.title,
                    actorId: user.username,
                    actorName: user.firstName,
                    message: `${user.firstName} posted new job ${job.title}`
                })

                alert("New Job has been added successfully.")
                props.navigation.goBack()
            })
            .catch((err) => {
                console.log(err, "errr");
            })
    }

    return (
        <Card style={{ flex: 1 }}>
            <Header
                {...props}
                route={props.route}
                navigation={props.navigation}
                children={"Add Job"}
                leftIcon={"plus"}
                routename={"AddContact"}
                showBack={true}
                leftType={"name"}
                leftName={"Save"}
                onPress={
                    handleSubmit((data) => {
                        onSave(data);
                    })
                }
            />
            <Card.Content style={{ paddingHorizontal: 0, flex: 1, marginBottom: 30 }}>
                {/* <List.AccordionGroup > */}
                <List.Accordion title="Basic Info" id="1" titleStyle={{
                    textAlign: 'center',
                }}
                // expanded={expanded}
                >
                    <ScrollView contentContainerStyle={styles.scrollViewStyle}>
                        <Text>{"Status"}</Text>
                        <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            defaultValue={'Active'}
                            formConfigArray={[
                                {
                                    name: 'status',
                                    type: 'select',
                                    textInputProps: {
                                        placeholder: 'Select Status',
                                        //   label: 'City',
                                        //   left: <TextInput.Icon name={'office-building'} />,
                                    },
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'status is required',
                                        },
                                    },
                                    options: [
                                        {
                                            label: 'Active',
                                            value: 'Active',
                                        },
                                        {
                                            label: 'On-Hold',
                                            value: 'On-Hold',
                                        },
                                        {
                                            label: 'Closed',
                                            value: 'Closed',
                                        },
                                    ],
                                },
                            ]}
                        />
                        <Text>{"Title"}</Text>
                        <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            formConfigArray={[
                                {
                                    type: 'text',
                                    name: 'title',
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'Title is required',
                                        },
                                    },
                                    textInputProps: {
                                        // label: 'Please Enter FirstName',
                                        placeholder: 'Enter Title',
                                    },
                                },
                            ]}
                        />
                        <Text>{"Vendor"}</Text>
                        <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            formConfigArray={[
                                {
                                    name: 'vendor',
                                    type: 'select',
                                    textInputProps: {
                                        placeholder: 'Select Vendor'
                                    },
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'Vendor is required',
                                        },
                                    },
                                    options: vendors.map((vendor) => {
                                        let vendorName = `${vendor.firstName}, ${vendor.lastName}`;
                                        return {
                                            label: vendorName,
                                            value: vendor.id
                                        }
                                    })
                                },
                            ]}
                        />
                        {errors.vendor?.message ?
                            <Text style={{
                                color: '#fc4c4c', fontSize: 12,
                                paddingVertical: 2, paddingHorizontal: 10,
                                marginBottom: errors.vendor && 10
                            }}>
                                {errors.vendor?.message}
                            </Text> : null}

                        {/* <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            formConfigArray={[
                                {
                                    name: 'vendor',
                                    type: 'select',
                                    textInputProps: {
                                        //   label: 'City',
                                        //   left: <TextInput.Icon name={'office-building'} />,
                                        placeholder: 'Select Vendor'
                                    },
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'Vendor is required',
                                        },
                                    },
                                    options: [

                                        contactsList.map(item => (
                                            {
                                                label: item.firstName,
                                                value: 'Close',
                                            }
                                        )
                                        ),
                                        // value: 'Close',

                                        // contactsList.map((item) => (
                                        //      ({
                                        //         label: '`${item.firstName} ${item.lastName}`',
                                        //         value: '`${item.firstName} ${item.lastName}`',
                                        //     },
                                        //     {
                                        //         label: 'Close',
                                        //         value: 'Close',
                                        //     })
                                        // )
                                        //     // console.log(item.firstName,"....")

                                        // )

                                    ],
                                },
                            ]}
                        /> */}
                        <Text>{'Client'}</Text>
                        <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            formConfigArray={[
                                {
                                    type: 'text',
                                    name: 'client',
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'Client is required',
                                        },
                                        // pattern: {
                                        //     value:
                                        //         /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                                        //     message: 'Email is invalid',
                                        // },
                                    },
                                    textInputProps: {
                                        // label: 'Please Enter FirstName',
                                        placeholder: 'Enter Client Name',
                                    },
                                }
                            ]}
                        />
                        <Text>{'Prime Vendor'}</Text>
                        <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            formConfigArray={[
                                {
                                    type: 'text',
                                    name: 'primeVendor',
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'Prime Vendor is required',
                                        },
                                        // pattern: {
                                        //     value:
                                        //         /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                                        //     message: 'Email is invalid',
                                        // },
                                    },
                                    textInputProps: {
                                        // label: 'Please Enter FirstName',
                                        placeholder: 'Enter Prime Vendor',
                                    },
                                }
                            ]}
                        />
                        <Text>{"Ecorfy Rate"}</Text>
                        <Controller
                            control={control}
                            name='ecorfyRate'
                            render={({ field: { onChange, value, onBlur } }) => (
                                <TextInput keyboardType="number-pad" mode="outlined"
                                    // onFocus={()=>{setFocus(true)}}
                                    defaultValue={value}
                                    placeholder="Please Enter Ecorfy Rate"
                                    onBlur={onBlur}
                                    onChangeText={(text) => {
                                        console.log(text, "text");
                                        onChange(text);
                                    }}
                                    outlineColor={errors.ecorfyRate && '#fc4c4c'}
                                    activeOutlineColor={errors.ecorfyRate && '#fc4c4c'}
                                    style={{ marginBottom: !errors.ecorfyRate && 20 }}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Ecorfy Rate is required'
                                },
                            }}
                        />
                        {errors.ecorfyRate?.message ?
                            <Text style={{
                                color: '#fc4c4c', fontSize: 12,
                                paddingVertical: 2, paddingHorizontal: 10,
                                marginBottom: errors.ecorfyRate && 20
                            }}>
                                {errors.ecorfyRate?.message}
                            </Text> : null}
                        {/* <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            formConfigArray={[
                                {
                                    name: 'ecorfyRate',
                                    type: 'text',
                                    textInputProps: {
                                        placeholder: 'Enter Ecorfy Rate',
                                        // left:<TextInput.Icon name={'account'} />
                                        //   label: 'City',
                                        //   left: <TextInput.Icon name={'office-building'} />,
                                    },
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'Ecorfy Rate is required',
                                        },
                                    },
                                },
                            ]}
                        /> */}
                        <Text>{"PositionType"}</Text>
                        <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            formConfigArray={[
                                {
                                    name: 'positionType',
                                    type: 'select',
                                    textInputProps: {
                                        //   label: 'City',
                                        //   left: <TextInput.Icon name={'office-building'} />,
                                        placeholder: 'Select... '
                                    },
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'PositionType is required',
                                        },
                                    },
                                    options: [
                                        {
                                            label: 'Onsite',
                                            value: 'Onsite',
                                        },
                                        {
                                            label: 'Offshore',
                                            value: 'Offshore',
                                        },
                                        {
                                            label: 'Remote',
                                            value: 'Remote',
                                        },
                                        {
                                            label: 'Hybrid',
                                            value: 'Hybrid',
                                        },
                                    ],
                                },
                            ]}
                        />

                        <Text>{"Location"}</Text>
                        <FormBuilder
                            control={control}
                            setFocus={setFocus}
                            formConfigArray={[
                                {
                                    type: 'text',
                                    name: 'location',
                                    rules: {
                                        required: {
                                            value: true,
                                            message: 'Location is required',
                                        },
                                    },
                                    textInputProps: {
                                        // label: 'Please Enter FirstName',
                                        placeholder: 'Enter Job Location (e.g Austin, TX)',
                                        // multiline: true,

                                    },
                                },
                            ]}
                        />

                    </ScrollView>
                </List.Accordion>
                <List.Accordion title="Role Info" id="2" titleStyle={{
                    textAlign: 'center',
                }}>
                    <ScrollView contentContainerStyle={styles.scrollViewStyle}>
                        <Text>{"Description"}</Text>
                        <Controller
                            control={control}
                            name='description'
                            render={({ field: { onChange, value, onBlur } }) => (
                                <TextInput mode="outlined"
                                    multiline={true}
                                    // onFocus={()=>{setFocus(true)}}
                                    defaultValue={value}
                                    placeholder="Enter Job Description"
                                    onBlur={onBlur}
                                    onChangeText={(text) => {
                                        onChange(text);
                                    }}
                                    style={{ height: 100 }}
                                    outlineColor={errors.description && '#fc4c4c'}
                                    activeOutlineColor={errors.description && '#fc4c4c'}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Description is required'
                                },
                                // pattern: {
                                //     value: /^((\\+91-?)|0)?[0-9]{10}$/,
                                //     message: 'phone Number is invalid',
                                // }
                            }}
                        />
                        <Card.Content style={{ justifyContent: 'space-between', flexDirection: 'row', paddingHorizontal: 0 }}>
                            <Card.Content>
                                {errors.description?.message ?
                                    <Text style={{ color: '#fc4c4c', fontSize: 12, paddingVertical: 2, }}>
                                        {errors.description?.message}
                                    </Text> : null}
                            </Card.Content>
                        </Card.Content>
                    </ScrollView>
                </List.Accordion>
                {/* </List.AccordionGroup> */}

            </Card.Content>
        </Card>
    )
}

export default CreateJob

const styles = StyleSheet.create({
    scrollViewStyle: {
        // flex: 1,
        padding: 15,
        justifyContent: 'center',
    },

    text: {
        fontSize: 16,
        width: '65%',
    },
    menuContainer: {
        marginVertical: 20,
    },
    menuContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        borderWidth: 1,
    },
    label: {
        fontSize: 14,
        marginBottom: 2,
        marginTop: 20,
        fontWeight: '500',
        textAlign: 'center',
    },
})