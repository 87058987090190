import React, { memo, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";

import Logo from "../../../global/components/Logo";
import { theme } from "../../../../theme";
import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import { Card, ActivityIndicator, Surface } from "react-native-paper";
import AnimatedFormView from "../../../account/components/AnimatedFormView";
import { Auth, API, graphqlOperation, DataStore, Storage } from "aws-amplify";

import config from "../../../aws-exports";

import IntlPhoneField from "react-native-intl-phone-field";

import UploadDocs from "./UploadDocs";
import Agreement from "./Agreement";

API.configure(config);
DataStore.configure(config);
Storage.configure(config);

const Onboarding = (props) => {
  const toast = useToast();
  const [contact, setContact] = useState({
    firstName: "",
    phone: "",
    email: "",
    lastName: "",
    userId: "",
  });

  const [checked, setChecked] = useState({
    firstName: false,
    phone: false,
    email: false,
    lastName: false,
  });
  const StepOne = (data) => {
    // console.log(data, props, "propsstep");
    const {
      control,
      setFocus,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm({
      defaultValues: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        contactType: null,
        filePurpose: "",
        fileName: "",
      },
      mode: "onChange",
    });

    const nextStep = (datas) => {
      const { next, saveState } = data;
      next();
      saveState({
        stepOneData: datas,
        handleSubmit: handleSubmit,
        navigation: props.navigation,
      });
    };

    return (
      <Card style={{ flex: 1, backgroundColor: "transparent" }}>
        <ScrollView>
          <View style={{ flex: 1, marginVertical: 20 }}>
            <Text
              variant="displayLarge"
              style={{ fontSize: 24, marginBottom: 20, fontWeight: "bold" }}
            >
              Personal Information
            </Text>
            <Text>{"First Name (Given Name)"}</Text>
            <FormBuilder
              control={control}
              setFocus={setFocus}
              formConfigArray={[
                {
                  type: "text",
                  name: "firstName",
                  rules: {
                    required: {
                      value: true,
                      message: "First Name is required",
                    },
                    minLength: {
                      value: 3,
                      message: "First Name should be atleast 3 characters",
                    },
                  },
                  textInputProps: {
                    placeholder: "Please Enter First Name",
                  },
                },
              ]}
            />
            <Text>{"Last Name (Sur Name)"}</Text>
            <FormBuilder
              control={control}
              setFocus={setFocus}
              formConfigArray={[
                {
                  type: "text",
                  name: "lastName",
                  rules: {
                    required: {
                      value: true,
                      message: "Last Name is required",
                    },
                  },
                  textInputProps: {
                    placeholder: "Please Enter Last Name",
                  },
                },
              ]}
            />
            <Text>{"Email"}</Text>
            <FormBuilder
              control={control}
              setFocus={setFocus}
              formConfigArray={[
                {
                  type: "email",
                  name: "email",
                  rules: {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value:
                        /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                      message: "Email is invalid",
                    },
                  },
                  textInputProps: {
                    placeholder: "Please Enter Email",
                  },
                },
              ]}
            />

            <Text>{"Phone Number"}</Text>
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value, onBlur } }) => (
                <IntlPhoneField
                  onEndEditing={(result) => onChange(result.value)}
                  // onValidation={(isValid) => console.log(isValid)}
                  defaultCountry="IN"
                  defaultPrefix="+91"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Phone Number is required",
                },
                pattern: {
                  value: !!/^\+?\d[\d\s-]+$/,
                  message: "Phone Number is invalid",
                },
              }}
            />
            {errors["phone"]?.message ? (
              <Text
                style={{
                  color: "#fc4c4c",
                  fontSize: 12,
                  paddingVertical: 2,
                  paddingHorizontal: 10,
                }}
              >
                {errors["phone"]?.message}
              </Text>
            ) : null}
          </View>
          <Card.Content
            style={{ flexDirection: "row", justifyContent: "flex-end" }}
          >
            <TouchableOpacity
              onPress={handleSubmit((data) => {
                console.log("form data", data);
                nextStep(data);
                setContact(data);
              })}
              style={styles.button}
            >
              <Text style={{ color: "white" }}>Next</Text>
            </TouchableOpacity>
          </Card.Content>
        </ScrollView>
      </Card>
    );
  };

  return (
    <Card.Content style={styles.container}>
      <Card.Content style={{ marginTop: 10, marginBottom: 20 }}>
        <Logo />
        <Text
          variant="displayLarge"
          style={{ fontSize: 32, justifyContent: "center" }}
        >
          Recruiter Onboarding
        </Text>
      </Card.Content>
      <AnimatedFormView
        steps={[
          { name: "step 1", component: StepOne },
          { name: "step 2", component: UploadDocs },
          { name: "step 3", component: Agreement },
          // { name: "step 4", component: Step4 },
        ]}
        // onBack={onBack}
        // onNext={onNext}
        // comeInOnNext="slideInRight"
        // OutOnNext="bounceOutDown"
        // comeInOnBack="bounceInDown"
        // OutOnBack="bounceOutUp"
      />
    </Card.Content>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    marginTop: 4,
  },
  label: {
    color: theme.colors.secondary,
    marginTop: 20,
  },
  button: {
    backgroundColor: "blue",
    borderWidth: 1,
    borderColor: "transparent",
    padding: 10,
    borderRadius: 5,
    width: "30%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default memo(Onboarding);
