import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Courses from '../components/Courses'

const CoursesScreen = (props) => {
  return (
    <Courses {...props} />
  )
}

export default CoursesScreen

const styles = StyleSheet.create({})