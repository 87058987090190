import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Contacts from '../components/Contacts';

const ContactsScreen = (props) => {
  return (
    <Contacts {...props} />
  )
}

export default ContactsScreen

const styles = StyleSheet.create({})