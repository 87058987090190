import { configureStore, createSlice } from '@reduxjs/toolkit'

const initialState={
    user:null,
    view:'login'
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
      setUser: (state, action) => {
        state.user = action.payload;
      },
      setView:(state,action)=>{
        state.view=action.payload;
      }
    },
  })

export const { setUser, setView } = loginSlice.actions

export const store = configureStore({
  reducer: {
      login:loginSlice.reducer
  },
})
