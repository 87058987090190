import React, {useState} from 'react';
import { Text, View, StyleSheet, Image } from 'react-native';
import { Card, Title, Paragraph, Avatar,IconButton } from 'react-native-paper';

const styles = StyleSheet.create({
    card: {
        marginTop: 20,
    },
    title: {
        top: 20,
        left: 100,
        fontSize: 24,
    },
    image: {
        height: 100,
    },
    action: {
        backgroundColor: 'black',
        color: 'white',
        paddingBottom: 5,
        paddingTop: 5,
    },
    icon: {
        position: 'absolute',
        top: 10,
        left: 10,
        color: 'white',
        backgroundColor: 'rgba(255,255,255,0)',
    },
    itemContainer: {
        margin: 10
    },
    itemName: {
        fontSize: 16,
        margin: 2
    }
});

const CourseItem = (props) => {
    let _that=props;
    return (
        <View style={styles.itemContainer}>
            <Card>
                <Card.Title
                    title={`${_that.item.title}`}
                    subtitle={_that.item.description} 
                    left={(props) => <Avatar.Text {...props} color={'white'} size={48} label={"C"} />}
                    right={(props) => <IconButton {...props} icon="arrow-right" onPress={() => {
                       _that.onItemPressed(_that.item);
                    }} />}
                />
            </Card>
        </View>
    )
}

export default CourseItem;