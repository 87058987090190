import React, { memo, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";

import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import { Card, ActivityIndicator, Surface } from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";
import { Auth, API, graphqlOperation, DataStore, Storage } from "aws-amplify";

import config from "../../../aws-exports";

import IntlPhoneField from "react-native-intl-phone-field";

API.configure(config);
DataStore.configure(config);
Storage.configure(config);

const ProfessionalInformation = (props) => {
  console.log(props.getState(), "propsstep");
  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      university: "",
      degree: "",
      yearJoined: "",
      linkedInUrl: "",
      currentVisaStatus: "",
      interestedPlatform: "",
    },
    mode: "onChange",
  });
  const [contactResumeFile, setContactResumeFile] = useState({
    fileName: "",
    fileType: "",
    filePurpose: "resume",
    blobData: null,
    contactId: null,
  });
  const attachResume = async () => {
    // Ask the user for the permission to access the media library
    const result = await DocumentPicker.getDocumentAsync({
      type: "application/*",
    });
    const fileName = result.name;
    const fileType = result.mimeType;

    const fileData = await fetch(result.uri);
    const blobData = await fileData.blob();

    let contactFile = {
      fileName,
      fileType,
      blobData,
      filePurpose: "resume",
    };

    console.log(contactFile);

    setContactResumeFile(contactFile);
  };
  const nextStep = (datas) => {
    const { next, saveState } = props;
    if (contactResumeFile.fileName === "") {
      alert("Resume is required. Please upload.");
      return;
    }
    next();
    saveState({
      professionalInformation: datas,
      resume: contactResumeFile,
    });
  };
  const goBack = () => {
    const { back } = props;
    // Go to previous step
    back();
  };
  return (
    <Card style={{ flex: 1, backgroundColor: "transparent" }}>
      <ScrollView>
        <View style={{ flex: 1, marginVertical: 20 }}>
          <Text
            variant="displayLarge"
            style={{ fontSize: 24, marginBottom: 20, fontWeight: "bold" }}
          >
            Personal Information
          </Text>
          <Text>{"University"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "text",
                name: "university",
                rules: {
                  required: {
                    value: true,
                    message: "University is required",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter University",
                },
              },
            ]}
          />
          <Text>{"Degree / Specialization"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "text",
                name: "degree",
                rules: {
                  required: {
                    value: true,
                    message: "Degree / Specialization is required",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Degree / Specialization",
                },
              },
            ]}
          />
          <Text>{"Year Joined"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "text",
                name: "yearJoined",
                rules: {
                  required: {
                    value: true,
                    message: "Year Joined is required",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Year Joined",
                },
              },
            ]}
          />
          <Text>{"Linkedin Url"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "text",
                name: "linkedInUrl",
                rules: {
                  required: {
                    value: true,
                    message: "Linkedin Url is required",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Linkedin Url",
                },
              },
            ]}
          />
          <Text>{"Current Visa Status "}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            defaultValue={"Please Select Current Visa Status"}
            formConfigArray={[
              {
                type: "select",
                name: "currentVisaStatus",
                rules: {
                  required: {
                    value: true,
                    message: "Current Visa Status  is required",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Current Visa Status ",
                },
                options: [
                  {
                    label: "F1",
                    value: "F1",
                  },
                  {
                    label: "OPT",
                    value: "OPT",
                  },
                  {
                    label: "CPT",
                    value: "CPT",
                  },
                ],
              },
            ]}
          />
          <Text>{"Interested Platform"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            defaultValue={"Please Select Platform"}
            formConfigArray={[
              {
                name: "interestedPlatform",
                type: "select",
                textInputProps: {
                  placeholder: "Please Enter Interested Platform",
                },
                rules: {
                  required: {
                    value: true,
                    message: "Interested Platform is required",
                  },
                },
                options: [
                  {
                    label: "Full Stack",
                    value: "Full Stack",
                  },
                  {
                    label: "DevOps",
                    value: "DevOps",
                  },
                  {
                    label: "QA",
                    value: "QA",
                  },
                  {
                    label: "BA",
                    value: "BA",
                  },
                  {
                    label: "Other",
                    value: "Other",
                  },
                  {
                    label: "Don't know",
                    value: "Don't know",
                  },
                ],
              },
            ]}
          />
          <Text
            variant="displayLarge"
            style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}
          >
            Upload Resume
          </Text>
          <Text>{"File Name"}</Text>
          <Text>{contactResumeFile.fileName}</Text>
          <Card.Content
            style={{
              paddingHorizontal: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                backgroundColor: "blue",
                borderWidth: 1,
                borderColor: "transparent",
                padding: 10,
                borderRadius: 5,
                width: "30%",
                height: 50,
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={attachResume}
            >
              <Text
                style={{ color: "white", fontSize: 14, fontWeight: "bold" }}
              >
                Attach File
              </Text>
            </TouchableOpacity>
          </Card.Content>
        </View>
        <Card.Content
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginVertical: 30,
          }}
        >
          <TouchableOpacity onPress={goBack} style={styles.button}>
            <Text style={{ color: "white" }}>Back</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={handleSubmit((data) => {
              console.log("form data", data);
              nextStep(data);
            })}
          >
            <Text style={{ color: "white" }}>Next</Text>
          </TouchableOpacity>
        </Card.Content>
      </ScrollView>
    </Card>
  );
};

export default ProfessionalInformation;

const styles = StyleSheet.create({
  button: {
    backgroundColor: "blue",
    borderWidth: 1,
    borderColor: "transparent",
    padding: 10,
    borderRadius: 5,
    width: "30%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});
