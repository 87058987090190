import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import React, { useState } from "react";
import * as DocumentPicker from "expo-document-picker";
import { Card, ActivityIndicator, Surface } from "react-native-paper";

const Documents = (props) => {
//   console.log(props.getState().navigation, "propsstep2");

  const [contactResumeFile, setContactResumeFile] = useState({
    fileName: props?.getState()?.stepTwoData
      ? props?.getState().stepTwoData[0].fileName
      : "",
    fileType: props?.getState()?.stepTwoData
      ? props?.getState().stepTwoData[0].fileType
      : "",
    filePurpose: "resume",
    blobData: null,
    contactId: null,
  });

  const [uploadVisaProof, setUploadVisaProof] = useState({
    fileName: props?.getState()?.stepTwoData
      ? props?.getState().stepTwoData[1].fileName
      : "",
    fileType: props?.getState()?.stepTwoData
      ? props?.getState().stepTwoData[1].fileType
      : "",
    filePurpose: "visa",
    blobData: null,
    contactId: null,
  });

  const [uploadIDProof, setUploadIDProof] = useState({
    fileName: props?.getState()?.stepTwoData
      ? props?.getState().stepTwoData[3].fileName
      : "",
    fileType: props?.getState()?.stepTwoData
      ? props?.getState().stepTwoData[3].fileType
      : "",
    filePurpose: "id",
    blobData: null,
    contactId: null,
  });
  const nextStep = () => {
    const { next, saveState } = props;

    if (contactResumeFile.fileName === "") {
      alert("Resume is required. Please upload.");
      return;
    }

    if (uploadVisaProof.fileName === "") {
      alert("Visa is required. Please upload.");
      return;
    }

    if (uploadIDProof.fileName === "") {
      alert("ID Proof is required. Please upload.");
      return;
    }
    next();

    saveState({
        Documents: [
        contactResumeFile,
        uploadVisaProof,
        uploadIDProof,
      ],
      navigation: props.getState().navigation,
    });
  };

  const goBack = () => {
    const { back, saveState } = props;
    // Go to previous step
    back();
    saveState({
      stepTwoData: [contactResumeFile, uploadVisaProof, uploadIDProof],
    });
  };
  const attachResume = async () => {
    // Ask the user for the permission to access the media library
    const result = await DocumentPicker.getDocumentAsync({
      type: "application/*",
    });
    const fileName = result.name;
    const fileType = result.mimeType;

    const fileData = await fetch(result.uri);
    const blobData = await fileData.blob();

    let contactFile = {
      fileName,
      fileType,
      blobData,
      filePurpose: "resume",
    };

    console.log(contactFile);

    setContactResumeFile(contactFile);
  };

  const attachVisa = async () => {
    // Ask the user for the permission to access the media library
    const result = await DocumentPicker.getDocumentAsync({
      type: ["image/*", "application/*"],
    });
    const fileName = result.name;
    const fileType = result.mimeType;

    const fileData = await fetch(result.uri);
    const blobData = await fileData.blob();

    let contactFile = {
      fileName,
      fileType,
      blobData,
      filePurpose: "visa",
    };

    console.log(contactFile);

    setUploadVisaProof(contactFile);
  };

  const attachIdProof = async () => {
    // Ask the user for the permission to access the media library
    const result = await DocumentPicker.getDocumentAsync({
      type: ["image/*", "application/*"],
    });
    const fileName = result.name;
    const fileType = result.mimeType;

    const fileData = await fetch(result.uri);
    const blobData = await fileData.blob();

    let contactFile = {
      fileName,
      fileType,
      blobData,
      filePurpose: "id",
    };

    console.log(contactFile);

    setUploadIDProof(contactFile);
  };

  return (
    <Card
      style={{
        flex: 1,
        marginTop: 5,
        marginBottom: 40,
        backgroundColor: "transparent",
      }}
    >
      <Text
        variant="displayLarge"
        style={{ fontSize: 24, fontWeight: "bold", marginBottom: 10 }}
      >
        Documents
      </Text>
      {/* <Text style={{ marginBottom: 15 }}>
        {
          "Do not submit any fake documents. All documents will be verified throughly before taking decision on your application. Images, PDF and doc/docx files are only allowed to upload."
        }
      </Text> */}
      <Text
        variant="displayLarge"
        style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}
      >
        Upload Resume
      </Text>
      <Text>{"File Name"}</Text>
      <Text>{contactResumeFile.fileName}</Text>
      <Card.Content
        style={{
          paddingHorizontal: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          style={{
            backgroundColor: "blue",
            borderWidth: 1,
            borderColor: "transparent",
            padding: 10,
            borderRadius: 5,
            width: "30%",
            height: 50,
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={attachResume}
        >
          <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>
            Attach File
          </Text>
        </TouchableOpacity>
      </Card.Content>
      <Text
        variant="displayLarge"
        style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}
      >
        Upload Visa
      </Text>
      <Text>{"File Name"}</Text>
      <Text>{uploadVisaProof.fileName}</Text>
      <Card.Content
        style={{
          paddingHorizontal: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          style={{
            backgroundColor: "blue",
            borderWidth: 1,
            borderColor: "transparent",
            padding: 10,
            borderRadius: 5,
            width: "30%",
            height: 50,
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={attachVisa}
        >
          <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>
            Attach File
          </Text>
        </TouchableOpacity>
      </Card.Content>
      <Text
        variant="displayLarge"
        style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}
      >
        Upload Id Proof
      </Text>
      <Text>{"File Name"}</Text>
      <Text>{uploadIDProof.fileName}</Text>
      <Card.Content
        style={{
          paddingHorizontal: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          style={{
            backgroundColor: "blue",
            borderWidth: 1,
            borderColor: "transparent",
            padding: 10,
            borderRadius: 5,
            width: "30%",
            height: 50,
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={attachIdProof}
        >
          <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>
            Attach File
          </Text>
        </TouchableOpacity>
      </Card.Content>

      <Card.Content
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 60,
        }}
      >
        <TouchableOpacity onPress={goBack} style={styles.button}>
          <Text style={{ color: "white" }}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={nextStep}>
          <Text style={{ color: "white" }}>Next</Text>
        </TouchableOpacity>
      </Card.Content>
    </Card>
  );
};

export default Documents;

const styles = StyleSheet.create({
  button: {
    backgroundColor: "blue",
    borderWidth: 1,
    borderColor: "transparent",
    padding: 10,
    borderRadius: 5,
    width: "30%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});
