import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Alert,
} from "react-native";
import { Paragraph, Card, Text, Surface, FAB } from "react-native-paper";
import { useFocusEffect } from "@react-navigation/native";
import { getJob } from "../../../graphql/queries";

import Amplify, { API } from "aws-amplify";
import awsconfig from "../../../aws-exports";

const JobNotes = (props) => {
//   console.log(props.job.id, "props");
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState({});
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    setJob(props.job);
    setNotes(props.job.jobNotes);
  }, [props]);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchJob();
      return () => {
        isActive = false;
      };
    }, [])
  );

  const fetchJob = async () => {
    await API.graphql({
      query: getJob,
      variables: {
        id: props ? props.job.id : job.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then(async (res) => {
        // console.log(res.data.getJob,"res...");
        setJob(res.data.getJob)
        setNotes(res.data.getJob.jobNotes);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };
  const renderItem = ({ item }) => {
    return (
      <Surface
        style={{
          backgroundColor: "white",
          flex: 1,
          marginVertical: 8,
          marginHorizontal: 16,
          elevation: 4,
        }}
      >
        <ScrollView>
          <Card.Content style={{ flexDirection: "row", paddingHorizontal: 0 }}>
            <Card.Content style={styles.titlePosition}>
              <Paragraph style={[styles.title, { color: "black" }]}>
                {item.message}
              </Paragraph>
              <Paragraph
                style={[
                  styles.discription,
                  {
                    color: "#696969",
                    textTransform: "capitalize",
                  },
                ]}
              >
                {item.userName}
              </Paragraph>
            </Card.Content>
          </Card.Content>
        </ScrollView>
      </Surface>
    );
  };

  return (
    <Card style={{ flex: 1 }}>
      <Card.Content>
        <FlatList data={notes} renderItem={renderItem} />
      </Card.Content>
      <FAB
        icon="plus"
        style={styles.fab}
        onPress={() => props.navigation.navigate("CreateJobNote", { job })}
      />
    </Card>
  );
};

export default JobNotes;

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: "blue",
  },
});
