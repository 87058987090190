/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContactRelation = /* GraphQL */ `
  query GetContactRelation($id: ID!) {
    getContactRelation(id: $id) {
      id
      relation
      relationContactID
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const listContactRelations = /* GraphQL */ `
  query ListContactRelations(
    $filter: ModelContactRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        relationContactID
        contactID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      name
      phone
      email
      extraData
      leadType
      leadSource
      verifiedEmail
      verifiedPhone
      unsubscribed
      assignedContact
      createdAt
      updatedAt
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        email
        extraData
        leadType
        leadSource
        verifiedEmail
        verifiedPhone
        unsubscribed
        assignedContact
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactCount = /* GraphQL */ `
  query GetContactCount($id: ID!) {
    getContactCount(id: $id) {
      id
      consultantCount
      submissionCount
      countDate
      contactID
      createdAt
      updatedAt
    }
  }
`;
export const listContactCounts = /* GraphQL */ `
  query ListContactCounts(
    $filter: ModelContactCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactCounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      notificationType
      entityType
      entityId
      entityName
      actorId
      actorName
      message
      notifiers
      status
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notificationType
        entityType
        entityId
        entityName
        actorId
        actorName
        message
        notifiers
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseLesson = /* GraphQL */ `
  query GetCourseLesson($id: ID!) {
    getCourseLesson(id: $id) {
      id
      title
      description
      courseID
      topics {
        title
        description
        videoUrl
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCourseLessons = /* GraphQL */ `
  query ListCourseLessons(
    $filter: ModelCourseLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        courseID
        topics {
          title
          description
          videoUrl
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubmission = /* GraphQL */ `
  query GetSubmission($id: ID!) {
    getSubmission(id: $id) {
      id
      job {
        id
        title
        vendor {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        client
        primeVendor
        positionType
        location
        ecorfyRate
        description
        additionalNotes
        status
        jobNotes {
          message
          userName
          createdAt
        }
        createdAt
        updatedAt
        jobVendorId
      }
      consultant {
        id
        userId
        firstName
        lastName
        phone
        email
        contactType
        email2
        email3
        phone2
        phone3
        middleName
        title
        jobTitle
        dob
        linkedinUrl
        githubUrl
        contactFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        contactNotes {
          message
          userName
          createdAt
        }
        contactTasks {
          message
          taskType
          priority
          userId
          taskStatus
        }
        shippingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        timezone
        leadSource
        gender
        openToWork
        primarySkill
        secondarySkill
        skillTags
        experience
        relocation
        whatsappId
        ssn
        employerName
        employerEmail
        employerPhone
        visaType
        contractType
        managerId
        consultantCount
        submissionCount
        deviceToken
        deviceOS
        university
        degree
        yearJoined
        seasonJoined
        interest
        city
        state
        contactCounts {
          nextToken
        }
        contactMessages {
          messageUserName
          message
          createdAt
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      status
      recruiter {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      manager {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      consultantRate
      updatedAt
      submissionJobId
      submissionConsultantId
      submissionRecruiterId
      submissionManagerId
    }
  }
`;
export const listSubmissions = /* GraphQL */ `
  query ListSubmissions(
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        job {
          id
          title
          client
          primeVendor
          positionType
          location
          ecorfyRate
          description
          additionalNotes
          status
          createdAt
          updatedAt
          jobVendorId
        }
        consultant {
          id
          userId
          firstName
          lastName
          phone
          email
          contactType
          email2
          email3
          phone2
          phone3
          middleName
          title
          jobTitle
          dob
          linkedinUrl
          githubUrl
          timezone
          leadSource
          gender
          openToWork
          primarySkill
          secondarySkill
          skillTags
          experience
          relocation
          whatsappId
          ssn
          employerName
          employerEmail
          employerPhone
          visaType
          contractType
          managerId
          consultantCount
          submissionCount
          deviceToken
          deviceOS
          university
          degree
          yearJoined
          seasonJoined
          interest
          city
          state
          createdAt
          updatedAt
          owner
        }
        createdAt
        status
        recruiter {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        manager {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        consultantRate
        updatedAt
        submissionJobId
        submissionConsultantId
        submissionRecruiterId
        submissionManagerId
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      title
      description
      courseNotes {
        message
        userName
        createdAt
      }
      courseFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      courseLessons {
        items {
          id
          title
          description
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        courseNotes {
          message
          userName
          createdAt
        }
        courseFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        courseLessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      title
      vendor {
        id
        consultantCount
        submissionCount
        countDate
        contactID
        createdAt
        updatedAt
      }
      client
      primeVendor
      positionType
      location
      ecorfyRate
      description
      additionalNotes
      status
      jobNotes {
        message
        userName
        createdAt
      }
      createdAt
      updatedAt
      jobVendorId
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        vendor {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        client
        primeVendor
        positionType
        location
        ecorfyRate
        description
        additionalNotes
        status
        jobNotes {
          message
          userName
          createdAt
        }
        createdAt
        updatedAt
        jobVendorId
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      userId
      firstName
      lastName
      phone
      email
      contactType
      email2
      email3
      phone2
      phone3
      middleName
      title
      jobTitle
      dob
      linkedinUrl
      githubUrl
      contactFiles {
        fileName
        fileType
        filePurpose
        createdAt
      }
      contactNotes {
        message
        userName
        createdAt
      }
      contactTasks {
        message
        taskType
        priority
        userId
        taskStatus
      }
      shippingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      billingAddress {
        address1
        address2
        city
        state
        zip
        country
      }
      timezone
      leadSource
      gender
      openToWork
      primarySkill
      secondarySkill
      skillTags
      experience
      relocation
      whatsappId
      ssn
      employerName
      employerEmail
      employerPhone
      visaType
      contractType
      managerId
      consultantCount
      submissionCount
      deviceToken
      deviceOS
      university
      degree
      yearJoined
      seasonJoined
      interest
      city
      state
      contactCounts {
        items {
          id
          consultantCount
          submissionCount
          countDate
          contactID
          createdAt
          updatedAt
        }
        nextToken
      }
      contactMessages {
        messageUserName
        message
        createdAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        firstName
        lastName
        phone
        email
        contactType
        email2
        email3
        phone2
        phone3
        middleName
        title
        jobTitle
        dob
        linkedinUrl
        githubUrl
        contactFiles {
          fileName
          fileType
          filePurpose
          createdAt
        }
        contactNotes {
          message
          userName
          createdAt
        }
        contactTasks {
          message
          taskType
          priority
          userId
          taskStatus
        }
        shippingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        timezone
        leadSource
        gender
        openToWork
        primarySkill
        secondarySkill
        skillTags
        experience
        relocation
        whatsappId
        ssn
        employerName
        employerEmail
        employerPhone
        visaType
        contractType
        managerId
        consultantCount
        submissionCount
        deviceToken
        deviceOS
        university
        degree
        yearJoined
        seasonJoined
        interest
        city
        state
        contactCounts {
          nextToken
        }
        contactMessages {
          messageUserName
          message
          createdAt
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
