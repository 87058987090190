import React from "react"
import { View, Text, StyleSheet } from "react-native"
import Home from "../components/Home"

const HomeScreen=(props)=>{
  return (
    // <View style={styles.container}>
      <Home {...props} />
    // </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
})

export default HomeScreen
