import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import EditLesson from '../components/EditLesson'

const EditLessonScreen = (props) => {
  return (
  <EditLesson {...props} />
  )
}

export default EditLessonScreen

const styles = StyleSheet.create({})