import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import EditJob from '../components/EditJob';

const EditJobScreen = (props) => {
    return <EditJob {...props} />
}

export default EditJobScreen

const styles = StyleSheet.create({})