import React, { useState, useEffect } from "react";
import { StyleSheet, FlatList } from "react-native";
import {
  Searchbar,
  Card,
  FAB,
  Avatar,
  IconButton,
  ActivityIndicator,
} from "react-native-paper";
import Header from "../../../global/components/Header";

import { useFocusEffect } from '@react-navigation/native';

import { listNotifications } from "../../../graphql/queries";

import Amplify, { Auth, graphqlOperation, API } from "aws-amplify";
import awsconfig from "../../../aws-exports";

API.configure(awsconfig);

const Notifications = (props) => {
  const [loading, setLoading] = useState(true);

  const [notifications, setNotifications] = useState([]);

  useFocusEffect(
    React.useCallback(() => {
      let isActive = true;
      fetchNotifications();
      return () => {
        isActive = false;
      };
    }, [])
  );

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    // console.log(currentUser.username,"bbbb");

    let userGroups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];

    if (userGroups.indexOf("managers") != -1) {
      await API.graphql({
        query: listNotifications,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          console.log(res.data.listNotifications);
          setNotifications(res.data.listNotifications.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
        });
    } else {
      await API.graphql({
        query: listNotifications,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
        .then(async (res) => {
          console.log(res);
          setNotifications(res.data.listNotifications.items);
          //   setContacts(res.data.listContacts.items);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "errr");
          setLoading(false);
        });
    }
  };

  const renderItem = ({ item }) => {
    return (
      <Card.Title
        title={`${item.notificationType}`}
        subtitle={item.message}
        left={(props) => (
          <Avatar.Text {...props} color={"white"} size={48} label={"N"} />
        )}
      />
    );
  };
  console.log(notifications, "notifications");
  return (
    <Card style={{ flex: 1 }}>
      <Header
        {...props}
        route={props.route}
        navigation={props.navigation}
        children={"Notifications"}
      />
      {!loading ? (
        <Card.Content style={{ marginTop: 20,paddingHorizontal:0 }}>
          <FlatList data={notifications} renderItem={renderItem} />
        </Card.Content>
      ) : (
        <ActivityIndicator
          size="large"
          color="#390485"
          style={styles.loading}
        />
      )}
    </Card>
  );
};

export default Notifications;

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
});
