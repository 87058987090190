import React, { memo, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";

import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import {
  Card,
  ActivityIndicator,
  Surface,
  Portal,
  Modal,
} from "react-native-paper";
import { Contact } from "../../../models";
import * as DocumentPicker from "expo-document-picker";
import { Auth, API, graphqlOperation, DataStore, Storage } from "aws-amplify";

import config from "../../../aws-exports";

import IntlPhoneField from "react-native-intl-phone-field";

API.configure(config);
DataStore.configure(config);
Storage.configure(config);

const EmployerInfo = (props) => {
  // console.log(props, "propsstep");
  const toast = useToast();
  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: "",
      contactName: "",
      contactPhone: "",
    },
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const containerStyle = {
    alignSelf: "center",
  };

  const goBack = () => {
    const { back } = props;
    // Go to previous step
    back();
  };

  const onSave = async (data) => {
    // props.getState().navigation.navigate("Login");
    // console.log(
    //   props.getState().ContactInformation,
    //   props.getState().Consultant,
    //   props.getState().Documents,
    //   props.getState().ProfessionalReferences
    // );
    setLoading(true);
    setLoadingPage(true);
    let formData = props.getState().ContactInformation;
    let form2Data = props.getState().Consultant;
    let filesData = props.getState().Documents;
    let professionalReferences = props.getState().ProfessionalReferences;

    // console.log(filesData[1],"filesData");
    let contactIdFile = filesData[2];
    // console.log(contactIdFile, "contactAadharFile");
    let contactVisaFile = filesData[1];
    let contactResumeFile = filesData[0];
    try {
      // if (verifyUser(data.email, data.phone)) {
      //   alert("User is already registered either with email and/or phone. Please use different email and/or phone to register again");
      //   return;
      // }
      let resume = await Storage.put(
        contactResumeFile.fileName,
        contactResumeFile.blobData,
        { level: "public" }
      );
      console.log("Uploading aadhar");
      let visa = await Storage.put(
        contactVisaFile.fileName,
        contactVisaFile.blobData,
        { level: "public" }
      );
      console.log("Uploading Visa");
      let idProof = await Storage.put(
        contactIdFile.fileName,
        contactIdFile.blobData,
        { level: "public" }
      );
      console.log("Uploading Id Proof");

      delete contactResumeFile.blobData;
      delete contactVisaFile.blobData;
      delete contactIdFile.blobData;

      let contactForm = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email.toLowerCase(),
        contactType: "APPLICANT",
        currentJobTitle: formData.currentJobTitle,
        phone: formData.phone,

        currentImmigrationStatus: form2Data.currentImmigrationStatus,
        willingtorelocate: form2Data.willingtorelocate,
        linkedIn: form2Data.linkedIn,
        dateofBirth: form2Data.dateofBirth,
        currentTimezone: form2Data.currentTimezone,
        besttimetocall: form2Data.besttimetocall,
        experience: parseInt(form2Data.experience) ,
        ssn: form2Data.ssn,

        reference1Name: professionalReferences.reference1Name,
        reference1Email: professionalReferences.reference1Email,
        reference1Phone: professionalReferences.reference1Phone,
        reference2Name: professionalReferences.reference2Name,
        reference2Email: professionalReferences.reference2Email,
        reference2Phone: professionalReferences.reference2Phone,

        companyName: data.companyName,
        contactName: data.companyName,
        contactPhone: data.contactPhone,
        userId: "0145c4fd-97dc-4ef2-8f4e-59f7b4c76aab",
        contactFiles: [contactResumeFile, contactVisaFile, contactIdFile],
      };
      console.log(contactForm, "contactForm");
      await DataStore.save(new Contact(contactForm));
      toast.show("Your application has been saved successfully.", {
        type: "success",
        placement: "top",
        duration: 4000,
        offset: 30,
        animationType: "zoom-in",
      });
      console.log("Contact saved successfully!");
      props.getState().navigation.navigate("Login");
      setLoading(false);
      setLoadingPage(false);
    } catch (error) {
      console.log("Error saving contact", error);
      toast.show("Error saving your application. Please try again", {
        type: "success",
        placement: "top",
        duration: 4000,
        offset: 30,
        animationType: "zoom-in",
      });
      props.getState().navigation.navigate("Login");
      setLoading(false);
      setLoadingPage(false);
    }
  };

  return (
    <Card style={{ flex: 1, backgroundColor: "transparent" }}>
      <ScrollView>
        <View style={{ flex: 1, marginVertical: 20 }}>
          <Text
            variant="displayLarge"
            style={{ fontSize: 24, marginBottom: 20, fontWeight: "bold" }}
          >
            Employer Info
          </Text>
          <Text>{"Company Name"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "text",
                name: "companyName",
                rules: {
                  required: {
                    value: true,
                    message: "Company Name is required",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Company Name",
                },
              },
            ]}
          />

          <Text>{"Contact Name"}</Text>
          <FormBuilder
            control={control}
            setFocus={setFocus}
            formConfigArray={[
              {
                type: "text",
                name: "contactName",
                rules: {
                  required: {
                    value: true,
                    message: "Contact Name is required",
                  },
                },
                textInputProps: {
                  placeholder: "Please Enter Contact Name",
                },
              },
            ]}
          />

          <Text>{"Contact Phone"}</Text>
          <Controller
            control={control}
            name="contactPhone"
            render={({ field: { onChange, value, onBlur } }) => (
              <IntlPhoneField
                onEndEditing={(result) => onChange(result.value)}
                //   onValidation={(isValid) => console.log(isValid)}
                defaultCountry="IN"
                defaultPrefix="+91"
              />
            )}
            rules={{
              required: {
                value: true,
                message: "Contact Phone is required",
              },
              pattern: {
                value: !!/^\+?\d[\d\s-]+$/,
                message: "Contact Phone is invalid",
              },
            }}
          />
          {errors.contactPhone?.message ? (
            <Text
              style={{
                color: "#fc4c4c",
                fontSize: 12,
                paddingVertical: 2,
                paddingHorizontal: 10,
              }}
            >
              {errors.contactPhone?.message}
            </Text>
          ) : null}
        </View>
        <Card.Content
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <TouchableOpacity onPress={goBack} style={styles.button}>
            <Text style={{ color: "white" }}>Back</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={handleSubmit((data) => {
              // console.log("form data", data);
              onSave(data);
            })}
          >
            <Text style={{ color: "white" }}>Submit</Text>
          </TouchableOpacity>
        </Card.Content>
        {loading && (
          <Portal theme={{ colors: { backdrop: "rgba(0, 0, 0, 0.6)" } }}>
            <Modal
              visible={loadingPage}
              dismissable={false}
              onDismiss={() => setLoadingPage(false)}
              contentContainerStyle={containerStyle}
            >
              <ActivityIndicator size={40} />
            </Modal>
          </Portal>
        )}
      </ScrollView>
    </Card>
  );
};

export default EmployerInfo;

const styles = StyleSheet.create({
  button: {
    backgroundColor: "blue",
    borderWidth: 1,
    borderColor: "transparent",
    padding: 10,
    borderRadius: 5,
    width: "30%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});
