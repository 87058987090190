import React, { memo, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Logo from "../../../global/components/Logo";
import { theme } from "../../../../theme";
import { FormBuilder } from "react-native-paper-form-builder";
import { useForm, useController, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import {
  Card,
  ActivityIndicator,
  Surface,
  TextInput,
} from "react-native-paper";
import AnimatedFormView from "../../../account/components/AnimatedFormView";
import { Auth, API, graphqlOperation, DataStore, Storage } from "aws-amplify";

import config from "../../../aws-exports";

import IntlPhoneField from "react-native-intl-phone-field";

API.configure(config);
DataStore.configure(config);
Storage.configure(config);

const ProfessionalInfo = (props) => {
//   console.log(props.getState().navigation, "props");
  const toast = useToast();
  const [willingtorelocateYes, setWillingtorelocateYes] = useState(false);
  const [willingtorelocateNo, setWillingtorelocateNo] = useState(false);
  const [contact, setContact] = useState({
    currentImmigrationStatus: "",
    willingtorelocate: "",
    linkedIn: "",
    dateofBirth: "",
    currentTimezone: "",
    besttimetocall: "",
    experience: "",
    ssn: "",
  });

  const {
    control,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentImmigrationStatus: "",
      willingtorelocate: "",
      linkedIn: "",
      dateofBirth: "",
      currentTimezone: "",
      besttimetocall: "",
      experience: "",
      ssn: "",
    },
    mode: "onChange",
  });

  const nextStep = (datas) => {
    const { next, saveState } = props;
    // console.log(props);
    next();
    saveState({
      //   stepOneData: datas,
      //   handleSubmit: handleSubmit,
      Consultant: datas,
      navigation: props.getState().navigation,
    });
  };
  const goBack = () => {
    const { back } = props;
    // Go to previous step
    back();
  };
  return (
    <Card.Content style={styles.container}>
      <Card.Content
        style={{ marginTop: 10, marginBottom: 20, paddingHorizontal: 0 }}
      >
        <Text
          variant="displayLarge"
          style={{ fontSize: 24, fontWeight: "bold" }}
        >
          Professional Info
        </Text>
      </Card.Content>
       <View style={{ flex: 1, marginVertical: 20 }}>
        <Text>{"Current Immigration Status"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              name: "currentImmigrationStatus",
              type: "select",
              textInputProps: {
                placeholder: "Choose...",
              },
              rules: {
                required: {
                  value: true,
                  message: "Current Immigration Status is required",
                },
              },
              options: [
                {
                  label: "USC",
                  value: "USC",
                },
                {
                  label: "GC",
                  value: "GC",
                },
                {
                  label: "GC EAD",
                  value: "GC EAD",
                },
                {
                  label: "OPT",
                  value: "OPT",
                },
                {
                  label: "H4 EAD",
                  value: "H4 EAD",
                },
                {
                  label: "H1B",
                  value: "H1B",
                },
              ],
            },
          ]}
        />
        <Text>{"Willing to relocate?"}</Text>
        <Controller
          control={control}
          name="willingtorelocate"
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <Card.Content
                  style={{ flexDirection: "row", marginVertical: 10 }}
                >
                  {willingtorelocateYes ? (
                    <FontAwesome
                      name={"check-square-o"}
                      color={"black"}
                      size={22}
                      onPress={() => {
                        setWillingtorelocateYes(true);
                        setWillingtorelocateNo(false);
                        onChange("yes");
                        value = "yes";
                      }}
                    />
                  ) : (
                    <FontAwesome
                      name={"square-o"}
                      color={"black"}
                      size={22}
                      onPress={() => {
                        setWillingtorelocateYes(true);
                        setWillingtorelocateNo(false);
                        onChange("yes");
                        value = "yes";
                      }}
                    />
                  )}
                  <Text
                    onPress={() => {
                      setWillingtorelocateYes(true);
                      setWillingtorelocateNo(false);
                      onChange("yes");
                      value = "yes";
                    }}
                    style={{ color: "black", marginLeft: 10, marginTop: 4 }}
                  >
                    {"Yes"}
                  </Text>
                </Card.Content>
                <Card.Content
                  style={{ flexDirection: "row", marginBottom: 10 }}
                >
                  {console.log(value, "value")}
                  {willingtorelocateNo ? (
                    <FontAwesome
                      name={"check-square-o"}
                      color={"black"}
                      size={22}
                      onPress={() => {
                        setWillingtorelocateNo(true);
                        setWillingtorelocateYes(false);
                        onChange("No");
                        value = "No";
                      }}
                    />
                  ) : (
                    <FontAwesome
                      name={"square-o"}
                      color={"black"}
                      size={22}
                      onPress={() => {
                        setWillingtorelocateNo(true);
                        setWillingtorelocateYes(false);
                        onChange("No");
                        value = "No";
                      }}
                    />
                  )}
                  <Text
                    onPress={() => {
                      setWillingtorelocateNo(true);
                      setWillingtorelocateYes(false);
                      onChange("No");
                      value = "No";
                    }}
                    style={{ color: "black", marginLeft: 10, marginTop: 4 }}
                  >
                    {"No"}
                  </Text>
                </Card.Content>
              </>
            );
          }}
          rules={{
            required: {
              value: true,
              message: "Willing to relocate is required",
            },
          }}
        />
        <Card.Content>
          {errors.willingtorelocate?.message ? (
            <Text
              style={{ color: "#fc4c4c", fontSize: 12, paddingVertical: 2 }}
            >
              {errors.willingtorelocate?.message}
            </Text>
          ) : null}
        </Card.Content>
        <Text>{"LinkedIn"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              type: "text",
              name: "linkedIn",
              rules: {
                required: {
                  value: true,
                  message: "LinkedIn is required",
                },
              },
              textInputProps: {
                placeholder: "Please Enter LinkedIn",
              },
            },
          ]}
        />
        <Text>{"Date of Birth (mm/yyyy only)"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              type: "text",
              name: "dateofBirth",
              rules: {
                required: {
                  value: true,
                  message: "Date of Birth is required",
                },
                pattern: {
                  value: /^(0[1-9]|1[012])\/[1][9]\d{2}$/,
                  message: " Please give in correct Date of Birth Format",
                },
              },
              textInputProps: {
                placeholder: "Please Enter Date of Birth",
              },
            },
          ]}
        />
        <Text>{"Best time to call"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              type: "text",
              name: "besttimetocall",
              rules: {
                required: {
                  value: true,
                  message: "Please Mention Best time to call is required",
                },
              },
              textInputProps: {
                placeholder: "Please Mention Best time to call",
              },
            },
          ]}
        />
        <Text>{"Current Timezone"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              name: "currentTimezone",
              type: "select",
              textInputProps: {
                placeholder: "Select Current Timezone",
                style: { position: "relative" },
              },
              rules: {
                required: {
                  value: true,
                  message: "Current Timezone Type is required",
                },
              },
              options: [
                {
                  label: "EST",
                  value: "EST",
                },
                {
                  label: "MST",
                  value: "MST",
                },
                {
                  label: "CST",
                  value: "CST",
                },
                {
                  label: "PST",
                  value: "PST",
                },
              ],
            },
          ]}
        />
        <Text>{"Experience"}</Text>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          formConfigArray={[
            {
              type: "text",
              name: "experience",
              rules: {
                required: {
                  value: true,
                  message: "Experience is required",
                },
              },
              textInputProps: {
                placeholder: "Please Enter Experience",
              },
            },
          ]}
        />
        <Text>{"SSN (Last 4 Digits only)"}</Text>
        <Controller
          control={control}
          name="ssn"
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              mode="outlined"
              placeholder="Enter SSN Number"
              onBlur={onBlur}
              onChangeText={(text) => {
                // console.log(text, "text");
                onChange(text);
              }}
              style={{ height: 57 }}
              outlineColor={errors.ssn && "#fc4c4c"}
              activeOutlineColor={errors.ssn && "#fc4c4c"}
              keyboardType="number-pad"
            />
          )}
          rules={{
            required: {
              value: true,
              message: "SSN number is required",
            },
            minLength: {
              value: 4,
              message: "SSN number should be atleast 4 digits",
            },
            maxLength: {
              value: 4,
              message: "SSN number should be only 4 digits",
            },
            pattern: {
              value: /^-?(0|[1-9]\d*)?$/,
              message: "Please Enter Only Numerics",
            },
          }}
        />
        <Card.Content>
          {errors.ssn?.message ? (
            <Text
              style={{ color: "#fc4c4c", fontSize: 12, paddingVertical: 2 }}
            >
              {errors.ssn?.message}
            </Text>
          ) : null}
        </Card.Content>
      </View> 
      <Card.Content
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginVertical: 30,
        }}
      >
        <TouchableOpacity onPress={goBack} style={styles.button}>
          <Text style={{ color: "white" }}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={handleSubmit((data) => {
            // console.log("form data", data);
            nextStep(data);
          })}
        >
          <Text style={{ color: "white" }}>Next</Text>
        </TouchableOpacity>
      </Card.Content>
    </Card.Content>
  );
};

export default ProfessionalInfo;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    marginTop: 4,
  },
  label: {
    color: theme.colors.secondary,
    marginTop: 20,
  },
  button: {
    backgroundColor: "blue",
    borderWidth: 1,
    borderColor: "transparent",
    padding: 10,
    borderRadius: 5,
    width: "30%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});
